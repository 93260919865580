/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { getClimaPlantOne, getplantsOne } from '../../../services/plantsService'
import { GraphicsModalPlant } from '../ GraphicsModalPlant'
import { LoadingSpan } from '../Loadings/loadingSpan'
import { Loading } from '../Loadings/loading'
import './styles.css'
import { getReportsAll } from '../../../services/reports'
import { TableReportModalPlant } from '../TableReportModalPlant'
import { getUnitConsumerAllFilter } from '../../../services/unitConsumerService'
import { TableUnitConsumerModalPlant } from '../TableUnitConsumerModalPlant'
import { NotImported } from '../NotInformat'
import { TableDevicesModalPlant } from '../TableDevicesModalPlant'
import { ItemAlarm } from './components/itemAlarm'
import { ModalUpdatePlant } from './components/ModalUpdatePlant'
import { useAuth } from '../../../modules/auth'
import { FaSeedling } from "react-icons/fa6";
import { FaList } from "react-icons/fa6";
import { FaCloud } from "react-icons/fa6";
import { getDayOfWeek } from '../../../utils/date'


type Props = {
    show: boolean;
    handleClose: () => void;
    plant: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalPlant = ({show, handleClose, plant}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingCharts] = useState<boolean>(false)
  //const [chartsSum, setChartsSum] = useState(null);
  const [largura, setLargura] = useState(window.innerWidth);
 
  const [filter] = useState<string>("GERADO");
  const [reports, setReports] = useState<any[]>([])
  const [unitConsumers, setUnitConsumers] = useState<any[]>([])
  const [loadingReports, setLoadingReports] = useState<boolean>(true)
  const [loadingUnitConsumers, setLoadingConsumers] = useState<boolean>(true)
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false)
  const user = useAuth()

  const [clima, setClima] = useState<any[]>()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const getPlants = async () => {
    try {
      if(plant){
        setLoading(true)
        const myPlant = await getplantsOne(plant)
        setData(myPlant)
      }
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }

  const getUnitConsumers = async () => {
    try {
      if(plant){
        setLoadingConsumers(true)
        const myPlant = await getUnitConsumerAllFilter(null, plant)
        setUnitConsumers(myPlant)
      }
    } catch (error) {
      console.log(error)
    }finally{
      setLoadingConsumers(false)
    }
  }

  const getClimaPlant = async () => {
    try {
      if(plant){
        const myClima = await getClimaPlantOne(plant) 
        setClima(myClima)
      }
    } catch (error) {
      console.log(error)
    }finally{
    }
  }

  const getReports = async () => {
    try {
      setLoadingReports(true)

      const reports = await getReportsAll(filter, undefined, plant)
      setReports(reports)

    } catch (error) {
      console.log(error)
      //toggleToast("Erro ao buscar relatórios. ", "error")
    }finally{
      setLoadingReports(false)
    }
  }

  const refresh = () => {
    getPlants()
  }

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };
  
  useEffect(() => {
   getPlants()
   getClimaPlant()
   //getChartsBD()
   getReports()
   getUnitConsumers()
  }, [plant]);

  useEffect(() => {
    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, []);


  useEffect(() => {
    if(data){
      //getClima()
    }
  }, [data])

  
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={
        largura < 1200 ? 'modal-dialog modal-dialog-centered modal-fullscreen-xxl-down p-5 h-auto' 
        : 'modal-dialog modal-dialog-centered modal-xl'
      }
      show={show}
      //style={{width: '1700px !important'}}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div>
          <div className='modal-header' style={{display: 'flex', flexDirection: largura < 1200 ? 'column' : 'row'}}>
            {largura > 1200 && (<h2>Detalhes da usina  {data && data.plantId ? `- ${data.plantId}` : <LoadingSpan style={{marginLeft: '0.5rem'}}/>}</h2>)}
            
            <div style={{display: 'flex', gap: '0.2rem', width: largura < 1200 ? '100%' : '', justifyContent: 'end'}}>
              {user.currentUser?.role.slug !== "CLIENTE" &&(
                <div 
                  className='btn btn-sm btn-icon btn-active-color-primary border border-right border-right-2 border-bottom-0 border-top-0 border-left-0 ' 
                  style={{display: 'flex', gap: '0.5rem', width: 'auto', paddingRight: '0.5rem'}}
                  onClick={()=> {
                    setShowUpdateModal(true)
                  }}
                >
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='text-muted text-hover-primary mt-1 fw-semibold fs-5'>CONFIGURAÇÕES DA USINA</span>
                  </h3>
                  <KTSVG className='svg-icon-1' path='/media/icons/duotune/coding/cod001.svg' />
                </div>
              )}

              <div className='btn btn-sm btn-icon btn-active-color-primary' 
                onClick={()=> {
                  handleClose()
                  setData({})
                  //setChartsSum(null)
                }}
              >
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
              </div>
            </div>

            {largura < 1200 && (<h2>Detalhes da usina  {data && data.plantId ? `- ${data.plantId}` : <LoadingSpan style={{marginLeft: '0.5rem'}}/>}</h2>)}
          </div>
          {!loading && !loadingCharts && !loadingReports && !loadingUnitConsumers &&(
            <div className='modal-body py-lg-4 px-lg-10'>
              <div className='divGlobalHeader'>
                <div style={{flex: largura > 1052 ? '3' : ''}}>
                  <div className='card-body pt-9 pb-0'>
                    <div className='d-flex flex-sm-nowrap mb-3'>
                      
                      <div className='me-7 mb-4 divGlobalImg'>
                        <div 
                          className='symbol symbol-100px symbol-fixed position-relative divSubImg' 
                        >
                          <img src={toAbsoluteUrl('/media/imgs/plant2.png')} alt='Metornic' />
                          <div 
                            className={
                              `position-absolute translate-middle bottom-0 start-100 mb-6 bg-${
                                data && data.status !== null
                                  ? data.status === 0
                                  ? "success"
                                  : data.status === 1
                                  ? "danger"
                                  : data.status === 2
                                  ? "warning"
                                  : data.status === 7 || data.status === 3
                                  ? "info"
                                  : "secondary"
                                : "secondary"
                              
                              } rounded-circle border border-4 border-white h-20px w-20px`}
                          ></div>

                        </div>
                      </div>

                      <div className='flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                          <div className='d-flex flex-column'>
                            <div className='d-flex align-items-center mb-2'>
                              <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-2'>
                                {data && data.name ? data.name : `Usina ${data && data.plantId ? data.plantId : ""}`}
                              </a>
                              <span 
                                className={
                                  `badge badge-light-${
                                    data && data.status !== null
                                      ? data.status === 0
                                      ? "success"
                                      : data.status === 1
                                      ? "danger"
                                      : data.status === 2
                                      ? "warning"
                                      : data.status === 7 || data.status === 3
                                      ? "info"
                                      : "secondary"
                                    : "secondary"
                                  
                                  } fs-7 fw-semibold`}
                              >
                                {
                                  data && data.status !== null
                                    ? data.status === 0
                                    ? "On-line"
                                    : data.status === 1
                                    ? "Off-line"
                                    : data.status === 7 || data.status === 3
                                    ? "Lembrar"
                                    : data.status === 2
                                    ? "Alerta"
                                    : "Desconhecido"
                                  : "Desconhecido"
                                }
                              </span>
                            </div>

                            <div className='d-flex flex-wrap fw-bold fs-6 mb-0 pe-2'>
                              <a
                                href='#'
                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/communication/com006.svg'
                                  className='svg-icon-4 me-1'
                                />
                                {
                                  data && data.client ? 
                                  data.client.nameResponsible
                                  : <NotImported className="fs-6 text-gray-400 text-hover-primary"/> 
                                }
                                
                              </a>
                            </div>

                            <div className='d-flex flex-wrap fw-bold fs-6 mb-0 pe-2'>
                              <a
                                href='#'
                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                              >
                                <KTSVG
                                  path="/media/icons/duotune/maps/map001.svg"
                                  className='svg-icon-4 me-1'
                                />
                                {data && data.address ? data.address : <NotImported className="fs-6 text-gray-400 text-hover-primary"/>}
                              </a>
                            </div>

                            <div className='d-flex flex-wrap fw-bold fs-6 mb-0 pe-2'>
                              <a
                                href='#'
                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                              >
                                <KTSVG
                                  path="/media/icons/duotune/coding/cod009.svg"
                                  className='svg-icon-4 me-1'
                                />
                                {
                                  data && data.credential ? 
                                  data.credential.mark ?
                                    `${data.credential.mark.name}`
                                  : <NotImported className="fs-6 text-gray-400 text-hover-primary"/>
                                  : <NotImported className="fs-6 text-gray-400 text-hover-primary"/>
                                }
                              </a>
                            </div>
                            
                            <div className='flex-wrap fw-bold fs-6 mb-0 pe-2' style={{display: 'flex', alignItems: 'center'}}>
                              <a
                                href='#'
                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5'
                              >
                                {/* <KTSVG
                                  path="/media/icons/duotune/maps/map001.svg"
                                  className='svg-icon-4 me-1'
                                /> */}
                                <i className="bi bi-currency-dollar svg-icon-4" style={{marginRight: '0.5rem'}}></i>
                                {data && data.value_implementation ? parseFloat(data.value_implementation) + " R$" : 0 + " R$"}
                              </a>
                              {/* {user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0
                                && user.currentUser.companies[0].planos === "PREMIUM" &&
                                  user.currentUser?.role.slug !== "CLIENTE" &&(
                                  <a
                                    onClick={(event: any) => {
                                      setShowUpdateModal(true)
                                    }}
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary'
                                    style={{width: '1.7rem', height: '1.7rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                  >
                                    <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='svg-icon-5'
                                    />
                                  </a>
                              )} */}
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card-body pb-0 divCardBody' style={{flex: largura > 1052 ? '4' : ''}}>
                  
                  <div className='d-flex flex-wrap flex-stack' style={{width: '100%'}}>
                    <div className='d-flex flex-column flex-grow-1'>
                      <div className='d-flex flex-wrap'>
                        <div className='border border-gray-300 border-dashed rounded py-3 px-4 mb-3 d-flex flex-wrap divDateModal' >
                          
                          <div className='divItemDate'>
                            <div className='d-flex divMiniItem'>
                              <div className='fs-5 fw-bolder'>{data && data.createDate ? data.createDate : <NotImported className="fs-5 fw-bolder"/>}</div>
                              <div className='fw-bold fs-8 text-gray-400'>Data de instalação</div>
                            </div>

                            <div className='d-flex divMiniItem'>
                              <div className='fs-5 fw-bolder'>{data && data.capacity ? data.capacity : "0"} KWp</div>
                              <div className='fw-bold fs-8 text-gray-400'>Capacidade</div>
                            </div>

                            {data && data.plano && data.plano === "PREMIUM" &&
                            (<div className='d-flex divMiniItem'>
                              <div className='fs-5 fw-bolder'>~{data && data.economy_total ? data.economy_total : "0"} R$</div>
                              <div className='fw-bold fs-8 text-gray-400'>Economizado (total)</div>
                            </div>)}
                          </div>
                          
                          <div className='divItemDate'>
                            {/* <div className='d-flex divMiniItem'>
                              <div className='fs-5 fw-bolder'>
                                {data.incomeToday ? data.incomeToday + " R$" : "0"}
                              </div>
                              <div className='fw-bold fs-8 text-gray-400'>Rendimento</div>
                            </div> */}

                            <div className='d-flex divMiniItem'>
                              <div className='fs-5 fw-bolder'>{data && data.total_sum ? data.total_sum : "0"} KWh</div>
                              <div className='fw-bold fs-8 text-gray-400'>Energia Gerada (Total)</div>
                            </div>

                            <div className='d-flex divMiniItem'>
                              <div className='fs-5 fw-bolder'>{data && data.energyToday ? data.energyToday : "0"} KWh</div>
                              <div className='fw-bold fs-8 text-gray-400'>Energia Gerada (Hoje)</div>
                            </div>

                            {data && data.plano && data.plano === "PREMIUM" &&
                            (<div className='d-flex divMiniItem'>
                              <div className='fs-5 fw-bolder'>{data && data.feedback_financial ? data.feedback_financial : "0"} %</div>
                              <div className='fw-bold fs-8 text-gray-400'>Retorno Financeiro (Total)</div>
                            </div>)}
                          </div>
                          
                          <div className='divItemDate'>
                            <div className='d-flex divMiniItem'>
                              <div className='fs-5 fw-bolder'>{data && data.monthly_sum ? data.monthly_sum : "0"} KWh</div>
                              <div className='fw-bold fs-8 text-gray-400'>Energia Gerada (Mês)</div>
                            </div>

                            <div className='d-flex divMiniItem'>
                              <div className='fs-5 fw-bolder'>{data && data.consumption ? data.consumption : "0"} KWh</div>
                              <div className='fw-bold fs-8 text-gray-400'>Consumo (Total)</div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='divMenuOpcoes'>
                <div className='card-body divCardBodyMenu'>
                  
                  <div className='d-flex overflow-auto h-55px' style={{marginBottom: '1rem'}}>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                      <li className='nav-item'>
                        <a
                          className="nav-link active text-active-primary border-active-primary border-hover-primary me-6"
                          data-bs-toggle="tab"
                          href="#details"
                        >
                          Mais detalhes
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a
                          className="nav-link text-active-primary border-active-primary border-hover-primary me-6"
                          data-bs-toggle="tab"
                          href="#relators"
                        >
                          Relatórios
                        </a>
                      </li>
                      {largura < 1200 && (
                        <li className='nav-item'>
                          <a
                            className="nav-link text-active-primary border-active-primary border-hover-primary me-6"
                            data-bs-toggle="tab"
                            href="#grapics"
                          >
                            Gráficos
                          </a>
                        </li>
                      )}
                      <li className='nav-item'>
                        <a
                          className="nav-link text-active-primary border-active-primary border-hover-primary me-6"
                          data-bs-toggle="tab"
                          href="#alarms"
                          style={{display: 'flex', gap: '0.5rem'}}
                        >
                          <span>
                            Alarmes
                          </span>

                          {data && data && data.alarms && data.alarms.length > 0 ? <span className='badge badge-warning'>
                            {data.alarms.length}
                          </span> : <></>}
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a
                          className="nav-link text-active-primary border-active-primary border-hover-primary me-6"
                          data-bs-toggle="tab"
                          href="#all"
                        >
                          Outros
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="tab-content" id="myTabContent5">
                    <div 
                      className="tab-pane fade active show"
                      id="details"
                      role="tabpanel"
                    >
                      <div className='d-flex flex-column flex-grow-1' style={{height: '35rem', overflow: 'auto',}}>
                        <div className='d-flex flex-wrap' style={{gap: '0.7rem'}}>
                          <div 
                            className='card border border-gray-300 border-dashed rounded py-3 px-4 mb-3 d-flex flex-wrap'
                            style={{flexDirection: 'column', width: '100%'}}  
                          >
                            <div style={{display: 'flex', alignItems: 'center', gap: '0.7rem', marginBottom: '1.5rem'}}>
                              {/* <i className='bi bi-three-dots fs-3'></i> */}
                              <div style={{width: '2.5rem', height: '2.5rem', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='bg-primary'>
                                <FaList className='text-light' style={{fontSize: '1.5rem'}}/>
                              </div>
                              
                              <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bold fs-5 mb-1'>Detalhes da usina</span>
                                {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
                              </h3>
                            </div>
                            
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                              
                              <div className='d-flex divItemInformationsDuo'>
                                <div className='fw-bold fs-6 text-gray-400'>Capacidade: </div>
                                <div className='fs-6 fw-bolder'>
                                {data && data.capacity ? data.capacity + " KWp" : 0 + ' KWp'}
                                </div>
                              </div>

                              <div className='separator separator-solid my-2'></div>

                              <div className='d-flex divItemInformationsDuo'>
                                <div className='fw-bold fs-6 text-gray-400'>Tensão: </div>
                                <div className='fs-6 fw-bolder'>
                                  {data && data.tensao ? data.tensao + " Volts" : 0 + ' Volts'}
                                </div>
                              </div>

                              <div className='separator separator-solid my-2'></div>

                              <div className='d-flex divItemInformationsDuo'>
                                <div className='fw-bold fs-6 text-gray-400'>Tipo de conexão: </div>
                                <div className='fs-6 fw-bolder'>
                                  {data && data.type_conection ? data.type_conection : <NotImported className="fs-6 fw-bolder"/>}
                                </div>
                              </div>

                              <div className='separator separator-solid my-2'></div>

                              <div className='d-flex divItemInformationsDuo'>
                                <div className='fw-bold fs-6 text-gray-400'>Total Investido: </div>
                                <div className='fs-6 fw-bolder'>
                                  R$ {data && data.value_implementation ? data.value_implementation : 0}
                                </div>
                              </div>

                              <div className='separator separator-solid my-2'></div>

                              <div className='d-flex divItemInformationsDuo'>
                                <div className='fw-bold fs-6 text-gray-400'>Unidade ( Filial ): </div>
                                <div className='fs-6 fw-bolder'>
                                  {data && data.unitBranch ? data.unitBranch.companyName : <NotImported className="fs-5 fw-bolder"/>}
                                </div>
                              </div>
                            </div>

                          </div>

                          {data && data.plano && (data.plano === "PREMIUM" || data.plano === "STARTER") &&
                          (<div 
                            className='card border border-gray-300 border-dashed rounded py-4 px-5 pt-4 mb-3'
                            style={{width: '100%', display: 'flex', flexDirection: 'column'}}
                          >
                            <div style={{display: 'flex', alignItems: 'center', gap: '0.7rem', marginBottom: '1.5rem'}}>
                              <div style={{width: '2.5rem', height: '2.5rem', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='bg-primary'>
                                <FaCloud className='text-light' style={{fontSize: '1.5rem'}}/>
                              </div>
                              
                              <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bold fs-5 mb-1'>Previsão do tempo</span>
                              </h3>
                            </div>
                            
                            <div className='d-flex divAddInformations'>
                              {clima && clima.length > 0 ?
                                clima.map((dayClima) => {
                                  return (
                                    <div className='d-flex divItemMenu'>
                                      <img src={toAbsoluteUrl(`https://openweathermap.org/img/wn/${dayClima.weather && dayClima.weather[0].icon ? dayClima.weather[0].icon : ""}@2x.png`)} className="imgItemMenu" alt='Metornic' />
                                      <div className='fw-bold fs-7 text-gray-400'>{dayClima.temp && dayClima.temp.min ? dayClima.temp.min.toFixed(0) : 0}°C | {dayClima.temp && dayClima.temp.day ? dayClima.temp.day.toFixed(0) : 0}°C | {dayClima.temp && dayClima.temp.max ? dayClima.temp.max.toFixed(0) : 0}°C </div>
                                      <div className='fs-7 fw-bolder'>{dayClima && dayClima.dt ? getDayOfWeek(dayClima.dt) : ""}</div>
                                    </div>
                                  )
                                })
                              : <>Dados climáticos não encontrados</>}
                            </div>

                          </div>)}

                          <div 
                            className='card border border-gray-300 border-dashed rounded py-4 px-5 pt-4 mb-3'
                            style={{width: '100%', display: 'flex', flexDirection: 'column'}}
                          >
                            <div style={{display: 'flex', alignItems: 'center', gap: '0.7rem', marginBottom: '1.5rem'}}>
                              {/* <i className='bi bi-three-dots fs-3'></i> */}
                              <div style={{width: '2.5rem', height: '2.5rem', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='bg-primary'>
                                <FaSeedling className='text-light' style={{fontSize: '1.5rem'}}/>
                              </div>
                              
                              <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bold fs-5 mb-1'>Benefícios Ambientais</span>
                                {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
                              </h3>
                            </div>
                            
                            <div className='d-flex divAddInformations'>
                              <div className='d-flex divItemMenu'>
                                <img src={toAbsoluteUrl('/media/imgs/co2.png')} className="imgItemMenu" alt='Metornic' />
                                <div className='fw-bold fs-7 text-gray-400'>Co2 evitada: </div>
                                <div className='fs-7 fw-bolder'>{data && data.co2 ? data.co2 + " ton" : 0 + ' ton'}</div>
                              </div>

                              <div className='d-flex divItemMenu'>
                                <img src={toAbsoluteUrl('/media/imgs/so2.png')} className="imgItemMenu" alt='Metornic' />
                                <div className='fw-bold fs-7 text-gray-400'>So2 evitada: </div>
                                <div className='fs-7 fw-bolder'>{data && data.so2 ? data.so2 + " ton" : 0 + ' ton'}</div>
                              </div>

                              <div className='d-flex divItemMenu'>
                                <img src={toAbsoluteUrl('/media/imgs/coal.png')} className="imgItemMenu" alt='Metornic' />
                                <div className='fw-bold fs-7 text-gray-400'>Coal evitado: </div>
                                <div className='fs-7 fw-bolder'>{data && data.coal ? data.coal + " ton" : 0 + ' ton'}</div>
                              </div>
                            </div>

                          </div>
                          
                        </div>
                      </div>
                    </div>

                    <div 
                      className="tab-pane fade show divTableReport"
                      id="relators"
                      role="tabpanel"
                    >
                      <TableReportModalPlant reports={reports} loading={loadingReports}/>
                    </div>

                    <div
                      id="all"
                      role="tabpanel"
                      className='tab-pane fade show'
                    >
                      <div className='border border-gray-300 border-dashed rounded py-3 px-4 mb-3 d-flex flex-wrap flex-direction-column' style={{gap: '1rem'}}>
                        <h3 className='card-title d-flex align-items-start flex-column'>
                          <span className='card-label fw-bold fs-5 mb-1'>Unidades Consumidoras</span>
                          <span className='text-muted fw-semibold fs-7'>Todas as unidades consumidoras vinculadas a usina {}</span>
                        </h3>
                        <div className='divTableUnitConsumer'>
                          <TableUnitConsumerModalPlant loading={loadingUnitConsumers} unitConsumers={unitConsumers}/>
                        </div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded py-3 px-4 mb-3 d-flex flex-wrap flex-direction-column' style={{gap: '1rem'}}>
                        <h3 className='card-title d-flex align-items-start flex-column'>
                          <span className='card-label fw-bold fs-5 mb-1'>Dispositivos</span>
                          <span className='text-muted fw-semibold fs-7'>Todos os inversores vinculados a usina {}</span>
                        </h3>
                        <div className='divTableUnitConsumer'>
                          <TableDevicesModalPlant loading={loading} devices={data && data.devices ? data.devices : undefined}/>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade show"
                      id="alarms"
                      role="tabpanel"
                    >

                      <ItemAlarm alarms={data && data.alarms ? data.alarms : []}/>
                    </div>

                    {largura < 1200 &&(
                      <div 
                        className="tab-pane fade show"
                        id="grapics"
                        role="tabpanel"
                        //style={{background: 'green'}}
                      >
                        <GraphicsModalPlant myProg={data && data.prognosis ? data.prognosis : null} charts={data && data.charts ? data.charts : {}} loading={loadingCharts} prognostico={data && data.annual_generation_forecast ? data.annual_generation_forecast : null} />
                      </div>
                    )
                    }
                  </div>
                  

                </div>

                {largura > 1200 && (<GraphicsModalPlant myProg={data && data.prognosis ? data.prognosis : null} charts={data && data.charts ? data.charts : null} loading={loadingCharts} prognostico={data && data.annual_generation_forecast ? data.annual_generation_forecast : null}/>)}
              </div>
            </div>
          )}
          {
            // eslint-disable-next-line no-mixed-operators
            loading || loadingCharts &&(
              <div className='modal-body py-lg-4 px-lg-10 divLoading' >
                <Loading myClass='spinner-border loading'/>
              </div>
            )
          }
        </div>

        <ModalUpdatePlant 
          show={showUpdateModal}
          data={data ? data : {}}
          id={data ? data.id : undefined} 
          handleClose={() => {
            setShowUpdateModal(false)
            refresh()
          }} 
        />
    </Modal>,
    modalsRoot
  )
}

export {ModalPlant}

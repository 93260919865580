import api from "./api";

export const getNotificationsAll = async(type?: string) => {
    const response = await api.get(`notifications/listAll?type=${type ? type : ""}`);
    return response.data;
};

export const putMyNotifications = async(id: string, body: any) => {
    const response = await api.put(`notifications/${id}`, body);
    return response.data;
};

export const putNotificationsAll = async(body: any) => {
    const response = await api.put(`notifications/updateAll/`, body);
    return response.data;
};



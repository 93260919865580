import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import useSize from '../../../../hooks/useSize';
import { useToast } from '../../../components/Toats';
import { getProposalOne, putProposalOne } from '../../../../services/proposalsService';
import { ModalAddUpdateProposal } from '../TableProposals/components/ModalAddEditProposal';
import { ModalDetailsProposals } from '../TableProposals/components/ModalDetailsProposals';
import { ModalDeleteProposal } from '../TableProposals/components/ModalDeleteProposal';
import { ModalTemplateDocument } from '../TableProposals/components/ModalTemplateProposal';
import { KTSVG } from '../../../../../_metronic/helpers';
import { initialLetters } from '../../../../utils/initialLetters';
import { NotImported } from '../../../components/NotInformat';
import { formatarData, formatarDataV2 } from '../../../../utils/date';
import { putPhasesFunnelOne } from '../../../../services/phasesFunnel';
import { LoadingSpan } from '../../../components/Loadings/loadingSpan';
import axios from 'axios';
import { getFileBase64 } from '../../../../services/files';
import { linkPDF } from '../../../../utils/savePDF';

type Task = {
  id: string;
  content: string;
};

type Column = {
  id: string;
  title: string;
  taskIds: string[];
};

type BoardData = {
  tasks: { [key: string]: Task };
  columns: { [key: string]: Column };
  columnOrder: string[];
};

type Props = {
    className: string;
    proposals: any[]
    refresh: any;
    filterFunnel: any;
    mySearch: any[];
    control: boolean;
    showCreateAppModal: boolean
    setShowCreateAppModal: any

    setMyFunnel: any
    setShowCreateAppModalFunnel: any
}

type Columns = {
    [key: string]: Column;
};

type TaskMap = {
    [key: string]: {
      id: string;
      content: string;
    };
  };

// const initialData: BoardData = {
//   tasks: {
//     'task-1': { id: 'task-1', content: 'Tarefa 1' },
//     'task-2': { id: 'task-2', content: 'Tarefa 2' },
//     'task-3': { id: 'task-3', content: 'Tarefa 3' },
//   },
//   columns: {
//     'column-1': {
//       id: 'column-1',
//       title: 'Fase 1',
//       taskIds: ['task-1', 'task-2'],
//     },
//     'column-2': {
//       id: 'column-2',
//       title: 'Fase 2',
//       taskIds: ['task-3'],
//     },
//     'column-3': {
//       id: 'column-3',
//       title: 'Fase 3',
//       taskIds: [],
//     },
//     'column-4': {
//       id: 'column-4',
//       title: 'Fase 4',
//       taskIds: [],
//     },
//   },
//   columnOrder: ['column-1', 'column-2', 'column-3', 'column-4'],
// };

const KanbanBoard: React.FC<Props> = ({
    className, proposals, refresh, 
    filterFunnel, mySearch, control, showCreateAppModal, 
    setShowCreateAppModal, setShowCreateAppModalFunnel, setMyFunnel
  }) => {

    console.log(proposals)
  //const [boardData, setBoardData] = useState<BoardData>(initialData);
  const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
  const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false)
  const [showDocumentAppModalTemplate, setShowDocumentAppModalTemplate] = useState<boolean>(false)

  const [myProposal, setMyProposal] = useState<any | null>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [isColumnSelected, setColumnSelected] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [loadingPhaseFunnel, setLoadingPhaseFunnel] = useState(false)

  const { toggleToast } = useToast();
  const {width} = useSize()
  const [boardData, setBoardData] = useState<BoardData>({
    tasks: {},
    columns: {},
    columnOrder: []
  });

  const [loadingPDF, setLoadingPDF] = useState<boolean>(false)
  const [idProposal, setIdProposal] = useState<any>(null)

  const getProposal = async (id: string) => {
    try {
      if(id){
        const myProposal = await getProposalOne(id ? id : "")
        return myProposal
      }
    } catch (error) {
        console.log(error)
    }
  }

  const getImage = async (key: string) => {
    try {
      const url = await getFileBase64(key)
      return url.base64

    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const replaceValues = async (dados: any, html: string) => {
      let novoHtml = html;
      const imageCompany = dados 
          && dados.unitBranch 
          && dados.unitBranch.company 
          && dados.unitBranch.company.image 
          ? await getImage(dados.unitBranch.company.image_dark)
          : "https://i.postimg.cc/kXzT1qjT/sua-logo-aqui.pngs";

      let dados_OS = {
          //imageCompany: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.image ? dados.unitBranch.company.image : "/media/imgs/sub-logo-v2.png",
          imageCompany: imageCompany ? `data:image/jpeg;base64,${imageCompany}` : "https://i.postimg.cc/kXzT1qjT/sua-logo-aqui.pngs",
          corporateName: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.corporateName ? dados.unitBranch.company.corporateName : "",
          cnpj: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.cnpj ? dados.unitBranch.company.cnpj : "",
          street: dados && dados.unitBranch && dados.unitBranch.street ? dados.unitBranch.street : "",
          district: dados && dados.unitBranch && dados.unitBranch.district ? dados.unitBranch.district : "",
          number: dados && dados.unitBranch && dados.unitBranch.number ? dados.unitBranch.number : "",
          telephone: dados && dados.unitBranch && dados.unitBranch.telephone ? dados.unitBranch.telephone : "",
          emailCompany: dados && dados.unitBranch && dados.unitBranch.emailCompany ? dados.unitBranch.emailCompany : "",
          number_order_service: dados && dados.number_order_service ? dados.number_order_service : "",
          dateCreate: dados && dados.created_at ? formatarData(dados.created_at) : "",
          nameResponsible: dados && dados.client && dados.client.nameResponsible ? dados.client.nameResponsible : "",
          cpfResponsible: dados && dados.client && dados.client.cpfResponsible ? dados.client.cpfResponsible : "",
          telephoneClient: dados && dados.client && dados.client.telephone ? dados.client.telephone : "0000-000",
          cellphoneClient: dados && dados.client && dados.client.cellphone ? dados.client.cellphone : "(00) 0 0000-0000",
          emailClient: dados && dados.client && dados.client.email ? dados.client.email : "",
          namePlant: dados && dados.plant && dados.plant.name ? dados.plant.name : "",
          addressPlant: dados && dados.plant && dados.plant.address ? dados.plant.address : "",
          titulo: dados && dados.title ? dados.title : "",
          status: dados && dados.status ? dados.status : "",
          description: dados && dados.description ? dados.description : "",
          companyName: dados && dados.unitBranch && dados.unitBranch.companyName ? dados.unitBranch.companyName : "",
          value_sub_final: dados && dados.value_sub_final ? dados.value_sub_final : "0",
          value_discont: dados && dados.value_discont ? dados.value_discont : "0",
          value_final: dados && dados.value_final ? dados.value_final : "0",
          itensProposal: dados && dados.itensProposal ? 
          dados.itensProposal.map((item: any) => {
              const my_item = {
                  service: item ? 
                          item.tipo === "SERVICO" ? 
                              item.typeService && item.typeService.description ? item.typeService.description 
                          : "" 
                      : item.tipo === "PRODUTO" ? 
                          item.product && item.product.description ? item.product.description 
                          : "" 
                      : ""  
                  : "",
                  description: item && item.description ? item.description : "",
                  value: item && item.value ? item.value : "",
                  amount: item && item.amount ? item.amount : "",
                  sub_value: item && item.sub_value ? item.sub_value : "",
              }
              return my_item
          }) : []

      }

      Object.keys(dados_OS).forEach(chave => {
          if(chave === 'itensProposal'){
              const servicesHtml = dados_OS[chave].map((service: any) => {
                  const tds = Object.keys(service).map(key => {
                    
                      if(key === 'sub_value' || key === 'value'){
                          return `<td>R$ ${service[key]},00</td>`
                      }else{
                          return `<td>${service[key]}</td>`
                      }

                    //return `<td>${report[key]}</td>`;
                  }).join('');
                  return `<tr>${tds}</tr>`;
                }).join('');
      
                novoHtml = novoHtml.replace(
                  `{{${chave}}}`, 
                  `<table style="width: 100%;">
                      <thead>
                          <tr>
                              <th style="background: #e1e3ea;">Serviço/Produto</th>
                              <th style="background: #e1e3ea;">Descrição</th>
                              <th style="background: #e1e3ea;">Valor Unit.</th>
                              <th style="background: #e1e3ea;">Qtd</th>
                              <th style="background: #e1e3ea;">Total</th>
                          </tr>
                      </thead>
                      <tbody id="table_services">${servicesHtml}</tbody>
                  </table>`
                  // `<tbody id="table_services">${servicesHtml}</tbody>`
                );
          }else{
              const marcador = new RegExp(`{{${chave}}}`, 'g');
              novoHtml = novoHtml.replace(marcador, dados_OS[chave] );
          }
      });
      return novoHtml;
  }

  const fetchHtmlAsString = async () => {
      try {
        const response = await axios.get('/media/HTMLs/template_PRO.html', { responseType: 'text' });
        return response.data;
      } catch (error) {
        console.error('Error fetching HTML file:', error);
        return null;
      }
  };

  const previewPDF = async (itemProposal: any) => {
      try {
        const template = itemProposal 
        && itemProposal.templatesProposals 
        && itemProposal.templatesProposals.length > 0 
        ? itemProposal.templatesProposals[0].textHTML 
        : await fetchHtmlAsString();

        const template_modificad = await replaceValues(itemProposal, template)
        const myReturnLink = await linkPDF(template_modificad ? template_modificad : "<h1>Template não encontrado! </h1>")
        window.open(myReturnLink, '_blank');
        
        //setUrl(myReturnLink) 
      
      } catch (error) {
        console.log(error)  
      }
  }

  const updatePhasesFunnel = async () => {
    try {
      setLoadingPhaseFunnel(true)
      const myData = {
        description: inputValue,
      }
      await putPhasesFunnelOne(myData, isColumnSelected)
      toggleToast("Sucesso ao atualizar a etapa do funil. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar a etapa do funil. ", "error")
    }finally{
      setLoadingPhaseFunnel(false)
      refresh()

      setInputValue("");
      setIsEditing(false);
      setColumnSelected("")
      //handleClose()
      //window.location.reload()
    }
  }

  const updateProposalSubmit = async (id: string, idPhaseFunnel: string) => {
    try {
      const myData = {
        idPhaseFunnel: idPhaseFunnel,
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      await putProposalOne(id, myData)
      toggleToast("Sucesso ao atualizar a proposta. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao atualizar a proposta. ", "error")
    }finally{
      refresh()
    }
  }

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // console.log(`Tarefa ${draggableId} movida para a coluna ${destination.droppableId} na posição ${destination.index}`);
    updateProposalSubmit(draggableId, destination.droppableId)

    const startColumn = boardData.columns[source.droppableId];
    const endColumn = boardData.columns[destination.droppableId];

    if (startColumn === endColumn) {
      const newTaskIds = Array.from(startColumn.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...startColumn,
        taskIds: newTaskIds,
      };

      const newState = {
        ...boardData,
        columns: {
          ...boardData.columns,
          [newColumn.id]: newColumn,
        },
      };

      setBoardData(newState);
    } else {
      const startTaskIds = Array.from(startColumn.taskIds);
      startTaskIds.splice(source.index, 1);

      const newStartColumn = {
        ...startColumn,
        taskIds: startTaskIds,
      };

      const endTaskIds = Array.from(endColumn.taskIds);
      endTaskIds.splice(destination.index, 0, draggableId);

      const newEndColumn = {
        ...endColumn,
        taskIds: endTaskIds,
      };

      const newState = {
        ...boardData,
        columns: {
          ...boardData.columns,
          [newStartColumn.id]: newStartColumn,
          [newEndColumn.id]: newEndColumn,
        },
      };

      setBoardData(newState);
    }
  };

const getProposalsByPhaseFunnel = (proposals: any[], phaseFunnelId: string): string[] => {
  return proposals
    .filter(proposal => proposal.phaseFunnel && proposal.phaseFunnel.id === phaseFunnelId)
    .map(proposal => proposal.id);
};

// const getProposalsByPhaseFunnel = (proposals: any[], phaseFunnelId: string): string[] => {
//     return proposals
//       .filter(proposal => proposal.phaseFunnel.id === phaseFunnelId)
//       .map(proposal => proposal.id);
//   };

// const generateColumns = (phases: any[]): Columns => {
//     return phases.reduce((acc, phase, index) => {
//       const columnId = `${phase.id ? phase.id : index + 1}`;
//       acc[columnId] = {
//         id: columnId,
//         title: phase.description ? phase.description : "",
//         taskIds: proposals && proposals.length > 0 ? getProposalsByPhaseFunnel(proposals, columnId) : [],
//       };
//       return acc;
//     }, {} as Columns);
// };

  const generateColumns = (phases: any[]): Columns => {
    const sortedPhases = phases.sort((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity));

    return sortedPhases.reduce((acc, phase, index) => {
      const columnId = `${phase.id ? phase.id : index + 1}`;
      acc[columnId] = {
        id: columnId,
        title: phase.description ? phase.description : "",
        taskIds: proposals && proposals.length > 0 ? getProposalsByPhaseFunnel(proposals, columnId) : [],
      };
      return acc;
    }, {} as Columns);
  };

  const getColumnIds = (columns: Columns): string[] => {
      return Object.keys(columns);
  };

  const transformTasks = (tasks: any[]): TaskMap => {
      return tasks.reduce((acc, task, index) => {
        const taskId = `${task.id ? task.id : index + 1}`;
        acc[taskId] = {
          id: taskId,
          content: task.title ? task.title : "",
        };
        return acc;
      }, {} as TaskMap);
  };

  const handleButtonClickDelete = (event, proposal) => {
    event.stopPropagation();
    
    setMyProposal(proposal)
    setShowCreateAppModalDelete(true)
  };

  const handleButtonClickUpdate = (event, proposal) => {
    event.stopPropagation();

    proposal.idPhaseFunnel = proposal.phaseFunnel ? proposal.phaseFunnel.id : null; 
    proposal.idsCollaborators = proposal.collaborators && proposal.collaborators.length > 0 ? proposal.collaborators.map((collaborator: any) => collaborator.id) : []
    proposal.idPlant = proposal.plant ? proposal.plant.id : null;
    proposal.idClient = proposal.client ? proposal.client.id : null;
    proposal.idUnitBranch = proposal.unitBranch ? proposal.unitBranch.id : null;
    proposal.idsCollaborator = proposal.collaborators && proposal.collaborators.length > 0 ? [proposal.collaborators[0].id] : null;
    
    setMyProposal(proposal)
    setShowCreateAppModal(true)
  };

  const handleButtonClickTemplate = (event, proposal) => {
    event.stopPropagation();
    setMyProposal(proposal)

    setShowDocumentAppModalTemplate(true)
  }

  const handleEditClick = (value: string, id: string) => {
    setIsEditing(true);
    setColumnSelected(id)
    setInputValue(value)
  };

  const handleSaveClick = () => {
    updatePhasesFunnel()
    //setTitle(inputValue);
    //setIsEditing(false);
  };

  const handleCancelClick = () => {
    setInputValue("");
    setIsEditing(false);
    setColumnSelected("")
  };

  const handleButtonClickDownload = async (event, proposal) => {
    setLoadingPDF(true)
    if(proposal && proposal.id){
        const myProposal = await getProposal(proposal.id)
        await previewPDF(myProposal)
    }
    setLoadingPDF(false)
    setIdProposal(null)
  }

  useEffect(() => {
  setBoardData({
      tasks: proposals && proposals.length > 0 ? transformTasks(proposals) : {},
      columns: filterFunnel && filterFunnel.phasesFunell ? generateColumns(filterFunnel.phasesFunell) : {},
      columnOrder: filterFunnel && filterFunnel.phasesFunell ? getColumnIds(generateColumns(filterFunnel.phasesFunell)) : []
      })
  }, [filterFunnel, proposals])

  return (
    <div className={`card ${className}`} style={{height: width > 1200 ? '62vh' : '68vh'}}>
        <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>{filterFunnel ? filterFunnel.description : ""}</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>
                    Quantidade de Propostas no Funil: {" "}
                    {/* {filterFunnel && filterFunnel.phasesFunnel && filterFunnel.phasesFunnel.proposals ? 
                    filterFunnel.phasesFunnel.reduce((acc, phasesFunnel) => acc + phasesFunnel.proposals.length, 0)
                    : 0} */}
                    {proposals.length}
                </span>
            </h3>
        </div>

        <div 
          className='card-body py-3' 
          style={
            width < 1200 ? {height: '20rem', overflow: 'auto', paddingTop: '0rem'} 
            : {width: '100%', overflowX: 'auto', overflowY: 'hidden', whiteSpace: 'nowrap', scrollBehavior: 'smooth'}}
        >
            <DragDropContext onDragEnd={onDragEnd}>
                <div style={{ display: 'flex'}}>
                    {boardData.columnOrder.map((columnId) => {
                    const column = boardData.columns[columnId];
                    const tasks = column.taskIds.map((taskId) => boardData.tasks[taskId]);

                    return (
                        <Droppable key={column.id} droppableId={column.id}>
                        {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className='card bg-light'
                              style={{
                                  marginRight: '8px',
                                  minWidth: '24rem',
                                  padding: '1rem',
                              }}
                            >

                            <div 
                              style={{
                                padding: '1rem', 
                                marginBottom: '1rem'
                              }} 
                              className='card border-bottom border-bottom-5 border-primary'
                            >
                              {/* <h3>{column.title}</h3> */}

                              <h3 className='card-title align-items-start flex-column' style={{ display: 'flex', flexDirection: 'column' }}>
                                {isEditing && isColumnSelected === column.id ? (
                                  <div className='mb-1' style={{display: 'flex', gap: '0.5rem', alignItems: 'center'}}>
                                    <input 
                                      type="text" 
                                      value={inputValue} 
                                      onChange={(e) => setInputValue(e.target.value)} 
                                      className='form-control form-control-sm'
                                      disabled={loadingPhaseFunnel}
                                    />
                                    <button 
                                      style={{height: '2.5rem', width: '2.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}} 
                                      onClick={handleCancelClick} 
                                      className='btn btn-light'
                                      disabled={loadingPhaseFunnel}
                                    >
                                      <KTSVG path='/media/icons/duotune/arrows/arr097.svg' className='svg-icon-3' />
                                    </button>
                                    <button 
                                      style={{height: '2.5rem', width: '2.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}} 
                                      onClick={handleSaveClick} 
                                      className='btn btn-primary'
                                      disabled={loadingPhaseFunnel}
                                    >
                                      <KTSVG path='/media/icons/duotune/arrows/arr085.svg' className='svg-icon-2' />
                                    </button>
                                  </div>
                                ) : (
                                  <span className='card-label fw-bold fs-3 mb-1' onClick={() => {handleEditClick(column.title, column.id)}}>
                                    {column.title}
                                  </span>
                                )}
                                 
                                 <span className='text-muted mt-1 fw-semibold fs-7'>
                                    {filterFunnel && filterFunnel.phasesFunell && filterFunnel.phasesFunell.find((p) => p.id === column.id) &&(
                                      <span>R$ {
                                        filterFunnel.phasesFunell.find((p) => p.id === column.id).proposals 
                                        ? filterFunnel.phasesFunell.find((p) => p.id === column.id).proposals.map(proposal => proposal.value_final).reduce((acc, curr) => acc + curr, 0) : 0},00 - </span>
                                    )}
                                    
                                    {filterFunnel && filterFunnel.phasesFunell && filterFunnel.phasesFunell.find((p) => p.id === column.id) &&(
                                      <span>{
                                        filterFunnel.phasesFunell.find((p) => p.id === column.id).proposals
                                        &&  filterFunnel.phasesFunell.find((p) => p.id === column.id).proposals.length > 0
                                        ? filterFunnel.phasesFunell.find((p) => p.id === column.id).proposals.length : 0} propostas</span>
                                    )}
                                </span>
                              </h3>

                              {/* <h3 className='card-title align-items-start flex-column' style={{display: 'flex',flexDirection: 'column'}}>
                                <span className='card-label fw-bold fs-3 mb-1'>{column.title}</span>
                                <span className='text-muted mt-1 fw-semibold fs-7'>
                                    {filterFunnel && filterFunnel.phasesFunell && filterFunnel.phasesFunell.find((p) => p.id === column.id) &&(
                                      <span>R$ {
                                        filterFunnel.phasesFunell.find((p) => p.id === column.id).proposals 
                                        ? filterFunnel.phasesFunell.find((p) => p.id === column.id).proposals.map(proposal => proposal.value_final).reduce((acc, curr) => acc + curr, 0) : 0},00 - </span>
                                    )}
                                    
                                    {filterFunnel && filterFunnel.phasesFunell && filterFunnel.phasesFunell.find((p) => p.id === column.id) &&(
                                      <span>{
                                        filterFunnel.phasesFunell.find((p) => p.id === column.id).proposals
                                        &&  filterFunnel.phasesFunell.find((p) => p.id === column.id).proposals.length > 0
                                        ? filterFunnel.phasesFunell.find((p) => p.id === column.id).proposals.length : 0} propostas</span>
                                    )}
                                </span>
                              </h3> */}
                            </div>

                            <div className='card p-1 mb-4'>
                              <button
                                onClick={() =>{
                                  setMyProposal({
                                    idPhaseFunnel: column.id
                                  })
                                  setShowCreateAppModal(true)
                                }}
                                //href='#'
                                className='btn btn-sm btn-light-primary'
                                style={{width: '100%'}}
                                //data-bs-target='#kt_modal_create_unitBranch'

                              >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-1' />
                              </button>
                            </div>

                            <div style={{height: '25rem', overflow: 'auto'}}>
                              {tasks.map((task, index) => (
                                <Draggable key={task.id} draggableId={task.id} index={index}>
                                  {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className='card p-1 mb-3 border border-1'
                                        style={{
                                          //borderRadius: '4px',
                                            ...provided.draggableProps.style,
                                        }}
                                      >
                                        <div className='bg-primary p-2' style={{borderRadius: '4px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                          <h3 className='card-title align-items-start flex-column' style={{display: 'flex',flexDirection: 'column'}}>
                                            <span className='card-label fw-bold fs-5 mb-1'>{task.content}</span>
                                            <span className='text-light mt-1 fw-semibold fs-8'>
                                                R$ {proposals.find((p) => p.id === task.id)?.value_final ? proposals.find((p) => p.id === task.id)?.value_final : '00'},00
                                            </span>
                                          </h3>

                                          <div>
                                            <div className='d-flex align-items-center'>
                                                {proposals.find((p) => p.id === task.id)?.collaborators?.length > 0 &&
                                                  proposals.find((p) => p.id === task.id).collaborators.map((collaborator) => {
                                                    return (
                                                      <div key={collaborator.id}>
                                                        <div className='symbol symbol-30px symbol-circle me-5'>
                                                          <div className='symbol symbol-30px symbol-circle '>
                                                            <span className={`symbol-label bg-light-primary text-primary fs-7 fw-bolder bg-hover-primary text-hover-light`}>
                                                              {initialLetters(collaborator.name)}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  })}
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{marginTop: '0.5rem'}}>
                                            <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                                              <KTSVG path='/media/icons/duotune/communication/com006.svg' className='svg-icon-1' />
                                              <h3 className='card-title align-items-start flex-column' style={{display: 'flex',flexDirection: 'column', alignItems: 'center'}}>
                                                <span className='text-muted fw-semibold fs-8'>
                                                    {proposals.find((p) => p.id === task.id)?.client && proposals.find((p) => p.id === task.id)?.client.nameResponsible 
                                                    ? proposals.find((p) => p.id === task.id)?.client.nameResponsible : "Não identificado. "}
                                                </span>
                                              </h3>
                                            </div>
                                            <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                                              <KTSVG path='/media/icons/duotune/general/gen014.svg' className='svg-icon-1' />
                                              <h3 className='card-title align-items-start flex-column' style={{display: 'flex',flexDirection: 'column', alignItems: 'center'}}>
                                                <span className='text-muted fw-semibold fs-8'>
                                                    {proposals.find((p) => p.id === task.id)?.created_at ? formatarDataV2(proposals.find((p) => p.id === task.id)?.created_at) : "Não identificado. "}
                                                </span>
                                              </h3>
                                            </div>
                                            <div className='separator my-3'></div>

                                            <div className='my-2' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                              <div>
                                                  <span 
                                                    className={
                                                        `badge badge-light-${
                                                            proposals.find((p) => p.id === task.id)?.status !== null
                                                            ? proposals.find((p) => p.id === task.id)?.status === "NEGOCIO FECHADO"
                                                            ? "success"
                                                            : proposals.find((p) => p.id === task.id)?.status === "NEGOCIO RECUSADO"
                                                            ? "danger"
                                                            : proposals.find((p) => p.id === task.id)?.status === "EM NEGOCIACAO"
                                                            ? "primary"
                                                            : "secondary"
                                                            : "secondary"
                                                        } fs-9 fw-semibold`
                                                    }
                                                >
                                                    {
                                                    proposals.find((p) => p.id === task.id)?.status ? proposals.find((p) => p.id === task.id)?.status : <NotImported className="fs-7 fw-bolder"/>
                                                    }
                                                </span>
                                              </div>
                                              <div className='d-flex justify-content-end flex-shrink-0'>
                                                  <button
                                                      className='btn btn-icon btn-bg-light btn-active-color-primary me-1'
                                                      style={{height: '2rem', width: '2rem'}}
                                                      onClick={() => {
                                                        setMyProposal(proposals.find((p) => p.id === task.id))
                                                        setShowCreateAppModalDetails(true)
                                                      }}
                                                  >
                                                      {/* <KTSVG
                                                      path='/media/icons/duotune/general/gen019.svg'
                                                      className='svg-icon-3'
                                                      /> */}
                                                      <i className={`bi bi-eye-fill`} style={{fontSize: '1rem'}}></i>
                                                  </button>

                                                  <button
                                                      className='btn btn-icon btn-bg-light btn-active-color-primary me-1'
                                                      style={{height: '2rem', width: '2rem'}}
                                                      onClick={(event) => {
                                                        setIdProposal(proposals.find((p) => p.id === task.id).id)
                                                        handleButtonClickDownload(event, proposals.find((p) => p.id === task.id))
                                                      }}
                                                  >
                                                      {/* <KTSVG
                                                      path='/media/icons/duotune/general/gen019.svg'
                                                      className='svg-icon-3'
                                                      /> */}
                                                      {loadingPDF && idProposal && idProposal === proposals.find((p) => p.id === task.id).id ? <LoadingSpan /> : <i className='bi bi-filetype-pdf' style={{fontSize: '1rem'}}></i>}
                                                      {/* <i className={`bi bi-eye-fill`} style={{fontSize: '1rem'}}></i> */}
                                                  </button>

                                                  <button
                                                      onClick={(event: any) =>{
                                                          handleButtonClickUpdate(event, proposals.find((p) => p.id === task.id))
                                                      }}
                                                      className='btn btn-icon btn-bg-light btn-active-color-primary me-1'
                                                      style={{height: '2rem', width: '2rem'}}
                                                  >
                                                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-6'/>
                                                  </button>
                                                  <button
                                                      onClick={(event: any) => {
                                                          handleButtonClickDelete(event, proposals.find((p) => p.id === task.id))
                                                      }}
                                                      className='btn btn-icon btn-bg-light btn-active-color-primary me-1'
                                                      style={{height: '2rem', width: '2rem'}}
                                                  >
                                                      <KTSVG
                                                      path='/media/icons/duotune/general/gen027.svg'
                                                      className='svg-icon-6'
                                                      />
                                                  </button>

                                                  <button
                                                      onClick={(event: any) => {
                                                          handleButtonClickTemplate(event, proposals.find((p) => p.id === task.id))
                                                      }}
                                                      className='btn btn-icon btn-bg-light btn-active-color-primary'
                                                      style={{height: '2rem', width: '2rem'}}
                                                  >
                                                      <KTSVG
                                                      path='/media/icons/duotune/text/txt009.svg'
                                                      className='svg-icon-6'
                                                      />
                                                  </button>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  )}
                                </Draggable>
                              ))}
                            </div>
                            {provided.placeholder}
                            </div>
                        )}
                        </Droppable>
                    );
                    })}
                    <div>
                      <button
                        onClick={() =>{
                          setMyFunnel(filterFunnel)
                          setShowCreateAppModalFunnel(true)
                          //setShowCreateAppModalProposal(true)
                        }}
                        //href='#'
                        className='btn btn-sm btn-light-primary'
                        //data-bs-target='#kt_modal_create_unitBranch'

                      >
                        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                        Adicionar Nova Fase

                      </button>
                    </div>
                </div>
            </DragDropContext>
        </div>

        <ModalAddUpdateProposal
            show={showCreateAppModal}
            id={myProposal ? myProposal.id : undefined} 
            data={myProposal ? myProposal : undefined}
            handleClose={() => {
              setMyProposal(null)
              setShowCreateAppModal(false)
              refresh()
            }} 
          />

          <ModalDetailsProposals
            show={showCreateAppModalDetails}
            data={myProposal ? myProposal : undefined}
            id={myProposal ? myProposal.id : undefined} 
            handleClose={() => {
              setMyProposal(null)
              setShowCreateAppModalDetails(false)
              refresh()
            }} 
          />

          <ModalDeleteProposal
            show={showCreateAppModalDelete}
            data={myProposal ? myProposal : undefined}
            id={myProposal ? myProposal.id : undefined} 
            handleClose={() => {
              setMyProposal(null)
              setShowCreateAppModalDelete(false)
              refresh()
            }} 
          />

        {<ModalTemplateDocument
          show={showDocumentAppModalTemplate}
          id={myProposal ? myProposal.id : undefined} 
          handleClose={() => {
            setMyProposal(null)
            setShowDocumentAppModalTemplate(false)
            refresh()
          }} 
        />}
        
    </div>
  );
};

export default KanbanBoard;
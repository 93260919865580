/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'

import { useFormik } from 'formik'
import * as Yup from "yup";


//import './styles.css';
import { StepperComponent } from '../../../../../../../_metronic/assets/ts/components';
import { useToast } from '../../../../../components/Toats';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import { Loading } from '../../../../../components/Loadings/loading';
import { LoadingSpan } from '../../../../../components/Loadings/loadingSpan';
import CKEditorTemplate from './components/CkEditor';
import { PreviewTemplate } from './components/PreviewTemplate';
import { getTemplateProposalAll } from '../../../../../../services/templatesProposal';
import { formatarData } from '../../../../../../utils/date';
import { getProposalOne, putProposalOne } from '../../../../../../services/proposalsService';
import { getFileBase64 } from '../../../../../../services/files';

type Props = {
    id: string | undefined;
    show: boolean;
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
  data: Yup.date(),
  generation: Yup.number(),
  idsTemplates: Yup.array().of(Yup.object().shape({})),
});

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalTemplateDocument = ({show, handleClose, id}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  const [loadingReports, setLoadingReports] = useState(false)
  const [loadingTemplates, setLoadingTemplates] = useState(false)
  const [, setLoadingPostReport] = useState(false)
  const [largura, setLargura] = useState(window.innerWidth);

  const [initialValues] = useState<any>({
    description:  "",
    title:  "",
    dateInit:  "",
    dateEnd:  "",
    status:  "",
    value_discont:  "",
    value_sub_final:  "",
    value_final:  "",
    address:  "",
    created_at:  "",
    updated_at:  "",
    phaseFunnel: null,
    unitBranch: null,
    plant: null,
    client: null,
    collaborators: [],
    itensProposal: [],
    idTemplate: "",
    idsTemplates: [],
  })

  const [myTemplates, setMyTemplates] = useState<any[]>([])
  const [visualizadReport, setVisualizadReport] = useState<boolean>(false)
  const [myTemplate, setMyTemplate] = useState<any>({
    description: null,
    id: null,
    textHTML: "<div >Crie o seu template ou escolha um template disponivel. </div>",
    type: "private",
    type_template: null
  })
  const [initialHtml, setInitialHtml] = useState<string>("<div>Crie o seu template ou escolha um template disponivel. </div>")
  const [editorStateV2, setEditorStateV2] = useState(() => {
    return { __html: '<div>Crie o seu template ou escolha um template disponivel. </div>' };
  });

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  const getImage = async (key: string) => {
    try {
      const url = await getFileBase64(key)
      return url.base64

    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const submit = () => {
    if(id){
      updateMyProposal()
    }
  }
  
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: submit,
  });


  const closed = () => {
    formik.setValues(initialValues)
    setInitialHtml("<div style='height: 50rem'>Crie o seu template ou escolha um template disponivel. </div>")
    setMyTemplate({
      description: null,
      id: null,
      textHTML: "<div style='height: 50rem'>Crie o seu template ou escolha um template disponivel. </div>",
      type: "private"
    })
    setEditorStateV2({ __html: "<div style='height: 50rem'>Crie o seu template ou escolha um template disponivel. </div>" })
    handleClose()
  }



  const getProposal = async () => {
    try {
      if(id){
        setLoading(true)
        const myProposal = await getProposalOne(id ? id : "")
        
        const myObject = {
          description: myProposal.description ? myProposal.description : "",
          title: myProposal.title ? myProposal.title : "",
          dateInit: myProposal.dateInit ? myProposal.dateInit : "",
          dateEnd: myProposal.dateEnd ? myProposal.dateEnd : "",
          status: myProposal.status ? myProposal.status : "",
          value_discont: myProposal.value_discont ? myProposal.value_discont : "",
          value_sub_final: myProposal.value_sub_final ? myProposal.value_sub_final : "",
          value_final: myProposal.value_final ? myProposal.value_final : "",
          address: myProposal.address ? myProposal.address : "",
          created_at: myProposal.created_at ? myProposal.created_at : "",
          updated_at: myProposal.updated_at ? myProposal.updated_at : "",
          phaseFunnel: myProposal.phaseFunnel ? myProposal.phaseFunnel : null,
          unitBranch: myProposal.unitBranch ? myProposal.unitBranch : null,
          plant: myProposal.plant ? myProposal.plant : null,
          client: myProposal.client ? myProposal.client : null,
          collaborators: myProposal.collaborators ? myProposal.collaborators : [],
          itensProposal: myProposal.itensProposal ? myProposal.itensProposal : [],
          idTemplate: myProposal.templatesProposals && myProposal.templatesProposals.length > 0 ? myProposal.templatesProposals[0].id : "",
          idsTemplates: myProposal.templatesProposals && myProposal.templatesProposals.length > 0 ? myProposal.templatesProposals : ""
        }

        formik.setValues(myObject)

        if(myProposal.templatesProposals && myProposal.templatesProposals.length > 0){
          const myTemplateOne = myProposal.templatesProposals[0]
          if(myTemplateOne){
            const selectTemplateOne = myTemplates.find((template) => template.id === myTemplateOne.id)
            if(selectTemplateOne){
              setInitialHtml(selectTemplateOne.textHTML)
              setMyTemplate(selectTemplateOne)
            }
          }
          
        }
      }
    } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar a proposta. ", "error")
    }finally{
        setLoading(false)
    }
  }

  const getTemplate = async () => {
    try {
      setLoadingTemplates(true)
 
      const templates = await getTemplateProposalAll()
      setMyTemplates(templates)

      //toggleToast("Sucesso ao buscar os templates. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao buscar os templates. ", "error")
    }finally{
      setLoadingTemplates(false)
    }
  }

  const updateMyProposal = async () => {
    try {
      setLoadingPostReport(true)

      const myData = {
        idTemplate: formik.values.idTemplate
      }
      await putProposalOne(id ? id : "", myData)
      //setMyTemplates(templates)

      toggleToast("Sucesso ao salvar a proposta. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao salvar a proposta. ", "error")
    }finally{
      setLoadingPostReport(false)
      closed()
    }
  }

  const refresh = () => {
    getTemplate()
  }

  const replaceValues = async (dados: any, html: string) => {
    let novoHtml = html;
    const imageCompany = dados 
        && dados.unitBranch 
        && dados.unitBranch.company 
        && dados.unitBranch.company.image 
        ? await getImage(dados.unitBranch.company.image_dark)
        : "https://i.postimg.cc/kXzT1qjT/sua-logo-aqui.pngs";

    let dados_OS = {
        imageCompany: imageCompany ? `data:image/jpeg;base64,${imageCompany}` : "https://i.postimg.cc/kXzT1qjT/sua-logo-aqui.pngs",
        corporateName: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.corporateName ? dados.unitBranch.company.corporateName : "",
        cnpj: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.cnpj ? dados.unitBranch.company.cnpj : "",
        street: dados && dados.unitBranch && dados.unitBranch.street ? dados.unitBranch.street : "",
        district: dados && dados.unitBranch && dados.unitBranch.district ? dados.unitBranch.district : "",
        number: dados && dados.unitBranch && dados.unitBranch.number ? dados.unitBranch.number : "",
        telephone: dados && dados.unitBranch && dados.unitBranch.telephone ? dados.unitBranch.telephone : "",
        emailCompany: dados && dados.unitBranch && dados.unitBranch.emailCompany ? dados.unitBranch.emailCompany : "",
        number_order_service: dados && dados.number_order_service ? dados.number_order_service : "",
        dateCreate: dados && dados.created_at ? formatarData(dados.created_at) : "",
        nameResponsible: dados && dados.client && dados.client.nameResponsible ? dados.client.nameResponsible : "",
        cpfResponsible: dados && dados.client && dados.client.cpfResponsible ? dados.client.cpfResponsible : "",
        telephoneClient: dados && dados.client && dados.client.telephone ? dados.client.telephone : "0000-000",
        cellphoneClient: dados && dados.client && dados.client.cellphone ? dados.client.cellphone : "(00) 0 0000-0000",
        emailClient: dados && dados.client && dados.client.email ? dados.client.email : "",
        namePlant: dados && dados.plant && dados.plant.name ? dados.plant.name : "",
        addressPlant: dados && dados.plant && dados.plant.address ? dados.plant.address : "",
        titulo: dados && dados.title ? dados.title : "",
        status: dados && dados.status ? dados.status : "",
        description: dados && dados.description ? dados.description : "",
        companyName: dados && dados.unitBranch && dados.unitBranch.companyName ? dados.unitBranch.companyName : "",
        value_sub_final: dados && dados.value_sub_final ? dados.value_sub_final : "0",
        value_discont: dados && dados.value_discont ? dados.value_discont : "0",
        value_final: dados && dados.value_final ? dados.value_final : "0",
        itensProposal: dados && dados.itensProposal ? 
        dados.itensProposal.map((item: any) => {
            const my_item = {
                service: item ? 
                        item.tipo === "SERVICO" ? 
                            item.typeService && item.typeService.description ? item.typeService.description 
                        : "" 
                    : item.tipo === "PRODUTO" ? 
                        item.product && item.product.description ? item.product.description 
                        : "" 
                    : ""  
                : "",
                description: item && item.description ? item.description : "",
                value: item && item.value ? item.value : "",
                amount: item && item.amount ? item.amount : "",
                sub_value: item && item.sub_value ? item.sub_value : "",
            }
            return my_item
        }) : []

    }

    Object.keys(dados_OS).forEach(chave => {
        if(chave === 'itensProposal'){
            const servicesHtml = dados_OS[chave].map((service: any) => {
                const tds = Object.keys(service).map(key => {
                  
                    if(key === 'sub_value' || key === 'value'){
                        return `<td>R$ ${service[key]},00</td>`
                    }else{
                        return `<td>${service[key]}</td>`
                    }

                  //return `<td>${report[key]}</td>`;
                }).join('');
                return `<tr>${tds}</tr>`;
              }).join('');
    
              novoHtml = novoHtml.replace(
                `{{${chave}}}`, 
                `<table style="width: 100%;">
                    <thead>
                        <tr>
                            <th style="background: #e1e3ea;">Serviço/Produto</th>
                            <th style="background: #e1e3ea;">Descrição</th>
                            <th style="background: #e1e3ea;">Valor Unit.</th>
                            <th style="background: #e1e3ea;">Qtd</th>
                            <th style="background: #e1e3ea;">Total</th>
                        </tr>
                    </thead>
                    <tbody id="table_services">${servicesHtml}</tbody>
                </table>`
                // `<tbody id="table_services">${servicesHtml}</tbody>`
              );
        }else{
            const marcador = new RegExp(`{{${chave}}}`, 'g');
            novoHtml = novoHtml.replace(marcador, dados_OS[chave] );
        }
    });
    return novoHtml;
  }


  useEffect(() => {    
    getTemplate()

    if(id){
      getProposal()
    }else{
      formik.setValues(initialValues)
    }

    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };

  }, [show]);

  useEffect(() => {
    const fetch = async () => {
      const newHtml = await replaceValues(formik.values, initialHtml)
      setEditorStateV2({ __html: newHtml })
    }
    fetch()
    //const newHtml = substituirValores(formik.values, initialHtml)
  }, [formik.values, initialHtml]);



  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      //modal-xl
      dialogClassName='modal-dialog modal-dialog-centered modal-fullscreen'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Edição do template da proposta</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closed}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      {
        loading || loadingReports ? 
        <div style={{display: 'flex', justifyContent: 'center', height: '40rem', alignItems: 'center'}}>
          <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
        </div>
        :
          <div className='modal-body py-lg-8 px-lg-8' style={{display: 'flex', gap: '1rem'}}>

            <div style={{marginBottom: '1rem', flex: '1', width: '50%'}}>
              <div style={{width: '100%', overflowY: 'auto', display: 'flex', justifyContent: 'space-between'}}>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap' style={{marginBottom: '1.5rem'}}>
                  {/* <li className='nav-item'>
                    <a
                      className="nav-link active text-active-primary me-6"
                      data-bs-toggle="tab"
                      href="#responsable"
                    >
                      Dados da Geração
                    </a>
                  </li> */}
                  <li className='nav-item'>
                    <a
                      className="nav-link active text-active-primary me-6"
                      data-bs-toggle="tab"
                      href="#unitBranchLinked"
                    >
                      Edição do Layout
                    </a>
                  </li>
                  {(largura < 1200 || visualizadReport === true) && (<li className='nav-item'>
                    <a
                      className="nav-link text-active-primary me-6"
                      data-bs-toggle="tab"
                      href="#visualizeReport"
                    >
                      Visualização do Relatório
                    </a>
                  </li>)}
                </ul>

                {largura > 1200 && (<div style={{display: 'flex', gap: '1rem'}}>
                  <h3 className='flex-column' style={{display: "flex", alignItems: 'center', justifyContent: 'center'}}>
                    <span className='text-muted fw-semibold fs-7'>Ampliar/reduzir editor - dados</span>
                  </h3>
                  <div className='form-check form-check-custom form-check-primary form-check-solid'>
                    <input 
                      className='form-check-input' 
                      type='checkbox'
                      checked={visualizadReport}
                      onChange={(e) =>{
                        //updateData(e.target.value, e.target.checked)
                        setVisualizadReport(e.target.checked)
                      }}
                      //onChange={formik.handleChange("idsPlants")}
                    />
                  </div>
                </div>)}
              </div>
              

              <div className="tab-content" id="myTabContent2" style={{width: '100%'}}>
                {/* <div
                  style={{width: '100%'}}
                  className="tab-pane fade active show"
                  id="responsable"
                  role="tabpanel"
                >
                  {!myTemplate.type_template || myTemplate.type_template === "DETALHADO" ?  
                    <StepModalValues initialHtml={editorStateV2.__html} formik={formik} updateGanerations={updateGanerations}/>
                  :
                  myTemplate.type_template === "GERACAO" &&
                    <StepModalValuesV2 initialHtml={editorStateV2.__html} formik={formik} updateGanerations={updateGanerations}/>}
                </div> */}
                
                <div
                  className="tab-pane fade active show"
                  id="unitBranchLinked"
                  role="tabpanel"
                >
                  <CKEditorTemplate 
                    initialHtml={initialHtml} 
                    setEditorStateV2={setEditorStateV2} 
                    mySelectTemplate={myTemplate}
                    refresh={refresh}
                    setInitialHtml={setInitialHtml}
                    formikReport={formik}
                    setMyTemplate={setMyTemplate}
                    myTemplates={myTemplates}
                  />
                </div>

               {(largura < 1200 || visualizadReport === true) && (<div
                  className="tab-pane fade show"
                  id="visualizeReport"
                  role="tabpanel"
                >
                  <PreviewTemplate
                    editorStateV2={editorStateV2} 
                    myTemplates={myTemplates} 
                    setInitialHtml={setInitialHtml} 
                    setMyTemplate={setMyTemplate}
                    myTemplate={myTemplate}
                    formik={formik}
                    loadingTemplates={loadingTemplates}
                    refresh={refresh}
                  />
                </div>)}
              </div>
            </div>


            {
              largura > 1200 && visualizadReport === false &&(
                <div style={{flex: '1', marginTop: '5.2rem', width: '50%'}}>
                  <PreviewTemplate
                    editorStateV2={editorStateV2} 
                    myTemplates={myTemplates} 
                    setInitialHtml={setInitialHtml} 
                    setMyTemplate={setMyTemplate}
                    myTemplate={myTemplate}
                    formik={formik}
                    loadingTemplates={loadingTemplates}
                    refresh={refresh}
                  />
                </div>
              )
            }
            
          </div>
        }

        <div className='d-flex flex-stack modal-footer py-lg-8 px-lg-8' style={{justifyContent: 'end', marginBottom :'1rem', gap: '1rem'}}>

          <div style={{display: 'flex', gap: '1rem', justifyContent: 'end', width: '100%'}}>
            
            <button
              type='button'
              className='btn btn-lg btn-primary'
              disabled={loading}
              //data-kt-stepper-action='submit'
              onClick={submit}
            >
              Salvar Proposta
              {
                loading ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                :
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
              }
            </button>
          
          </div>
        </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalTemplateDocument}

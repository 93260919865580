import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
//import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LoadingSpan } from '../Loadings/loadingSpan';
import { KTSVG } from '../../../../_metronic/helpers';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { postTemplateOne, putTemplateAll } from '../../../services/templates';
import { getUnitBranchAll } from '../../../services/unitBranchService';
import { DropdownMenuDates } from './components/DropdownMenuDados/dropdownMenuDates';
import Editor from "ckeditor5-custom-build/build/ckeditor";
import './styles.css'
import { getfileCloundUrl} from '../../../services/uploadService';
import { useAuth } from '../../../modules/auth';
import { getFile } from '../../../services/files';
import mammoth from 'mammoth';

// const editorConfiguration = {
//   plugins: [ Bold, Italic, Code],
//   toolbar: [ 'bold', 'italic', 'code'  ]
// };

type Props = {
  initialHtml: string,
  setEditorStateV2: any,
  mySelectTemplate: any
  refresh: any;
  setInitialHtml: any;
  formikReport : any;
  setMyTemplate: any;
  myTemplates: any[]
}



const validationSchema = Yup.object().shape({
  textHTML: Yup.string(),
  description: Yup.string(),
  type: Yup.string(),
  type_template:  Yup.string(),
  idUnitBranch: Yup.string()
});

const CKEditorV2 = ({initialHtml, setMyTemplate, mySelectTemplate, refresh, setInitialHtml, formikReport, myTemplates} : Props) => {
  const [, setEditorData] = useState('');
  const [generateTemplate, setGenerateTemplate] = useState(false);
  const [loading, setLoading] = useState(false)
  const [loadingUploadWord, setLoadingUploadWord] = useState(false)
  const [loadingUnitBranchs, setLoadingUnitBranchs] = useState(false)
  const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  const user = useAuth()
  const [textArquive, setTextArquive] = useState<string>("Escolha um arquivo (.docx).");

  const fileInputRef : any = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  //const editorRef = useRef<any>(null);

  const [initialValues] = useState({
    textHTML: initialHtml,
    description: null,
    type: "private",
    type_template: "DETALHADO",
    idUnitBranch : null
  })

  const verificadValues = () => {
    const myValues = {
      year: initialHtml.includes("{{year}}") ? true : false,
      month: initialHtml.includes("{{month}}") ? true : false,
      generation: initialHtml.includes("{{generation}}") ? true : false,
      performance: initialHtml.includes("{{performance}}") ? true : false,
      consumption: initialHtml.includes("{{consumption}}") ? true : false,
      saved: initialHtml.includes("{{saved}}") ? true : false,
      initPeriod: initialHtml.includes("{{initPeriod}}") ? true : false,
      endPeriod: initialHtml.includes("{{endPeriod}}") ? true : false, 
      corporateName: initialHtml.includes("{{corporateName}}") ? true : false,

      performance_anual: initialHtml.includes("{{performance_anual}}") ? true : false,
      generation_anual: initialHtml.includes("{{generation_anual}}") ? true : false,
      prog_12_meses: initialHtml.includes("{{prog_12_meses}}") ? true : false,
      capacity: initialHtml.includes("{{capacity}}") ? true : false,

      economia_co2: initialHtml.includes("{{economia_co2}}") ? true : false,
      arvores_salvas: initialHtml.includes("{{arvores_salvas}}") ? true : false,

      invoiced: initialHtml.includes("{{invoiced}}") ? true : false,
      total_economy: initialHtml.includes("{{total_economy}}") ? true : false,
      financial_feedback_month: initialHtml.includes("{{financial_feedback_month}}") ? true : false,
      financial_feedback_year: initialHtml.includes("{{financial_feedback_year}}") ? true : false,

      consumerRegister: initialHtml.includes("{{consumerRegister}}") ? true : false,
      consumerInstant: initialHtml.includes("{{consumerInstant}}") ? true : false,
      unitConsumerTotal: initialHtml.includes("{{unitConsumerTotal}}") ? true : false,
      energyInjected: initialHtml.includes("{{energyInjected}}") ? true : false,

      cnpj: initialHtml.includes("{{cnpj}}") ? true : false,
      companyName: initialHtml.includes("{{companyName}}") ? true : false,
      emailCompany: initialHtml.includes("{{emailCompany}}") ? true : false,
      namePlant: initialHtml.includes("{{namePlant}}") ? true : false,
      adressPlant: initialHtml.includes("{{adressPlant}}") ? true : false,
      mark: initialHtml.includes("{{mark}}") ? true : false,

      chart_comsuption: initialHtml.includes("{{chart_comsuption}}") ? true : false,
      graphic_mensal_combination: initialHtml.includes("{{graphic_mensal_combination}}") ? true : false,
      graphic_days: initialHtml.includes("{{graphic_days}}") ? true : false,
      graphic_months: initialHtml.includes("{{graphic_months}}") ? true : false,
      
    }
    return myValues
  }

  const [valuesReport, setVeluesReport] = useState(verificadValues())

  


  // const [editorStateV2, setEditorStateV2] = useState(() => {
  //   if(initialHtml){
  //     return { __html: initialHtml };
  //   }
  //   return { __html: '' };
  // });


  // const handleEditorReady = (editor) => {
  //   //setEditor(editor);
  //   editor.config.define('contentFilter', {
  //     classes: true,
  //     styles: true,
  //   });
  // };

  const getImage = async (key: string) => {
    try {
      const url = await getFile(key);
      return url
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    
    setInitialHtml(data);
    formik.setValues({...formik.values, textHTML: data})

    //setEditorStateV2({ __html: data });

  };

  const handleCheckBoxValues = (checked: boolean, value: string) => {
    if(checked){
      const newHTML = initialHtml + `{{${value}}} </br>`
      
      setInitialHtml(newHTML);
      formik.setValues({...formik.values, textHTML: newHTML})

    }else{
      const newHTML = initialHtml.replace(`{{${value}}}`, "");
      setInitialHtml(newHTML);
      formik.setValues({...formik.values, textHTML: newHTML})
    }
  }

  const handleCheckBoxValuesImg = async (value: string) => {
    if(
      user && user.currentUser 
      && user.currentUser.companies 
      && user.currentUser.companies.length > 0 
      && user.currentUser.companies[0].image){

      let newHTML = ""
      const url = await getImage(user.currentUser.companies[0].image)

      if(value === 'logo_100'){
        newHTML = initialHtml + `<img src="${url.url}" alt="" style="height:100px;"/> </br>`
      }

      if(value === 'logo_200'){
        newHTML = initialHtml + `<img src="${url.url}" alt="" style="height:200px;"/> </br>`
      }

      if(value === 'logo_500'){
        newHTML = initialHtml + `<img src="${url.url}" alt="" style="height:500px;"/> </br>`
      }

      if(value === 'logo_700'){
        newHTML = initialHtml + `<img src="${url.url}" alt="" style="height:700px;"/> </br>`
      }
      
      setInitialHtml(newHTML);
      formik.setValues({...formik.values, textHTML: newHTML})

    }
  }


  const getAllUnitBranchs = async () => {
    try {
      setLoadingUnitBranchs(true)
      const unitBranchs = await getUnitBranchAll()
      setUnitBranchs(unitBranchs)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      setLoadingUnitBranchs(false)
    }
  }

  const postTemplate = async () => {
    try {
      setLoading(true)
      const myData = {
        textHTML: formik.values.textHTML,
        description: formik.values.description,
        type: user.currentUser?.role.slug !== "ADMIN" ? "private" : "public",
        type_template: formik.values.type_template,
        idUnitBranch : formik.values.idUnitBranch
      }
 
      const myTemplate = await postTemplateOne(myData)
      setInitialHtml(myTemplate.textHTML)

      if(myTemplate){
        let formik_templates = formikReport.values.idsTemplates;
        const result = formik_templates.map(id => myTemplates.find(template => template.id === id) || null);

        const index = result.findIndex(template => template.type_template === myTemplate.type_template);
        if(index === -1){
          formik_templates.push(myTemplate.id)
        }else{
          formik_templates[index] = myTemplate.id
        }
        formikReport.setValues({...formikReport.values, idsTemplates: formik_templates})

        //formikReport.setValues({...formik.values, idTemplate: template.id})

        setMyTemplate(myTemplate)
      }

      toggleToast("Sucesso ao cadastrar o template. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao cadastrar template. ", "error")
    }finally{
      setLoading(false)
      refresh()
      setGenerateTemplate(false)
    }
  }

  const postImgUrl = async (file: any) => {
    try {
      //const url = await getfileUrl(file);
      const url = await getfileCloundUrl(file, 
        user && user.currentUser ?
        user.currentUser.role.slug === "EMPRESA" ?
          user.currentUser.companies && user.currentUser.companies.length > 0 ?
            user.currentUser.companies[0].corporateName
          : ""
        : user.currentUser.role.slug === "FILIAL" ?
            user.currentUser.unitBranchs && user.currentUser.unitBranchs.length > 0 ?
              user.currentUser.unitBranchs[0].companyName
            : ""
        : ""
      : ""
      )
      if(url && url.data && url.data.url){
        return url.data.url;
      }
      //return url;
    } catch (error) {
      toggleToast("Erro ao inserir a imagem. ", "error");
    }
  };

  const putTemplate = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        textHTML: formik.values.textHTML,
        description: formik.values.description,
        type: formik.values.type,
        type_template: formik.values.type_template
      }
 
      await putTemplateAll(id, myData)
      toggleToast("Sucesso ao atualizar o template. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar o template. ", "error")
    }finally{
      setLoading(false)
      refresh()
    }
  }

  // const handleWordUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //       const arrayBuffer = await file.arrayBuffer();
  //       const { value } = await mammoth.convertToHtml({ arrayBuffer });
  //       setInitialHtml(value)
  //   }
  // };


  const submit = (type: number) => {
    if(type === 1){
      if(mySelectTemplate){
        putTemplate(mySelectTemplate.id)
      }
    }else if (type === 0){
      postTemplate()
    }
  }


  // function uploadPlugin(editor: any) {
    
  // }

  // function uploadPlugin(editor: any) {
  //   editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
  //     return {
  //       upload: () => {
  //         return new Promise(async (resolve, reject) => {
  //           try {
  //             const body = new FormData();
  
  //             const file = await loader.file;
  //             body.append("file", file);
  
  //             const url = await postImgUrl(body);
  
  //             // Adicione um ID específico à imagem antes de inseri-la no editor
  //             const imageId = 'myImg'; // Substitua 'suaIdEspecifica' pelo ID desejado
  
  //             // Crie um elemento de imagem com o ID específico
  //             const img = new editor.model.insertContent(
  //               editor.model.schema.create('image', {
  //                 src: url.url,
  //                 attributes: {
  //                   'data-id': imageId,
  //                 },
  //               })
  //             );
  
  //             resolve({
  //               default: url.url,
  //             });
  //           } catch (error) {
  //             reject(error);
  //           }
  //         });
  //       },
  //     };
  //   };
  // }

//   function uploadPlugin(editor) {
//     editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
//         return {
//             upload: () => {
//                 return new Promise(async (resolve, reject) => {
//                     try {
//                         const body = new FormData();
//                         const file = await loader.file;
//                         body.append("file", file);

//                         const url = await postImgUrl(body);

//                         // Resolva a URL da imagem
//                         resolve({
//                             default: url.url,
//                         });

//                         // Adicione a imagem ao editor
//                         editor.model.change(writer => {
//                             const imageElement = writer.createElement('image', {
//                                 src: url.url,
//                                 'data-id': 'myImg' // ID específico da imagem
//                             });
//                             // Inserir a imagem no lugar do cursor atual
//                             editor.model.insertContent(imageElement, editor.model.document.selection);
//                         });
//                     } catch (error) {
//                         reject(error);
//                     }
//                 });
//             },
//         };
//     };
// }


// function uploadPlugin(editor: any) {
//   editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
//       return {
//           upload: () => {
//               return new Promise(async (resolve, reject) => {
//                   try {
//                       const body = new FormData();
//                       const file = await loader.file;
//                       body.append("file", file);

//                       const url = await postImgUrl(body);

//                       resolve({
//                           //default: url.url,
//                            default: url,
//                       });

//                       editor.model.change(writer => {
//                           const imageElement = writer.createElement('imageBlock', {
//                               src: url,
//                               'data-id': 'myImg'
//                           });
//                           const insertAtSelection = editor.model.document.selection.getFirstPosition();
//                           editor.model.insertContent(imageElement, insertAtSelection);
//                       });
//                   } catch (error) {
//                       reject(error);
//                   }
//               });
//           },
//       };
//   };
// }


// function uploadPlugin(editor: any) {
//   editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
//       return {
//           upload: () => {
//               return new Promise(async (resolve, reject) => {
//                   try {
//                       const body = new FormData();
//                       const file = await loader.file;
//                       body.append("file", file);

//                       const url = await postImgUrl(body); // Supondo que o url seja retornado dessa forma

//                       resolve({
//                           //default: url.url,
//                           default: url,
//                       });

//                       editor.model.change(writer => {
//                           const width = 795; 
//                           const height = 1052;

//                           const imageElement = writer.createElement('imageBlock', {
//                               src: url,
//                               'data-id': 'myImg',
//                               width: width,  // Adicionando a largura
//                               height: height, // Adicionando a altura
//                           });

//                           // Inserindo a imagem na posição atual do cursor
//                           const insertAtSelection = editor.model.document.selection.getFirstPosition();
//                           editor.model.insertContent(imageElement, insertAtSelection);
//                       });
//                   } catch (error) {
//                       reject(error);
//                   }
//               });
//           },
//       };
//   };
// }


// function uploadPlugin(editor: any) {
//   editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
//       return {
//           upload: () => {
//               return new Promise(async (resolve, reject) => {
//                   try {
//                       const body = new FormData();
//                       const file = await loader.file;
//                       body.append("file", file);

//                       const url = await postImgUrl(body); // Supondo que o url seja retornado dessa forma

//                       resolve({
//                           default: url,
//                       });

//                       editor.model.change(writer => {
//                           // Definindo largura e altura fixas
//                           const width = 600;  // Defina o valor de largura desejado
//                           const height = 400; // Defina o valor de altura desejado

//                           // Inserindo a imagem com largura e altura como atributos, não no estilo
//                           const imageElement = writer.createElement('imageBlock', {
//                               src: url,
//                               'data-id': 'myImg',
//                               width: width,  // Definindo largura como atributo
//                               height: height, // Definindo altura como atributo
//                           });

//                           // Inserindo a imagem na posição atual do cursor
//                           const insertAtSelection = editor.model.document.selection.getFirstPosition();
//                           editor.model.insertContent(imageElement, insertAtSelection);
//                       });
//                   } catch (error) {
//                       reject(error);
//                   }
//               });
//           },
//       };
//   };
// }

// const handleWordUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
//   const file = event.target.files?.[0];

//   if (file) {
//       const arrayBuffer = await file.arrayBuffer();

//       const { value } = await mammoth.convertToHtml(
//           { arrayBuffer },
//           {
//               //@ts-ignore
//               convertImage: mammoth.images.inline(async (image) => {
//                   const blob = new Blob([image.read("base64")], { type: image.contentType });
//                   const formData = new FormData();
//                   formData.append("image", blob);
//                   const url = await postImgUrl(formData);
//                   if(url){
//                     return { src : url}
//                   }else{
//                     return { src : ""}
//                   }
                  
//               }),
//           }
//       );

//       // Atualize seu estado com o HTML gerado
//       setInitialHtml(value);
//   }
// };

// const handleWordUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
//   const file = event.target.files?.[0];

//   if (file) {
//     const arrayBuffer = await file.arrayBuffer();

//     const { value } = await mammoth.convertToHtml(
//       { arrayBuffer },
//       {
//         //@ts-ignore
//         convertImage: mammoth.images.inline(async (image) => {
//           try {
//             console.log('Entrou no callback do mammoth.images.inline');

//             const base64Data = await image.read("base64");
//             console.log('Base64 data:', base64Data);

//             if (!base64Data) {
//               throw new Error("Imagem retornou dados inválidos ou nulos");
//             }

//             const byteNumbers = Uint8Array.from(Buffer.from(base64Data, "base64"));
//             console.log('Criando File a partir dos dados base64');

//             const file = new File([byteNumbers], "upload.jpg", { type: image.contentType });
//             console.log('File criado:', file);

//             const formData = new FormData();
//             formData.append("image", file);
//             console.log('FormData criado:', formData);

//             const url = await postImgUrl(formData);
//             console.log('URL gerada:', url);

//             return { src: url || "" };
//           } catch (error) {
//             console.error('Erro no callback de conversão de imagem:', error);
//             return { src: "" }; // Retorna vazio se ocorrer um erro
//           }
//         }),
//       }
//     );

//     // Atualize seu estado com o HTML gerado
//     setInitialHtml(value);
//   }
// };

// const handleWordUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
//   setLoadingUploadWord(true)
//   const file = event.target.files?.[0];

//   if (file) {
//     const arrayBuffer = await file.arrayBuffer();

//     const { value } = await mammoth.convertToHtml(
//       { arrayBuffer },
//       {
//         //@ts-ignore
//         convertImage: mammoth.images.inline(async (image) => {
//           try {

//             const base64Data = await image.read("base64");
//             console.log("Base64 data:", base64Data);

//             if (!base64Data) {
//               throw new Error("Imagem retornou dados inválidos ou nulos");
//             }

//             const byteCharacters = atob(base64Data);
//             const byteNumbers = new Uint8Array(byteCharacters.length);
//             for (let i = 0; i < byteCharacters.length; i++) {
//               byteNumbers[i] = byteCharacters.charCodeAt(i);
//             }

//             const file = new File([byteNumbers], "upload.jpg", { type: image.contentType });
//             console.log("File criado:", file);

//             const formData = new FormData();
//             formData.append("file", file);
//             console.log("FormData criado:", formData);

//             const url = await postImgUrl(formData);
//             console.log("URL gerada:", url);

//             return { src: url || "" };
//           } catch (error) {
//             console.error("Erro no callback de conversão de imagem:", error);
//             return { src: "" };
//           }
//         }),
//       }
//     );

//     setInitialHtml(value);
//     setLoadingUploadWord(false)
//   }
// };

// const handleWordUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
//   setLoadingUploadWord(true);
//   const file = event.target.files?.[0];

//   if (file) {
//     const arrayBuffer = await file.arrayBuffer();

//     const { value } = await mammoth.convertToHtml(
//       { arrayBuffer },
//       {
//         //@ts-ignore
//         convertImage: mammoth.images.inline(async (image) => {
//           try {
//             const base64Data = await image.read("base64");
//             if (!base64Data) {
//               throw new Error("Imagem retornou dados inválidos ou nulos");
//             }

//             const byteCharacters = atob(base64Data);
//             const byteNumbers = new Uint8Array(byteCharacters.length);
//             for (let i = 0; i < byteCharacters.length; i++) {
//               byteNumbers[i] = byteCharacters.charCodeAt(i);
//             }

//             const file = new File([byteNumbers], "upload.jpg", { type: image.contentType });

//             const formData = new FormData();
//             formData.append("file", file);

//             const url = await postImgUrl(formData);

//             // Retorna a URL da imagem com estilo para tamanho fixo
//             return {
//               src: url || "",
//               style: "max-width: 100%; height: auto; display: block; margin: 0 auto;", // Ajusta as imagens para caber no layout
//             };
//           } catch (error) {
//             console.error("Erro no callback de conversão de imagem:", error);
//             return { src: "" };
//           }
//         }),
//         styleMap: [
//           "p[style-name='Normal'] => p", // Mapear estilos do Word para HTML
//           "b => strong",
//           "i => em",
//         ],
//       }
//     );

//     // Adicionar estilos para simular páginas A4
//     const styledHtml = `
//       <div style="width: 794px; margin: auto; position: relative;">
//         <div style="position: absolute; top: 0; left: 0; width: 100%; height: 1122px; page-break-after: always; overflow: hidden;">
//           ${value}
//         </div>
//       </div>
//     `;

//     setInitialHtml(styledHtml);
//     setLoadingUploadWord(false);
//   }
// };

// const handleWordUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
//   setLoadingUploadWord(true);
//   const file = event.target.files?.[0];

//   if (file) {
//     const arrayBuffer = await file.arrayBuffer();

//     const { value } = await mammoth.convertToHtml(
//       { arrayBuffer },
//       {
//         //@ts-ignore
//         convertImage: mammoth.images.inline(async (image) => {
//           try {
//             const base64Data = await image.read("base64");
//             if (!base64Data) {
//               throw new Error("Imagem retornou dados inválidos ou nulos");
//             }

//             const byteCharacters = atob(base64Data);
//             const byteNumbers = new Uint8Array(byteCharacters.length);
//             for (let i = 0; i < byteCharacters.length; i++) {
//               byteNumbers[i] = byteCharacters.charCodeAt(i);
//             }

//             const file = new File([byteNumbers], "upload.jpg", { type: image.contentType });

//             const formData = new FormData();
//             formData.append("file", file);

//             const url = await postImgUrl(formData);

//             // Retorna a URL da imagem com estilo para tamanho fixo
//             return {
//               src: url || "",
//               style: "max-width: 100%; height: auto; display: block; margin: 0 auto;", // Ajusta as imagens para caber no layout
//             };
//           } catch (error) {
//             console.error("Erro no callback de conversão de imagem:", error);
//             return { src: "" };
//           }
//         }),
//         styleMap: [
//           "p[style-name='Normal'] => p",
//           "b => strong",
//           "i => em",
//         ],
//       }
//     );

//     // Adicionar estilos para simular páginas A4
//     const PAGE_HEIGHT = 1122; // Altura de uma página A4 em px
//     const PAGE_WIDTH = 794; // Largura de uma página A4 em px

//     // Divida o conteúdo em páginas simuladas
//     const content = `
//       <div style="width: ${PAGE_WIDTH}px; margin: auto;">
//         ${value
//           .split('\n')
//           .map(
//             (block, index) => `
//               <div style="height: ${PAGE_HEIGHT}px; page-break-after: always; overflow: hidden;">
//                 ${block}
//               </div>`
//           )
//           .join('')}
//       </div>
//     `;

//     setInitialHtml(content);
//     setLoadingUploadWord(false);
//   }
// };

// const handleWordUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
//   setLoadingUploadWord(true);
//   const file = event.target.files?.[0];

//   if (file) {
//     const arrayBuffer = await file.arrayBuffer();

//     const { value } = await mammoth.convertToHtml(
//       { arrayBuffer },
//       {
//         //@ts-ignore
//         convertImage: mammoth.images.inline(async (image) => {
//           try {
//             const base64Data = await image.read("base64");
//             if (!base64Data) {
//               throw new Error("Imagem retornou dados inválidos ou nulos");
//             }

//             const byteCharacters = atob(base64Data);
//             const byteNumbers = new Uint8Array(byteCharacters.length);
//             for (let i = 0; i < byteCharacters.length; i++) {
//               byteNumbers[i] = byteCharacters.charCodeAt(i);
//             }

//             const file = new File([byteNumbers], "upload.jpg", { type: image.contentType });

//             const formData = new FormData();
//             formData.append("file", file);

//             const url = await postImgUrl(formData);

//             // Retorna a URL da imagem com estilo para ajuste
//             return {
//               src: url || "",
//               style: "max-width: 100%; height: auto; display: block; margin: 0 auto;", // Ajusta as imagens proporcionalmente
//             };
//           } catch (error) {
//             console.error("Erro no callback de conversão de imagem:", error);
//             return { src: "" };
//           }
//         }),
//         styleMap: [
//           "p[style-name='Normal'] => p",
//           "b => strong",
//           "i => em",
//         ],
//       }
//     );

//     // Define o HTML convertido com ajuste de imagens
//     setInitialHtml(value);
//     setLoadingUploadWord(false);
//   }
// };

const handleWordUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
  setLoadingUploadWord(true);
  const file = event.target.files?.[0];

  if (file) {
    const fileName = file.name;
    const arrayBuffer = await file.arrayBuffer();

    const { value } = await mammoth.convertToHtml(
      { arrayBuffer },
      {
        //@ts-ignore
        convertImage: mammoth.images.inline(async (image) => {
          try {
            const base64Data = await image.read("base64");
            if (!base64Data) {
              throw new Error("Imagem retornou dados inválidos ou nulos");
            }

            const byteCharacters = atob(base64Data);
            const byteNumbers = new Uint8Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const file = new File([byteNumbers], "upload.jpg", { type: image.contentType });

            const formData = new FormData();
            formData.append("file", file);

            const url = await postImgUrl(formData);

            return {
              src: url || "",
              style: "max-width: 100%; height: auto; display: block; margin: 0 auto;", // Ajusta as imagens proporcionalmente
            };
          } catch (error) {
            console.error("Erro no callback de conversão de imagem:", error);
            return { src: "" };
          }
        }),
        styleMap: [
          "p[style-name='Normal'] => p",
          "b => strong",
          "i => em",
        ],
      }
    );

    setInitialHtml(value);
    setTextArquive(fileName);
    setLoadingUploadWord(false);
  }
};

function uploadPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return {
          upload: () => {
              return new Promise(async (resolve, reject) => {
                  try {
                      const body = new FormData();
                      const file = await loader.file;
                      body.append("file", file);

                      const url = await postImgUrl(body);

                      resolve({
                          default: url,
                      });

                      editor.model.change(writer => {
                          const existingImage = editor.model.document.getRoot().getChild(0);
                          if (existingImage && existingImage.is('element', 'imageBlock')) {
                              return;
                          }

                          const width = 795; 
                          const height = 1052;

                          const imageElement = writer.createElement('imageBlock', {
                              src: url,
                              'data-id': 'myImg',
                              width: width,
                              height: height,
                          });

                          const insertAtSelection = editor.model.document.selection.getFirstPosition();
                          editor.model.insertContent(imageElement, insertAtSelection);
                      });

                      
                  } catch (error) {
                      reject(error);
                  }
              });
          },
      };
  };
}

function uploadArquive(editor: any){
  editor.ui.componentFactory.add("customButton", (locale) => {
    const view = new editor.ui.button.ButtonView(locale);

    view.set({
        label: "Meu Botão",
        icon: "https://cdn-icons-png.flaticon.com/512/25/25076.png",
        tooltip: true,
    });

    // Define a ação ao clicar no botão
    view.on("execute", () => {
        const message = prompt("Escreva algo para adicionar no editor:");
        if (message) {
            editor.model.change((writer) => {
                const insertPosition = editor.model.document.selection.getFirstPosition();
                writer.insertText(message, insertPosition);
            });
        }
    });

    return view;
});


// editor.ui.extendMenuBar( {
//   item: 'customButton',
//   //position: 'after:menuBar:bold'
// } );

// editor.ui.componentFactory.add( 'timestamp', () => {
//   // The button will be an instance of ButtonView.
//   const button = new ButtonView();

//   button.set( {
//       label: 'Timestamp',
//       withText: true
//   } );

//   // Execute a callback function when the button is clicked.
//   button.on( 'execute', () => {
//       const now = new Date();

//       // Change the model using the model writer.
//       editor.model.change( writer => {

//           // Insert the text at the user's current position.
//           editor.model.insertContent( writer.createText( now.toString() ) );
//       } );
//   } );

//   return button;
// } );
}



//   function divPlugin(editor: any) {

//     editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
//       return {
//         upload: () => {
//           return new Promise(async (resolve, reject) => {
//             try {
//               const body = new FormData();
  
//               const file = await loader.file;
//               body.append("file", file);
  
//               const url = await postImgUrl(body);
  
//               resolve({
//                 default: url.url
//               });
//             } catch (error) {
//               reject(error);
//             }
//           });
//         }
//       };
//     };
//     // Adiciona o comando ao editor
//     // editor.commands.add('insertDiv', {
//     //     exec: function (editor) {
//     //         // Insere uma div no cursor atual
//     //         const divElement = editor.model.document.createElement('div');
//     //         editor.model.insertContent(divElement);
//     //     }
//     // });

//     // // Adiciona um botão à barra de ferramentas
//     // editor.ui.componentFactory.add('DivButton', locale => {
//     //     const button = document.createElement('button');
//     //     button.setAttribute('title', 'Inserir Div');
//     //     button.textContent = 'Div';
//     //     button.addEventListener('click', () => {
//     //         editor.execute('insertDiv');
//     //     });

//     //     return button;
//     // });
// }

  

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
  });

  // const onReady = (editor) => {
  //   // Configurar o editor como necessário
  //   //setEditorInstance(editor);
  // };


  useEffect(() => {
    const data = {
      textHTML: mySelectTemplate.textHTML ? mySelectTemplate.textHTML : "<h1>Não encontrado.. </h1>",
      description: mySelectTemplate.description ? mySelectTemplate.description : null,
      type: mySelectTemplate.type ? mySelectTemplate.type : null,
      type_template: mySelectTemplate.type_template ? mySelectTemplate.type_template : null,
      idUnitBranch: mySelectTemplate.unitBranch ? mySelectTemplate.unitBranch.id : null
    }
    formik.setValues(data)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySelectTemplate]);


  useEffect(() => {
    setVeluesReport(verificadValues)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialHtml]);

  useEffect(() => {
    getAllUnitBranchs()
  }, []);

  useEffect(() => {
    console.log('values', formik.values)
  }, [formik.values])


return (
  <div className='bg-light' style={{ display: 'flex', gap: '1rem', padding: '1rem', width: '100%', flexDirection: 'column' , height: '50rem', overflow: 'auto'}}>
    <div className='bg-body' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem'}}>
      <h3 className='align-items-start flex-column' style={{display: "flex"}}>
          <span className='card-label fw-bold fs-3 mb-1'>Dados Adicionados</span>
          <span className='text-muted fw-semibold fs-7'>Dados que serão vinculados ao relatório</span>
      </h3>
      <div className='me-0 dropdown'>
        <button
          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
          type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
          //data-kt-menu-trigger='click'
          //data-kt-menu-dismiss='false'
          //data-kt-menu-placement='bottom-end'
          //data-kt-menu-flip='top-end'
        >
          <i className='bi bi-three-dots fs-3'></i>
        </button>
        
          <DropdownMenuDates 
            valuesReport={valuesReport} 
            handleCheckBoxValues={handleCheckBoxValues}
            handleCheckBoxValuesImg={handleCheckBoxValuesImg}
            myTemplateSelected={mySelectTemplate}
          />
      </div>

    </div>
    <div style={{ flex: '1', overflowY: 'auto', height: '40rem', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        {/* <input type="file" accept=".docx" onChange={handleWordUpload} /> */}
        
        <div className='border border-gray-300 border-dashed rounded'>
          <div className="d-flex align-items-center justify-content-center" style={{width: '100%', height: '5rem', flexDirection: 'row'}}>
            <input
              type="file"
              accept=".docx"
              ref={fileInputRef}
              disabled={loadingUploadWord}
              onChange={handleWordUpload}
              style={{ display: 'none' }}
            />
            {/* <Button 
              onClick={handleButtonClick}
            >
              Escolher Arquivo
            </Button> */}

            <button
              onClick={() =>{handleButtonClick()}}
              disabled={loadingUploadWord}
              style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '0.5rem'}}
              className='btn btn-sm btn-light-primary text-hover-white'
            >
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start'}}>
                <KTSVG path='/media/icons/duotune/files/fil009.svg' className='svg-icon-2x' />
                <h3 className='card-title align-items-start flex-column'>
                  {/* <span className='card-label fw-bold fs-3 mb-1'>Adicionar Cliente (Planilha)</span> */}
                  <span className='mt-1 fw-semibold fs-7'>{textArquive}</span>
                </h3>
              </div>

              {loadingUploadWord && (
                <div style={{marginRight: '1rem'}}>
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                </div>
            )}
            </button>

          </div>
        </div>
        
        <div id="editor"></div>
        <CKEditor
          // @ts-ignore
          editor={Editor.Editor }
          data={initialHtml}
          disabled={loadingUploadWord}
          config={{
            licenseKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxIiwiYXV0aCI6eyJjb2xsYWJvcmF0aW9uIjp7IioiOnsicm9sZSI6IndyaXRlciJ9fSwiY2tib3giOnsicm9sZSI6InN1cGVyYWRtaW4ifX0sInVzZXIiOnsiaWQiOiIxIiwibmFtZSI6IlN0YXJsaW4gQnJhbXN0b20iLCJlbWFpbCI6Im9qdGlzaWZAdWdlLmJnIn0sImlzRGV2VG9rZW4iOnRydWUsInRpbWVzdGFtcCI6MTcwOTMwMTY3NTU0Mywic2lnbmF0dXJlIjoiZjk3NTBlZDA5OThiMDc4NDk1YmQ2NDA4ZjYxYzZiZmMzOWQ5NGU5Y2Q1N2ZjNzlmN2Y3OTAwZDI2YzAxNjIwZCIsImV4cCI6MTcwOTMwNTI3NSwianRpIjoiN3RIUkcyUFNfMGZ0YzZiekJQWUtrdGt4dVdvdHRIUFYiLCJhdWQiOiJ6Zk56c2xQUnM5WW4wZldkcFh1ayIsImlhdCI6MTcwOTMwMTY3NX0.r42wV0NFJOj1V_BOoZPYmlQ9329MJwciBhX6ml3zo_Q",
            // @ts-ignore
            menuBar: {
                isVisible: true
            },
            extraPlugins: [uploadPlugin, uploadArquive],
            // @ts-ignore
            htmlSupport: {
              allow: [
                {
                    name: /.*/,
                    attributes: true,
                    classes: true,
                    styles: true
                }
            ]
        },
            image: {
              toolbar: [
                  'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|',
                  'toggleImageCaption', 'imageTextAlternative', 
              ],
              resizeUnit: 'px', // Use px em vez de %
          },
            style: {
              definitions: [
                {
                  name: 'Article category',
                  element: 'h3',
                  classes: [ 'category' ]
              },
              {
                  name: 'Info box',
                  element: 'p',
                  classes: [ 'info-box' ]
              },
              {
                name: 'Image sobreposta',
                element: 'figure',
                classes: [ 'imgSobreposta' ]
              },
              {
                name: 'Image Folha',
                element: 'img',
                classes: [ 'imgFolha' ]
              },
                
              ]
            },
            fontFamily: {
              options: [
                "default",
                "Arial, Helvetica, sans-serif",
                "Courier New, Courier, monospace",
                "Georgia, serif",
                "Times New Roman, Times, serif",
                "Verdana, Geneva, sans-serif",
                "Bahnschript, sans-serif",
                "'YourCustomFont', sans-serif",
              ],
              supportAllValues: true,
            },
          }}
          onChange={ ( event, editor ) => {
            handleEditorChange(event, editor)
          }}
          
        />
      {/* <div>
        <button onClick={() => {
          
          const editor = Editor.Editor


        // Use o plugin de exportação de PDF para exportar o HTML
        editor.defaultConfig.exportPdf?.d
        editor.defaultConfig.get('ExportPdf').execute().then(data => {
            // 'data' contém o PDF exportado em formato de dados, que você pode manipular conforme necessário
        }).catch(error => {
            console.error('Erro ao exportar PDF:', error);
        });

        }}>teste</button>
      </div> */}

      {/* <CKEditor
        editor={DecoupledEditor}
        onReady={onReady}
        data="<p>Conteúdo inicial</p>"
      /> */}

      <div style={{display: 'flex', gap: '1rem'}}>
      <button
          type='button'
          className='btn btn-outline-success'
          //disabled={loading}
          //data-kt-stepper-action='submit'
          onClick={() => setGenerateTemplate(true)}
        >
          Gerar Template
          {
            loading ? 
            <LoadingSpan style={{marginLeft: '0.5rem'}}/>
            :
            <KTSVG
                path='/media/icons/duotune/arrows/arr064.svg'
                className='svg-icon-3 ms-2 me-0'
            />
          }
      </button>

      <button
          type='button'
          className='btn btn-outline-primary'
          disabled={!mySelectTemplate.id || mySelectTemplate.type === "public" ? true : false}
          //data-kt-stepper-action='submit'
          onClick={() => {
            setGenerateTemplate(false)
            submit(1)
          }}
          //onClick={putTemplate}
        >
          Atualizar Template
          {
            loading ? 
            <LoadingSpan style={{marginLeft: '0.5rem'}}/>
            :
            <KTSVG
                path='/media/icons/duotune/arrows/arr064.svg'
                className='svg-icon-3 ms-2 me-0'
            />
          }
      </button>
      </div>
    </div>
    
    {generateTemplate ? 
      loadingUnitBranchs ?
        <><h1>Carregando..</h1></>
      :
        <div>
          <div className='mb-10' style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
            <div style={{flex: '1'}}>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className='required'>Descrição</span>
                  <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Fim do período de análise do relatório'
                  ></i>
              </label>
            </div>
            <div style={{flex: '3'}}>
              <input
                  type='text'
                  className='form-control form-control-lg'
                  name='endPeriod'
                  disabled={loading}
                  placeholder=''
                  value={formik.values.description ? formik.values.description : ""}
                  onChange={formik.handleChange("description")}
              />
            </div>
          </div>

          <div style={{}}>
            <div style={{}}>
              <div className='mb-10' style={{display: 'flex', gap: '1rem'}}>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Unidade (Filial)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Selecione para qual unidade filial essa credencial será vinculada. '
                  ></i>
                </label>
                <div style={{flexGrow: '1', display: 'flex', gap: '1rem'}}>
                  <select
                    className='form-select form-select-solid bg-white'
                    data-kt-select2='true'
                    disabled={loading}
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    defaultValue={formik.values.idUnitBranch ? formik.values.idUnitBranch : '1'}
                    onChange={formik.handleChange("idUnitBranch")}
                  >
                    <option value={'1'}>Selecione uma Unidade (Filial)</option>
                    {unitBranchs && unitBranchs.map((myUnitBranch: any) => {
                      return (
                        <option key={myUnitBranch.id} value={myUnitBranch.id}>{myUnitBranch.companyName}</option>
                      )
                    })}
                    
                  </select>
                  {/* {!data.idUnitBranch && hasError && (
                  <div className='fv-plugins-message-container'>
                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                      Selecione a unidade!
                    </div>
                  </div>
                )} */}

                <select
                    className='form-select form-select-solid bg-white'
                    data-kt-select2='true'
                    disabled={loading}
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    defaultValue={formik.values.type_template ? formik.values.type_template : 'DETALHADO'}
                    onChange={formik.handleChange("type_template")}
                  >
                    <option value={'GERACAO'}>Geração</option>
                    <option value={'DETALHADO'}>Detalhado</option>
                    
                  </select>

                </div>
              </div>
            </div>
          </div>

          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <button
              type='button'
              className='btn btn-lg btn-secondary'
              disabled={loading}
              //data-kt-stepper-action='submit'
              onClick={() => {
                setGenerateTemplate(false)
              }}
              >
                Cancelar
                {
                  loading ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                  :
                  <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 ms-2 me-0'
                  />
                }
            </button>

            <button
                type='button'
                className='btn btn-lg btn-primary'
                //disabled={loading}
                //data-kt-stepper-action='submit'
                onClick={() => submit(0)}
              >
                Gerar
                {
                  loading ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                  :
                  <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                  />
                }
            </button>
          </div>

        </div> 

      : <></>
    }

    {/* <div style={{ flex: '1', height: '85%' }}>
      <div style={{marginBottom: '1rem'}}>
        <h3 className='align-items-start flex-column' style={{display: "flex"}}>
          <span className='card-label fw-bold fs-3 mb-1'>Visualização do layout</span>
          <span className='text-muted fw-semibold fs-7'>Renderização da edição do relatório</span>
        </h3>
      </div>
      
      <div style={{height: '100%', overflowY: 'auto', padding: '1rem',}} className="border border-gray-300 border-dashed rounded">
        <div dangerouslySetInnerHTML={editorStateV2} />
      </div>  
    </div> */}
  </div>
);
};

export default CKEditorV2;

function toggleToast(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}

import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
//import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as Yup from "yup";
import { useFormik } from 'formik';
import Editor from "ckeditor5-custom-build/build/ckeditor";
import './styles.css'
import { useAuth } from '../../../../../../../../modules/auth';
import { LoadingSpan } from '../../../../../../../components/Loadings/loadingSpan';
import { KTSVG } from '../../../../../../../../../_metronic/helpers';
import { getfileBase64, getfileCloundUrl, getfileUrl } from '../../../../../../../../services/uploadService';
import { useToast } from '../../../../../../../components/Toats';
import { getUnitBranchAll } from '../../../../../../../../services/unitBranchService';
import { postTemplateProposalOne, putTemplateProposalAll } from '../../../../../../../../services/templatesProposal';
import { DropdownMenuDadosProposal } from './components/DropDownCheckValues';
import { postTemplateOSOne, putTemplateOSAll } from '../../../../../../../../services/templateOS';
import mammoth from 'mammoth';


type Props = {
  initialHtml: string,
  setEditorStateV2: any,
  mySelectTemplate: any
  refresh: any;
  setInitialHtml: any;
  formikReport : any;
  setMyTemplate: any;
  myTemplates: any[]
}

const validationSchema = Yup.object().shape({
  textHTML: Yup.string(),
  description: Yup.string(),
  type: Yup.string(),
  //type_template:  Yup.string(),
  idUnitBranch: Yup.string()
});

const CKEditorTemplate = ({initialHtml, setMyTemplate, mySelectTemplate, refresh, setInitialHtml, formikReport, myTemplates} : Props) => {
  const [, setEditorData] = useState('');
  const [generateTemplate, setGenerateTemplate] = useState(false);
  const [loading, setLoading] = useState(false)
  const [loadingUnitBranchs, setLoadingUnitBranchs] = useState(false)
  const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  const user = useAuth()
  const { toggleToast } = useToast();


  const [loadingUploadWord, setLoadingUploadWord] = useState(false)
  const [textArquive, setTextArquive] = useState<string>("Escolha um arquivo (.docx).");
  const fileInputRef : any = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  //const editorRef = useRef<any>(null);

  const [initialValues] = useState({
    textHTML: initialHtml,
    description: null,
    type: "private",
    //type_template: "DETALHADO",
    idUnitBranch : null
  })

  const verificadValues = () => {
    const myValues = {
      imageCompany: initialHtml.includes("{{imageCompany}}") ? true : false,
      corporateName: initialHtml.includes("{{corporateName}}") ? true : false,
      cnpj: initialHtml.includes("{{cnpj}}") ? true : false,
      street: initialHtml.includes("{{street}}") ? true : false,
      district: initialHtml.includes("{{district}}") ? true : false,
      number: initialHtml.includes("{{number}}") ? true : false,
      telephone: initialHtml.includes("{{telephone}}") ? true : false,
      emailCompany: initialHtml.includes("{{emailCompany}}") ? true : false,
      dateCreate: initialHtml.includes("{{dateCreate}}") ? true : false,
      nameResponsible: initialHtml.includes("{{nameResponsible}}") ? true : false,
      cpfResponsible: initialHtml.includes("{{cpfResponsible}}") ? true : false,
      telephoneClient: initialHtml.includes("{{telephoneClient}}") ? true : false,
      cellphoneClient: initialHtml.includes("{{cellphoneClient}}") ? true : false,
      emailClient: initialHtml.includes("{{emailClient}}") ? true : false,
      namePlant: initialHtml.includes("{{namePlant}}") ? true : false,
      addressPlant: initialHtml.includes("{{addressPlant}}") ? true : false,
      titulo: initialHtml.includes("{{titulo}}") ? true : false,
      status: initialHtml.includes("{{status}}") ? true : false,
      description: initialHtml.includes("{{description}}") ? true : false,
      companyName: initialHtml.includes("{{companyName}}") ? true : false,
      value_sub_final: initialHtml.includes("{{value_sub_final}}") ? true : false,
      value_discont: initialHtml.includes("{{value_discont}}") ? true : false,
      value_final: initialHtml.includes("{{value_final}}") ? true : false,
      itensOS: initialHtml.includes("{{itensOS}}") ? true : false,

      nameCollaborator : initialHtml.includes("{{nameCollaborator}}") ? true : false,
      telefoneCollaborator: initialHtml.includes("{{telefoneCollaborator}}") ? true : false,
      emailCollaborator : initialHtml.includes("{{emailCollaborator}}") ? true : false,

      number_order_service: initialHtml.includes("{{number_order_service}}") ? true : false,
    }

    return myValues
  }

  const [valuesReport, setVeluesReport] = useState(verificadValues())

  


  // const [editorStateV2, setEditorStateV2] = useState(() => {
  //   if(initialHtml){
  //     return { __html: initialHtml };
  //   }
  //   return { __html: '' };
  // });


  // const handleEditorReady = (editor) => {
  //   //setEditor(editor);
  //   editor.config.define('contentFilter', {
  //     classes: true,
  //     styles: true,
  //   });
  // };

//   const getImage = async (key: string) => {
//     try {
//       const url = await getFile(key);
//       return url
//     } catch (error) {
//       console.log(error)
//       return null
//     }
//   }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    
    setInitialHtml(data);
    formik.setValues({...formik.values, textHTML: data})

    //setEditorStateV2({ __html: data });

  };

  const handleCheckBoxValues = (checked: boolean, value: string) => {
    if(checked){
      const newHTML = initialHtml + `{{${value}}} </br>`
      
      setInitialHtml(newHTML);
      formik.setValues({...formik.values, textHTML: newHTML})

    }else{
      const newHTML = initialHtml.replace(`{{${value}}}`, "");
      setInitialHtml(newHTML);
      formik.setValues({...formik.values, textHTML: newHTML})
    }
  }

  const handleCheckBoxValuesImg = async (value: string) => {
    if(
      user && user.currentUser 
      && user.currentUser.companies 
      && user.currentUser.companies.length > 0 
      && user.currentUser.companies[0].image){

      let newHTML = ""
      //const url = await getImage(user.currentUser.companies[0].image)

      const url = {url: ""}

      if(value === 'logo_100'){
        newHTML = initialHtml + `<img src="${url.url}" alt="" style="height:100px;"/> </br>`
      }

      if(value === 'logo_200'){
        newHTML = initialHtml + `<img src="${url.url}" alt="" style="height:200px;"/> </br>`
      }

      if(value === 'logo_500'){
        newHTML = initialHtml + `<img src="${url.url}" alt="" style="height:500px;"/> </br>`
      }

      if(value === 'logo_700'){
        newHTML = initialHtml + `<img src="${url.url}" alt="" style="height:700px;"/> </br>`
      }
      
      setInitialHtml(newHTML);
      formik.setValues({...formik.values, textHTML: newHTML})

    }
  }


  const getAllUnitBranchs = async () => {
    try {
      setLoadingUnitBranchs(true)
      const unitBranchs = await getUnitBranchAll()
      setUnitBranchs(unitBranchs)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      setLoadingUnitBranchs(false)
    }
  }

  const postTemplate = async () => {
    try {
      setLoading(true)
      const myData = {
        textHTML: formik.values.textHTML,
        description: formik.values.description,
        type: user.currentUser?.role.slug !== "ADMIN" ? "private" : "public",
        //type_template: formik.values.type_template,
        idUnitBranch : formik.values.idUnitBranch
      }
 
      const myTemplate = await postTemplateOSOne(myData)
      setInitialHtml(myTemplate.textHTML)

      if(myTemplate){
        let formik_templates = formikReport.values.idsTemplates;
        const result = formik_templates.map(id => myTemplates.find(template => template.id === id) || null);

        const index = result.findIndex(template => template.type_template === myTemplate.type_template);
        if(index === -1){
          formik_templates.push(myTemplate.id)
        }else{
          formik_templates[index] = myTemplate.id
        }
        formikReport.setValues({...formikReport.values, idsTemplates: formik_templates})

        //formikReport.setValues({...formik.values, idTemplate: template.id})

        setMyTemplate(myTemplate)
      }

      toggleToast("Sucesso ao cadastrar o template. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao cadastrar template. ", "error")
    }finally{
      setLoading(false)
      refresh()
      setGenerateTemplate(false)
    }
  }

  const postImgUrl = async (file) => {
    try {
      //const url = await getfileUrl(file);
      const url = await getfileCloundUrl(file)
      if(url && url.data && url.data.url){
        return url.data.url;
      }
      //return url;
    } catch (error) {
      toggleToast("Erro ao inserir a imagem. ", "error");
    }
  };

  const putTemplate = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        textHTML: formik.values.textHTML,
        description: formik.values.description,
        type: formik.values.type,
        //type_template: formik.values.type_template
      }
 
      await putTemplateOSAll(id, myData)
      toggleToast("Sucesso ao atualizar o template. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar o template. ", "error")
    }finally{
      setLoading(false)
      refresh()
    }
  }


  const submit = (type: number) => {
    if(type === 1){
      if(mySelectTemplate){
        putTemplate(mySelectTemplate.id)
      }
    }else if (type === 0){
      postTemplate()
    }
  }


  const handleWordUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingUploadWord(true);
    const file = event.target.files?.[0];
  
    if (file) {
      const fileName = file.name;
      const arrayBuffer = await file.arrayBuffer();
  
      const { value } = await mammoth.convertToHtml(
        { arrayBuffer },
        {
          //@ts-ignore
          convertImage: mammoth.images.inline(async (image) => {
            try {
              const base64Data = await image.read("base64");
              if (!base64Data) {
                throw new Error("Imagem retornou dados inválidos ou nulos");
              }
  
              const byteCharacters = atob(base64Data);
              const byteNumbers = new Uint8Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
  
              const file = new File([byteNumbers], "upload.jpg", { type: image.contentType });
  
              const formData = new FormData();
              formData.append("file", file);
  
              const url = await postImgUrl(formData);
  
              return {
                src: url || "",
                style: "max-width: 100%; height: auto; display: block; margin: 0 auto;", // Ajusta as imagens proporcionalmente
              };
            } catch (error) {
              console.error("Erro no callback de conversão de imagem:", error);
              return { src: "" };
            }
          }),
          styleMap: [
            "p[style-name='Normal'] => p",
            "b => strong",
            "i => em",
          ],
        }
      );
  
      setInitialHtml(value);
      setTextArquive(fileName);
      setLoadingUploadWord(false);
    }
  };
  
  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return {
            upload: () => {
                return new Promise(async (resolve, reject) => {
                    try {
                        const body = new FormData();
                        const file = await loader.file;
                        body.append("file", file);
  
                        const url = await postImgUrl(body);
  
                        resolve({
                            default: url,
                        });
  
                        editor.model.change(writer => {
                            const existingImage = editor.model.document.getRoot().getChild(0);
                            if (existingImage && existingImage.is('element', 'imageBlock')) {
                                return;
                            }
  
                            const imageElement = writer.createElement('imageBlock', {
                                src: url,
                                'data-id': 'myImg',
                            });
  
                            const insertAtSelection = editor.model.document.selection.getFirstPosition();
                            editor.model.insertContent(imageElement, insertAtSelection);
                        });
  
                        
                    } catch (error) {
                        reject(error);
                    }
                });
            },
        };
    };
  }


  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
  });



  useEffect(() => {
    const data = {
      textHTML: mySelectTemplate.textHTML ? mySelectTemplate.textHTML : "<h1>Não encontrado.. </h1>",
      description: mySelectTemplate.description ? mySelectTemplate.description : null,
      type: mySelectTemplate.type ? mySelectTemplate.type : null,
      type_template: mySelectTemplate.type_template ? mySelectTemplate.type_template : null,
      idUnitBranch: mySelectTemplate.unitBranch ? mySelectTemplate.unitBranch.id : null
    }
    formik.setValues(data)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySelectTemplate]);


  useEffect(() => {
    setVeluesReport(verificadValues)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialHtml]);

  useEffect(() => {
    getAllUnitBranchs()
  }, []);


return (
  <div className='bg-light' style={{ display: 'flex', gap: '1rem', padding: '1rem', width: '100%', flexDirection: 'column' , height: '50rem', overflow: 'auto'}}>
    
    <div className='bg-body' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem'}}>
      <h3 className='align-items-start flex-column' style={{display: "flex"}}>
          <span className='card-label fw-bold fs-3 mb-1'>Edição de template</span>
          <span className='text-muted fw-semibold fs-7'>Dados que serão vinculados a O.S</span>
      </h3>
      <div className='me-0 dropdown'>
        <button
          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
          type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
          //data-kt-menu-trigger='click'
          //data-kt-menu-dismiss='false'
          //data-kt-menu-placement='bottom-end'
          //data-kt-menu-flip='top-end'
        >
          <i className='bi bi-three-dots fs-3'></i>
        </button>

          <DropdownMenuDadosProposal 
            valuesProposal={valuesReport} 
            handleCheckBoxValues={handleCheckBoxValues}
            handleCheckBoxValuesImg={handleCheckBoxValuesImg}
            myTemplateSelected={mySelectTemplate}
          />
      </div>

    </div>

    <div style={{ flex: '1', overflowY: 'auto', height: '40rem', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        
        <div className='border border-gray-300 border-dashed rounded'>
          <div className="d-flex align-items-center justify-content-center" style={{width: '100%', height: '5rem', flexDirection: 'row'}}>
            <input
              type="file"
              accept=".docx"
              ref={fileInputRef}
              disabled={loadingUploadWord}
              onChange={handleWordUpload}
              style={{ display: 'none' }}
            />
            {/* <Button 
              onClick={handleButtonClick}
            >
              Escolher Arquivo
            </Button> */}

            <button
              onClick={() =>{handleButtonClick()}}
              disabled={loadingUploadWord}
              style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '0.5rem'}}
              className='btn btn-sm btn-light-primary text-hover-white'
            >
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start'}}>
                <KTSVG path='/media/icons/duotune/files/fil009.svg' className='svg-icon-2x' />
                <h3 className='card-title align-items-start flex-column'>
                  {/* <span className='card-label fw-bold fs-3 mb-1'>Adicionar Cliente (Planilha)</span> */}
                  <span className='mt-1 fw-semibold fs-7'>{textArquive}</span>
                </h3>
              </div>

              {loadingUploadWord && (
                <div style={{marginRight: '1rem'}}>
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                </div>
            )}
            </button>

          </div>
        </div>
        
        <div id="editor"></div>
        <CKEditor
          // @ts-ignore
          editor={ Editor.Editor }
          data={initialHtml}
          config={{  
            licenseKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxIiwiYXV0aCI6eyJjb2xsYWJvcmF0aW9uIjp7IioiOnsicm9sZSI6IndyaXRlciJ9fSwiY2tib3giOnsicm9sZSI6InN1cGVyYWRtaW4ifX0sInVzZXIiOnsiaWQiOiIxIiwibmFtZSI6IlN0YXJsaW4gQnJhbXN0b20iLCJlbWFpbCI6Im9qdGlzaWZAdWdlLmJnIn0sImlzRGV2VG9rZW4iOnRydWUsInRpbWVzdGFtcCI6MTcwOTMwMTY3NTU0Mywic2lnbmF0dXJlIjoiZjk3NTBlZDA5OThiMDc4NDk1YmQ2NDA4ZjYxYzZiZmMzOWQ5NGU5Y2Q1N2ZjNzlmN2Y3OTAwZDI2YzAxNjIwZCIsImV4cCI6MTcwOTMwNTI3NSwianRpIjoiN3RIUkcyUFNfMGZ0YzZiekJQWUtrdGt4dVdvdHRIUFYiLCJhdWQiOiJ6Zk56c2xQUnM5WW4wZldkcFh1ayIsImlhdCI6MTcwOTMwMTY3NX0.r42wV0NFJOj1V_BOoZPYmlQ9329MJwciBhX6ml3zo_Q",
            extraPlugins: [uploadPlugin],
            // @ts-ignore
            htmlSupport: {
              allow: [
                {
                    name: /.*/,
                    attributes: true,
                    classes: true,
                    styles: true
                }
            ]
            },
            image: { toolbar: [
              'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|',
              'toggleImageCaption', 'imageTextAlternative'
            ]},
            style: {
              definitions: [
                {
                  name: 'Article category',
                  element: 'h3',
                  classes: [ 'category' ]
              },
              {
                  name: 'Info box',
                  element: 'p',
                  classes: [ 'info-box' ]
              },
              {
                name: 'Image sobreposta',
                element: 'figure',
                classes: [ 'imgSobreposta' ]
              },
              {
                name: 'Image Folha',
                element: 'img',
                classes: [ 'imgFolha' ]
              },
                
              ]
            },
            fontFamily: {
              options: [
                "default",
                "Arial, Helvetica, sans-serif",
                "Courier New, Courier, monospace",
                "Georgia, serif",
                "Times New Roman, Times, serif",
                "Verdana, Geneva, sans-serif",
                "Bahnschript, sans-serif",
                "'YourCustomFont', sans-serif",
              ],
              supportAllValues: true,
            },
          }}
          onChange={ ( event, editor ) => {
            handleEditorChange(event, editor)
          }}
          
        />
      
      <div style={{display: 'flex', gap: '1rem'}}>
        <button
          type='button'
          className='btn btn-outline-success'
          onClick={() => setGenerateTemplate(true)}
        >
          Gerar Template
          {
            loading ? 
            <LoadingSpan style={{marginLeft: '0.5rem'}}/>
            :
            <KTSVG
                path='/media/icons/duotune/arrows/arr064.svg'
                className='svg-icon-3 ms-2 me-0'
            />
          }
        </button>

        <button
            type='button'
            className='btn btn-outline-primary'
            disabled={!mySelectTemplate.id || mySelectTemplate.type === "public" ? true : false}
            //data-kt-stepper-action='submit'
            onClick={() => {
                setGenerateTemplate(false)
                submit(1)
            }}
            //onClick={putTemplate}
            >
            Atualizar Template
            {
                loading ? 
                <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                :
                <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                />
            }
        </button>
      </div>
    </div>
    
    {generateTemplate ? 
      loadingUnitBranchs ?
        <><h1>Carregando..</h1></>
      :
        <div>
          <div className='mb-10' style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
            <div style={{flex: '1'}}>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className='required'>Descrição</span>
                  <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Fim do período de análise do relatório'
                  ></i>
              </label>
            </div>
            <div style={{flex: '3'}}>
              <input
                  type='text'
                  className='form-control form-control-lg'
                  name='endPeriod'
                  disabled={loading}
                  placeholder=''
                  value={formik.values.description ? formik.values.description : ""}
                  onChange={formik.handleChange("description")}
              />
            </div>
          </div>

          <div style={{}}>
            <div style={{}}>
              <div className='mb-10' style={{display: 'flex', gap: '1rem'}}>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Unidade (Filial)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Selecione para qual unidade filial essa credencial será vinculada. '
                  ></i>
                </label>
                <div style={{flexGrow: '1', display: 'flex', gap: '1rem'}}>
                  <select
                    className='form-select form-select-solid bg-white'
                    data-kt-select2='true'
                    disabled={loading}
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    defaultValue={formik.values.idUnitBranch ? formik.values.idUnitBranch : '1'}
                    onChange={formik.handleChange("idUnitBranch")}
                  >
                    <option value={'1'}>Selecione uma Unidade (Filial)</option>
                    {unitBranchs && unitBranchs.map((myUnitBranch: any) => {
                      return (
                        <option key={myUnitBranch.id} value={myUnitBranch.id}>{myUnitBranch.companyName}</option>
                      )
                    })}
                    
                  </select>

                    {/* <select
                    className='form-select form-select-solid bg-white'
                    data-kt-select2='true'
                    disabled={loading}
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    defaultValue={formik.values.type_template ? formik.values.type_template : 'DETALHADO'}
                    onChange={formik.handleChange("type_template")}
                  >
                    <option value={'GERACAO'}>Geração</option>
                    <option value={'DETALHADO'}>Detalhado</option>
                    
                  </select> */}

                </div>
              </div>
            </div>
          </div>

          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <button
              type='button'
              className='btn btn-lg btn-secondary'
              disabled={loading}
              onClick={() => {
                setGenerateTemplate(false)
              }}
              >
                Cancelar
                {
                  loading ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                  :
                  <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 ms-2 me-0'
                  />
                }
            </button>

            <button
                type='button'
                className='btn btn-lg btn-primary'
                onClick={() => submit(0)}
              >
                Gerar
                {
                  loading ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                  :
                  <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                  />
                }
            </button>
          </div>

        </div> 

      : <></>
    }
  </div>
);
};

export default CKEditorTemplate;

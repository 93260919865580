/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { getConcessionariesAll } from "../../../../../../services/concessionariesService";
import { getUnitConsumerAll } from "../../../../../../services/unitConsumerService";
import { Loading } from "../../../../../components/Loadings/loading";
import MyPDFViewer from "../../../../../components/pdfReader";
import DateSelector from "../../../../../reports/TableReports/components/DateInput";
import { putInvoiceExtract } from "../../../../../../services/invoices";
import { DateCompleteBarra, parseDate } from "../../../../../../utils/date";
import { useToast } from "../../../../../components/Toats";
import DateSelectorDays from "../../../../../components/DateInputDays";


const StepModal = ({hasError, formik, id}: any) => {
  const [unitConsumers, setUnitConsumers] = useState<any[]>([]);
  const [concessionaries, setConcessionaries] = useState<any[]>([]);
  //const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  // const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  // const [users, setUsers] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
  const [loadingConcessionaries, setLoadingConcessionaries] = useState<boolean>(false);
  const [largura, setLargura] = useState(window.innerWidth);
  const [base64, setBase64] = useState<string | ArrayBuffer |  null>('');
  const { toggleToast } = useToast();
  
  const getInvoiceOneExtract = async () => {
    try {
      setLoadingPdf(true)
      if(base64 !== ''){
        const body = {
          arquive: base64
        }
        const myInvoiceExtract = await putInvoiceExtract(id ? id : "", body)
        if(myInvoiceExtract){

          const myObject = {
            mes: formik.values.mes,
            data_vencimento: myInvoiceExtract.data_vencimento ? myInvoiceExtract.data_vencimento : formik.values.data_vencimento,
            energia_faturada: myInvoiceExtract.energia_faturada ? myInvoiceExtract.energia_faturada : formik.values.energia_faturada,
            energia_injetada: myInvoiceExtract.energia_injetada ? myInvoiceExtract.energia_injetada : formik.values.energia_injetada,
            energia_registrada: myInvoiceExtract.energia_registrada ? myInvoiceExtract.energia_registrada : formik.values.energia_registrada,
            energia_compensada: myInvoiceExtract.energia_compensada ? myInvoiceExtract.energia_compensada : formik.values.energia_compensada,
            leitura_atual: myInvoiceExtract.leitura_atual ? myInvoiceExtract.leitura_atual : formik.values.leitura_atual,
            leitura_anterior: myInvoiceExtract.leitura_anterior ? myInvoiceExtract.leitura_anterior : formik.values.leitura_anterior,
            tarifa: myInvoiceExtract.tarifa ? myInvoiceExtract.tarifa : formik.values.tarifa,
            tipo_fornecimento: myInvoiceExtract.tipo_fornecimento ? myInvoiceExtract.tipo_fornecimento : formik.values.tipo_fornecimento,
            valor_faturado: myInvoiceExtract.valor_faturado ? myInvoiceExtract.valor_faturado : formik.values.valor_faturado,
            link_file: myInvoiceExtract.link_file ? myInvoiceExtract.link_file : formik.values.link_file,
            base64: myInvoiceExtract.base64 ? myInvoiceExtract.base64 : formik.values.base64,
            creditos: myInvoiceExtract.creditos ? myInvoiceExtract.creditos : formik.values.creditos,
            status: formik.values.status,
            idConcessionarie: formik.values.idConcessionarie,
            idUnitConsumer: formik.values.idUnitConsumer
          }
          formik.setValues(myObject)
        }
      }

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao extrair dados do arquivo. ", "error")
      
    }finally{
      setLoadingPdf(false)
    }
  }

  // const getUsersAllUnitBranchs = async () => {
  //   try {
  //     setLoading(true)
  //     const unitBranchs = await getUnitBranchAll()
  //     setUnitBranchs(unitBranchs)

  //   } catch (error) {
  //     console.log(error)
  //     toggleToast("Erro ao buscar a lista de unidades. ", "error")

  //   }finally{
  //     setLoading(false)
  //   }
  // }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result;
        if (typeof result === 'string') {
          const base64String = result.split(',')[1];
          setBase64(base64String);
        } else {
          console.error('Error: result is not a string');
        }
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
    }
  };

  // const handleFileChange = (event: any) => {
  //   const file = event.target.files[0];
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   let base64 : string | ArrayBuffer |  null = ''
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       setBase64(reader.result);
  //       //base64 = reader.result
  //     };
  //     reader.onerror = (error) => {
  //       console.error('Error reading file:', error);
  //     };
  //   }
  // };

  const getUnitConsumers = async () => {
    try {
      setLoading(true)
      let unitConsumers = await getUnitConsumerAll()
      setUnitConsumers(unitConsumers)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades consumidoras. ", "error")

    }finally{
      setLoading(false)
    }
  }

  const getConcessionaries = async () => {
    try {
      setLoadingConcessionaries(true)
      let myConcessionaries = await getConcessionariesAll()
      setConcessionaries(myConcessionaries)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista concessionárias. ", "error")

    }finally{
      setLoadingConcessionaries(false)
    }
  }

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  const isDisabled = () => {
    const item = concessionaries.find((concessionarie) => concessionarie.id === formik.values.idConcessionarie);
    return item?.status_extract === "NAO EXTRAI";
  };

  useEffect(() => {
    const fuction_async = async () =>{
      //await getUsersAllClients()
      //await getUsersAllUnitBranchs()
      await getUnitConsumers()
      await getConcessionaries()
    }
    fuction_async()

    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };

  }, []);

  useEffect(() => {
    getInvoiceOneExtract()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [base64]);

  if (loading || loadingConcessionaries) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  // }else{
  //   if(users.length === 0){
  //     return (
  //       <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
  //         <h3 style={{display: "flex", flexDirection: "column"}}>
  //           <span className='text-muted mt-1 fw-semibold fs-7'>Há um problema no cadastro. Tente novamente mais tarde. </span>
  //         </h3>
  //       </div>
  //     )
  //   }
  // }

  return (
    <div className='current' data-kt-stepper-element='content'>
      {largura < 1200 ? (
        <div style={{width: '100%'}}>
          <div className='d-flex overflow-auto h-55px' style={{marginBottom: '1rem'}}>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <a
                  className="nav-link active text-active-primary me-6"
                  data-bs-toggle="tab"
                  href="#dates"
                >
                  Dados
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className="nav-link text-active-primary me-6"
                  data-bs-toggle="tab"
                  href="#pdf"
                >
                  PDF
                </a>
              </li>
            </ul>
          </div>

          <div className="tab-content" style={{width: '100%'}}>
            <div 
              className="tab-pane fade active show"
              id="dates"
              role="tabpanel"
            >
              <div className="border border-gray-300 border-dashed rounded" style={{display: 'flex',flexDirection: 'column', gap: '2rem', flex: '4', padding: '1rem'}}>
                <div className='fv-row'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span>Ref: Mês/Ano</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Selecione para qual unidade filial essa credencial será vinculada. '
                    ></i>
                  </label>
                  <div>
                    <DateSelector 
                      selectedDate={new Date(formik.values.mes)}
                      setSelectedDate={(date: any) => {
                        //setSelectedDate(date)
                        formik.setValues({...formik.values, mes: date})
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className='fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                      <span>Consessionária</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Selecione para qual unidade filial essa credencial será vinculada. '
                      ></i>
                    </label>
                    <div>
                      <select
                        className='form-select form-select-solid bg-light'
                        data-kt-select2='true'
                        disabled
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        defaultValue={formik.values.idConcessionarie ? formik.values.idConcessionarie : '1'}
                        onChange={formik.handleChange("idConcessionarie")}
                      >
                        <option value={'1'}></option>
                        {concessionaries && concessionaries.map((concessionaries: any) => {
                          return (
                            <option key={concessionaries.id} value={concessionaries.id}>{concessionaries.name}</option>
                          )
                        })}      
                      </select>
                    </div>
                  </div>

                  <div className='fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                      <span>Unidade Consumidora</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Selecione para qual unidade filial essa credencial será vinculada. '
                      ></i>
                    </label>
                    <div>
                      <select
                        className='form-select form-select-solid bg-light'
                        data-kt-select2='true'
                        disabled
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        defaultValue={formik.values.idUnitConsumer ? formik.values.idUnitConsumer : '1'}
                        onChange={formik.handleChange("idUnitConsumer")}
                      >
                        <option value={'1'}></option>
                        {unitConsumers && unitConsumers.map((myUnitConsumer: any) => {
                          return (
                            <option key={myUnitConsumer.id} value={myUnitConsumer.id}>{myUnitConsumer.name}</option>
                          )
                        })}      
                      </select>
                    </div>
                  </div>

                  <div style={{display: 'flex', gap: '1rem'}} className="mb-5">
                    <div className='fv-row'>
                      <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span className='required'>Leitura anterior</span>
                          <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Início do período de análise do relatório'
                          ></i>
                      </label>
                      {/* <input
                        type='text'
                        className='form-control form-control-lg'
                        name='initPeriod'
                        placeholder=''
                        value={formik.values.leitura_anterior}
                        onChange={formik.handleChange("leitura_anterior")}
                      /> */}

                      
                    <DateSelectorDays 
                      selectedDate={new Date(parseDate(formik.values.leitura_anterior))}
                      setSelectedDate={(date: any) => {
                          formik.setValues({...formik.values, leitura_anterior: date.toISOString()})
                    }}/>

                      {!formik.values.leitura_anterior && hasError && (
                        <div className='fv-plugins-message-container'>
                          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            A leitura anterior é obrigatória!
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='fv-row'>
                      <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span className='required'>Leitura Atual</span>
                          <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Início do período de análise do relatório'
                          ></i>
                      </label>
                      {/* <input
                        type='text'
                        className='form-control form-control-lg'
                        name='initPeriod'
                        placeholder=''
                        value={formik.values.leitura_atual}
                        onChange={formik.handleChange("leitura_atual")}
                      /> */}

                      <DateSelectorDays 
                        selectedDate={new Date(parseDate(formik.values.leitura_atual))}
                        setSelectedDate={(date: Date) => {
                            formik.setValues({...formik.values, leitura_atual: date.toISOString()})
                      }}/>
                      {!formik.values.leitura_atual && hasError && (
                        <div className='fv-plugins-message-container'>
                          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            A leitura atual é obrigatória!
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <div style={{display: 'flex', gap: '1rem'}} className="mb-5">
                      <div className='fv-row'>
                        <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                            <span className='required'>Energia faturada (KWh)</span>
                            <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Início do período de análise do relatório'
                            ></i>
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-lg'
                          //name='initPeriod'
                          placeholder=''
                          value={formik.values.energia_faturada}
                          onChange={formik.handleChange("energia_faturada")}
                        />
                        {!formik.values.energia_faturada && hasError && (
                        <div className='fv-plugins-message-container'>
                          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            A energia faturada é obrigatória!
                          </div>
                        </div>
                        )}
                      </div>

                      <div className='fv-row'>
                        <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                            <span className='required'>Energia injetada (KWh)</span>
                            <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Início do período de análise do relatório'
                            ></i>
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-lg'
                          name='initPeriod'
                          placeholder=''
                          value={formik.values.energia_injetada}
                          onChange={formik.handleChange("energia_injetada")}
                        />
                        {!formik.values.energia_injetada && hasError && (
                        <div className='fv-plugins-message-container'>
                          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            A energia injetada é obrigatória!
                          </div>
                        </div>
                        )}
                      </div>
                    </div>

                    <div style={{display: 'flex', gap: '1rem'}}>
                      <div className='fv-row mb-5'>
                        <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                            <span className='required'>Energia registrada (KWh)</span>
                            <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Início do período de análise do relatório'
                            ></i>
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-lg'
                          name='initPeriod'
                          placeholder=''
                          value={formik.values.energia_registrada}
                          onChange={formik.handleChange("energia_registrada")}
                        />
                        {!formik.values.energia_registrada && hasError && (
                          <div className='fv-plugins-message-container'>
                            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                              A energia registrada é obrigatória!
                            </div>
                          </div>
                          )}
                      </div>

                      <div className='fv-row'>
                        <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                            <span className='required'>Tarifa (R$)</span>
                            <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Início do período de análise do relatório'
                            ></i>
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-lg'
                          name='initPeriod'
                          placeholder=''
                          value={formik.values.tarifa}
                          onChange={formik.handleChange("tarifa")}
                        />
                        {!formik.values.tarifa && hasError && (
                          <div className='fv-plugins-message-container'>
                            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                              A tarifa é obrigatória!
                            </div>
                          </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div style={{display: 'flex', gap: '1rem'}} className="mb-5">
                    
                      {/* <div className='fv-row'>
                        <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                            <span className='required'>Valor Faturado (R$)</span>
                            <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Início do período de análise do relatório'
                            ></i>
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-lg'
                          name='initPeriod'
                          placeholder=''
                          value={formik.values.valor_faturado}
                          onChange={formik.handleChange("valor_faturado")}
                        />
                        {!formik.values.valor_faturado && hasError && (
                        <div className='fv-plugins-message-container'>
                          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            A valor faturado é obrigatória!
                          </div>
                        </div>
                        )}
                      </div> */}
                  </div>

                  <div style={{display: 'flex', gap: '1rem'}} className="mb-5">
                    <div className='fv-row'>
                      <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span className='required'>Valor Faturado (R$)</span>
                          <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Início do período de análise do relatório'
                          ></i>
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-lg'
                        name='initPeriod'
                        placeholder=''
                        value={formik.values.valor_faturado}
                        onChange={formik.handleChange("valor_faturado")}
                      />
                      {!formik.values.valor_faturado && hasError && (
                      <div className='fv-plugins-message-container'>
                        <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                          A valor faturado é obrigatória!
                        </div>
                      </div>
                      )}
                    </div>

                    <div className='fv-row mb-5'>
                      <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span className='required'>Crédito acumulado (KWh)</span>
                          <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Créditos referente a fatura atual'
                          ></i>
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-lg'
                        name='initPeriod'
                        placeholder=''
                        value={formik.values.creditos}
                        onChange={formik.handleChange("creditos")}
                      />
                      {/* {!formik.values.energia_registrada && hasError && (
                        <div className='fv-plugins-message-container'>
                          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            A energia registrada é obrigatória!
                          </div>
                        </div>
                        )} */}
                    </div>
                  </div>


                  <div className='fv-row'>
                    <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                        <span>Tipo de Fornecimento</span>
                        <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Início do período de análise do relatório'
                        ></i>
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      name='tipo_fornecimento'
                      placeholder=''
                      value={formik.values.tipo_fornecimento}
                      onChange={formik.handleChange("tipo_fornecimento")}
                    />
                  </div>

                  <div className='fv-row' style={{marginTop: '1rem', display: 'flex', gap: '1rem'}}>
                    <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                        <span>Informar Fatura</span>
                        <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Utilizar valores passados manualmente/padrão da unidade consumidora'
                        ></i>
                    </label>
                    <div className='form-check form-check-solid form-switch fv-row'>
                      <input
                        className='form-check-input w-45px h-30px'
                        type='checkbox'
                        id='allowmarketing'
                        defaultChecked={formik.values.status === 'INFORMADO' ? true : false }
                        onChange={(event) => {
                          if(event.target.checked === true){
                            formik.setValues({...formik.values, status: 'INFORMADO'})
                          }else{
                            formik.setValues({...formik.values, status: 'NÃO INFORMADO'})
                          }
                        }}
                      />
                      <label className='form-check-label'></label>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div 
              className="tab-pane fade show"
              id="pdf"
              role="tabpanel"
              style={{width: '100%'}}
            >
              <div className="border border-gray-300 border-dashed rounded" style={{flex: '5', padding: '1rem', width: '100%'}}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <input 
                        type="file" 
                        accept="application/pdf" 
                        style={{paddingBottom: '1rem'}}
                        //className="btn btn-primary"
                        disabled={loadingPdf}
                        onChange={handleFileChange} 
                      />

                      {loadingPdf && (<h3 className='card-title align-items-start flex-column'>
                        {/* <span className='card-label fw-bold fs-3 mb-1'>{id ? "Editar" : "Adicionar"} Fatura {" "}</span> */}
                        <span className='text-muted mt-1 fw-semibold fs-5'>Carregando ...</span>
                      </h3>)}

                  </div>
                  <div className="bg-ligh" style={{display: 'flex', justifyContent:"center", alignItems: 'center', height: '100%'}}>
                    {/* <div className='fw-bold fs-6 text-gray-400'>Não há PDFs de faturas disponíveis!</div> */}
                    <MyPDFViewer link_file={formik.values.link_file}/>
                  </div>
              </div>
            </div>

          </div>
        </div>
      ) :
      (
        <div className='w-100' style={{display: "flex", gap: '1rem'}}> 
          <div className="border border-gray-300 border-dashed rounded" style={{display: 'flex',flexDirection: 'column', gap: '2rem', flex: '4', padding: '1rem'}}>
              <div className='fv-row'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Ref: Mês/Ano</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Selecione para qual unidade filial essa credencial será vinculada. '
                  ></i>
                </label>
                <div>
                  <DateSelector 
                    selectedDate={new Date(formik.values.mes)}
                    setSelectedDate={(date: any) => {
                      //setSelectedDate(date)
                      formik.setValues({...formik.values, mes: date})
                    }}
                  />
                </div>
              </div>
            <div>
              <div className='fv-row mb-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Consessionária</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Selecione para qual unidade filial essa credencial será vinculada. '
                  ></i>
                </label>
                <div>
                  <select
                    className='form-select form-select-solid'
                    data-kt-select2='true'
                    disabled
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    defaultValue={formik.values.idConcessionarie ? formik.values.idConcessionarie : '1'}
                    onChange={formik.handleChange("idConcessionarie")}
                  >
                    <option value={'1'}></option>
                    {concessionaries && concessionaries.map((concessionaries: any) => {
                      return (
                        <option key={concessionaries.id} value={concessionaries.id}>{concessionaries.name}</option>
                      )
                    })}      
                  </select>
                </div>
              </div>

              <div className='fv-row mb-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Unidade Consumidora</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Selecione para qual unidade filial essa credencial será vinculada. '
                  ></i>
                </label>
                <div>
                  <select
                    className='form-select form-select-solid'
                    data-kt-select2='true'
                    disabled
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    defaultValue={formik.values.idUnitConsumer ? formik.values.idUnitConsumer : '1'}
                    onChange={formik.handleChange("idUnitConsumer")}
                  >
                    <option value={'1'}></option>
                    {unitConsumers && unitConsumers.map((myUnitConsumer: any) => {
                      return (
                        <option key={myUnitConsumer.id} value={myUnitConsumer.id}>{myUnitConsumer.name}</option>
                      )
                    })}      
                  </select>
                </div>
              </div>

              <div style={{display: 'flex', gap: '1rem'}} className="mb-5">
                <div className='fv-row' style={{flex: largura < 1200 ? '' : '1'}}>
                  <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                      <span className='required'>Leitura anterior</span>
                      <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Início do período de análise do relatório'
                      ></i>
                  </label>
                  {/* <input
                    type='text'
                    className='form-control form-control-lg'
                    name='initPeriod'
                    placeholder=''
                    value={formik.values.leitura_anterior}
                    onChange={formik.handleChange("leitura_anterior")}
                  /> */}

                    <DateSelectorDays 
                      selectedDate={new Date(parseDate(formik.values.leitura_anterior))}
                      setSelectedDate={(date: any) => {
                          formik.setValues({...formik.values, leitura_anterior: date.toISOString()})
                    }}
                  />
                  {!formik.values.leitura_anterior && hasError && (
                    <div className='fv-plugins-message-container'>
                      <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                        A leitura anterior é obrigatória!
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row' style={{flex: largura < 1200 ? '' : '1'}}>
                  <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                      <span className='required'>Leitura Atual</span>
                      <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Início do período de análise do relatório'
                      ></i>
                  </label>
                  {/* <input
                    type='text'
                    className='form-control form-control-lg'
                    name='initPeriod'
                    placeholder=''
                    value={formik.values.leitura_atual}
                    onChange={formik.handleChange("leitura_atual")}
                  /> */}

                    <DateSelectorDays 
                      selectedDate={new Date(parseDate(formik.values.leitura_atual))}
                      setSelectedDate={(date: any) => {
                          formik.setValues({...formik.values, leitura_atual: date.toISOString()})
                    }}
                  />
                  {!formik.values.leitura_atual && hasError && (
                    <div className='fv-plugins-message-container'>
                      <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                        A leitura atual é obrigatória!
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div>
                <div style={{display: 'flex', gap: '1rem'}} className="mb-5">
                  <div className='fv-row' style={{flex: largura < 1200 ? '' : '1'}}>
                    <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                        <span className='required'>Energia faturada (KWh)</span>
                        <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Início do período de análise do relatório'
                        ></i>
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      //name='initPeriod'
                      placeholder=''
                      value={formik.values.energia_faturada}
                      onChange={formik.handleChange("energia_faturada")}
                    />
                    {!formik.values.energia_faturada && hasError && (
                    <div className='fv-plugins-message-container'>
                      <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                        A energia faturada é obrigatória!
                      </div>
                    </div>
                    )}
                  </div>

                  <div className='fv-row' style={{flex: largura < 1200 ? '' : '1'}}>
                    <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                        <span >Energia injetada (KWh)</span>
                        <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Início do período de análise do relatório'
                        ></i>
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      name='initPeriod'
                      placeholder=''
                      value={formik.values.energia_injetada}
                      onChange={formik.handleChange("energia_injetada")}
                    />
                    {/* {!formik.values.energia_injetada && hasError && (
                    <div className='fv-plugins-message-container'>
                      <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                        A energia injetada é obrigatória!
                      </div>
                    </div>
                    )} */}
                  </div>
                </div>
                
                <div style={{display: 'flex', gap: '1rem'}}>
                  <div className='fv-row mb-5' style={{flex: largura < 1200 ? '' : '1'}}>
                    <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                        <span className='required'>Energia registrada (KWh)</span>
                        <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Início do período de análise do relatório'
                        ></i>
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      name='initPeriod'
                      placeholder=''
                      value={formik.values.energia_registrada}
                      onChange={formik.handleChange("energia_registrada")}
                    />
                    {!formik.values.energia_registrada && hasError && (
                      <div className='fv-plugins-message-container'>
                        <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                          A energia registrada é obrigatória!
                        </div>
                      </div>
                      )}
                  </div>

                  <div className='fv-row' style={{flex: largura < 1200 ? '' : '1'}}>
                    <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                        <span className='required'>Energia compensada (KWh)</span>
                        <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Início do período de análise do relatório'
                        ></i>
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      name='energiaCompensada'
                      placeholder=''
                      value={formik.values.energia_compensada}
                      onChange={formik.handleChange("energia_compensada")}
                    />
                    {/* {!formik.values.tarifa && hasError && (
                      <div className='fv-plugins-message-container'>
                        <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                          A tarifa é obrigatória!
                        </div>
                      </div>
                      )} */}
                  </div>
                </div>
              </div>

              <div style={{display: 'flex', gap: '1rem'}} className="mb-5">
                <div className='fv-row' style={{flex: largura < 1200 ? '' : '1'}}>
                    <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                        <span className='required'>Tarifa (R$)</span>
                        <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Início do período de análise do relatório'
                        ></i>
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      name='initPeriod'
                      placeholder=''
                      value={formik.values.tarifa}
                      onChange={formik.handleChange("tarifa")}
                    />
                    {!formik.values.tarifa && hasError && (
                      <div className='fv-plugins-message-container'>
                        <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                          A tarifa é obrigatória!
                        </div>
                      </div>
                      )}
                </div>
                <div className='fv-row' style={{flex: largura < 1200 ? '' : '1'}}>
                  <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                      <span className='required'>Valor Faturado (R$)</span>
                      <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Início do período de análise do relatório'
                      ></i>
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg'
                    name='initPeriod'
                    placeholder=''
                    value={formik.values.valor_faturado}
                    onChange={formik.handleChange("valor_faturado")}
                  />
                  {!formik.values.valor_faturado && hasError && (
                  <div className='fv-plugins-message-container'>
                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                      A valor faturado é obrigatória!
                    </div>
                  </div>
                  )}
                </div>
              </div>

              <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                      <span className='required'>Crédito acumulado (KWh)</span>
                      <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Créditos referente a fatura atual'
                      ></i>
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg'
                    name='initPeriod'
                    placeholder=''
                    value={formik.values.creditos}
                    onChange={formik.handleChange("creditos")}
                  />
                  {/* {!formik.values.energia_registrada && hasError && (
                    <div className='fv-plugins-message-container'>
                      <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                        A energia registrada é obrigatória!
                      </div>
                    </div>
                    )} */}
                </div>

              {/* <div className='fv-row'>
                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                    <span>Tipo de Fornecimento</span>
                    <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Início do período de análise do relatório'
                    ></i>
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='tipo_fornecimento'
                  placeholder=''
                  value={formik.values.tipo_fornecimento}
                  onChange={formik.handleChange("tipo_fornecimento")}
                />
              </div> */}

              <div style={{display: 'flex', gap: '1rem', justifyContent: 'space-between'}}>
                <div className='fv-row' style={{marginTop: '1rem', display: 'flex', gap: '1rem'}}>
                  <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                      <span>Informar Fatura</span>
                      <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Utilizar valores passados manualmente/padrão da unidade consumidora'
                      ></i>
                  </label>
                  <div className='form-check form-check-solid form-switch fv-row'>
                    <input
                      className='form-check-input w-45px h-30px'
                      type='checkbox'
                      id='allowmarketing'
                      defaultChecked={formik.values.status === 'INFORMADO' ? true : false }
                      onChange={(event) => {
                        if(event.target.checked === true){
                          formik.setValues({...formik.values, status: 'INFORMADO'})
                        }else{
                          formik.setValues({...formik.values, status: 'NÃO INFORMADO'})
                        }
                      }}
                    />
                    <label className='form-check-label'></label>
                  </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                  {/* <div className='form-check form-check-custom form-check-primary form-check-solid' style={{flex: '1', display: 'flex', justifyContent: 'end'}}>
                    <input
                      className={`form-check-input ${formik.values.confirm_payment === false ? 'bg-secondary' : ''}`} 
                      type='checkbox'
                      //checked={plant.imported}
                      //checked={formik.values.idPlant === plant.id ? true : false}
                      checked={true}
                      //value={plant.id}
                      onChange={(e) =>{
                        //updateData(e.target.value, e.target.checked)
                        //console.log(e.target.checked)
                      }}
                      //onChange={formik.handleChange("idsPlants")}
                    />
                  </div> */}
                  <label className='d-flex align-items-center fs-6 fw-semibold'>
                      <span>Confirmar <br /> Pagamento</span>
                      <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='confirmar se o cliente pagou a fatura.'
                      ></i>
                  </label>
                  <div className='d-flex align-items-center mt-3' style={{flexDirection: 'column', gap: '1rem'}}>
                    <label className='form-check form-check-inline form-check-success form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        name='communication[]'
                        type='checkbox'
                        //defaultChecked={true}
                        checked={formik.values.confirm_payment === true ? true : false}
                        onChange={() => {
                          formik.setValues({...formik.values, confirm_payment : true })
                        }}
                      />
                      <span className='fw-bold ps-2 fs-6'>Sim</span>
                    </label>

                    <label className='form-check form-check-inline form-check-danger form-check-solid'>
                      <input
                        className='form-check-input'
                        name='communication[]'
                        type='checkbox'
                        //defaultChecked={true}
                        checked={formik.values.confirm_payment === false ? true : false}
                        onChange={() => {
                          formik.setValues({...formik.values, confirm_payment : false })
                        }}
                      />
                      <span className='fw-bold ps-2 fs-6'>Não</span>
                    </label>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="border border-gray-300 border-dashed rounded" style={{flex: '5', padding: '1rem'}}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <input 
                    type="file" 
                    accept="application/pdf" 
                    style={{paddingBottom: '1rem'}}
                    //className="btn btn-primary"
                    disabled={loadingPdf || isDisabled()}
                    onChange={handleFileChange} 
                  />

                  {loadingPdf && (<h3 className='card-title align-items-start flex-column'>
                    {/* <span className='card-label fw-bold fs-3 mb-1'>{id ? "Editar" : "Adicionar"} Fatura {" "}</span> */}
                    <span className='text-muted mt-1 fw-semibold fs-5'>Carregando ...</span>
                  </h3>)}

              </div>
              <div className="bg-light" style={{ display: 'flex', justifyContent:"center", alignItems: 'center', height: '100%'}}>
                {/* <div className='fw-bold fs-6 text-gray-400'>Não há PDFs de faturas disponíveis!</div> */}
                <MyPDFViewer link_file={formik.values.link_file}/>
              </div>
          </div>

          {/*end::Form Group */}

          {/*begin::Form Group */}
          {/*end::Form Group */}
        </div>
      )}
      

    </div>
  )
}

export {StepModal}



/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";

//import * as Yup from "yup";
import { getConcessionariesAll } from "../../../../../../services/concessionariesService";
import { getplantsAll } from "../../../../../../services/plantsService";
//import { getUnitBranchAll } from "../../../../../../services/unitBranchService";
//import { getUsersAllRole } from "../../../../../../services/usersService";
import { Loading } from "../../../../../components/Loadings/loading";
import { ListPlantsLinked } from "../listPlantsLinked";
import { StepV2 } from "./components/stepV2";
import { useAuth } from "../../../../../../modules/auth";
import { getInvoicesOneFilter } from "../../../../../../services/invoices";
import { KTSVG } from "../../../../../../../_metronic/helpers";


const StepModal = ({hasError, formik, id}: any) => {
  const [plants, setPlants] = useState<any[]>([]);
  const [concessionaries, setConcessionaries] = useState<any[]>([]);
  const [concessionarie, setConcessionarie] = useState<any>(null);
  const [invoiceFilter, setInvoiceFilter] = useState<any>(null);

  const user = useAuth()
  // const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  // const [users, setUsers] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingConcessionaries, setLoadingConcessionaries] = useState<boolean>(false);
  const [loadingInvoiceFilter, setLoadingInvoiceFilter] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  // const getUsersAllClients = async () => {
  //   try {
  //     setLoading(true)
  //     const users = await getUsersAllRole("CLIENTE")
  //     setUsers(users)

  //   } catch (error) {
  //     console.log(error)
  //     toggleToast("Erro ao buscar a lista de usuarios. ", "error")
      
  //   }finally{
  //     setLoading(false)
  //   }
  // }

  const getconcessionaries = async () => {
    try {
      setLoadingConcessionaries(true)
      const concessionaries = await getConcessionariesAll()
      setConcessionaries(concessionaries)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar as concessionárias. ", "error")

    }finally{
      setLoadingConcessionaries(false)
    }
  }

  const getInvoiceOneFilter = async () => {
    try {
      setLoadingInvoiceFilter(true)
      const invoice_filter = await getInvoicesOneFilter(id, new Date())
      setInvoiceFilter(invoice_filter)

    } catch (error) {
      console.log(error)
    }finally{
      setLoadingInvoiceFilter(false)
    }
  }

  const getPlants = async () => {
    try {
      setLoading(true)
      let plants = await getplantsAll(`?unitBranch=${formik.values.idUnitBranch ? formik.values.idUnitBranch : ""}`)
      plants = plants.map((plant: any) => {
        const exist = formik.values.idPlant === plant.id ? true : false
        plant.imported = exist ? true : false
        return plant
      })
      plants = plants.sort((a: any, b: any) => (b.imported ? 1 : -1))
      setPlants(plants)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      setLoading(false)
    }
  }

    useEffect(() => {
    if(formik.values.idConcessionarie){
      const concessionar = concessionaries.find((csss: any) => csss.id === formik.values.idConcessionarie)
      setConcessionarie(
        concessionar
      )
    }else{
      setConcessionarie(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.idConcessionarie])


  useEffect(() => {
    getPlants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.idUnitBranch]);

  useEffect(() => {
    const fuction_async = async () =>{
      //await getUsersAllClients()
      //await getUsersAllUnitBranchs()
      await getPlants()
      await getconcessionaries()
      await getInvoiceOneFilter()
    }
    fuction_async()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || loadingConcessionaries) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  // }else{
  //   if(users.length === 0){
  //     return (
  //       <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
  //         <h3 style={{display: "flex", flexDirection: "column"}}>
  //           <span className='text-muted mt-1 fw-semibold fs-7'>Há um problema no cadastro. Tente novamente mais tarde. </span>
  //         </h3>
  //       </div>
  //     )
  //   }
  // }

  // eslint-disable-next-line react-hooks/rules-of-hooks


  return (
    <div className='current' data-kt-stepper-element='content' >
      <div className='w-100' style={{display: 'flex', gap: '1rem', flexWrap: 'wrap'}}>
        {/*begin::Form Group */}
        
        <div className="border border-gray-300 border-dashed rounded" style={{padding: '1rem', flex: '1'}}>
          <div className='fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Consessionária</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selecione para qual unidade filial essa credencial será vinculada. '
              ></i>
            </label>
            <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={formik.values.idConcessionarie ? formik.values.idConcessionarie : '1'}
                onChange={formik.handleChange("idConcessionarie")}
              >
                <option value={'1'}></option>
                {concessionaries && concessionaries.map((concessionaries: any) => {
                  return (
                    <option key={concessionaries.id} value={concessionaries.id}>{concessionaries.name}</option>
                  )
                })}      
              </select>
              <span 
                className={
                  `badge badge-light-${
                      concessionarie && concessionarie.status_mon_aut !== null
                      ? concessionarie.status_mon_aut === "MONITORADA"
                      ? "success"
                      : concessionarie.status_mon_aut === "NÃO MONITORADA"
                      ? "danger"
                      : "secondary"
                    : "secondary"
                  } fs-7 fw-semibold`
                }
              >
                  {
                    concessionarie && concessionarie.status_mon_aut !== null ? concessionarie.status_mon_aut : "Não identificado"
                  }
              </span>
            </div>
              {!formik.values.idConcessionarie && hasError && (
                <div className='fv-plugins-message-container'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    Informe uma concessionária para a unidade!
                  </div>
                </div>
              )}

          </div>
          
          <div style={{display: 'flex', gap: '1rem'}} className="mb-5">
            <div className='fv-row' style={{flex: '1'}}>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>Nome</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Digite um nome para a unidade(consumidoras)'
                ></i>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='name'
                placeholder=''
                value={formik.values.name}
                onChange={formik.handleChange("name")}
              />
              {!formik.values.name && hasError && (
                <div className='fv-plugins-message-container'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    Informe um nome para a unidade consumidora
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row' style={{flex: '1'}}>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>Nº do contrato</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Digite um número de contrato da unidade(consumidora)'
                ></i>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='contrato'
                placeholder=''
                value={formik.values.contrato}
                onChange={formik.handleChange("contrato")}
                
              />
              {!formik.values.contrato && hasError && (
                <div className='fv-plugins-message-container'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    O número do contrato é obrigatório!
                  </div>
                </div>
              )}
            </div>
          </div>

          {plants && plants.length > 0 && plants[0].plano === 'PREMIUM' && 
            (<div>
              <h3 className='align-items-start flex-column mb-10 mt-10' style={{display: "flex", marginBottom: '2rem'}}>
                <span className='card-label fw-bold fs-4 mb-1'>
                  Credenciais da Concessionária 
                  <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Para ativar o monitoramento de faturas da unidade consumidora, é necessário informar as credenciais da plataforma da concessionária'
                  ></i>
                </span> 
                <span className='text-muted fw-semibold fs-6'>Credenciais referentes ao login na plataforma da concessionária</span>
              </h3>

              <div style={{display: 'flex', gap: '1rem'}} className="mb-5">
                <div className='fv-row'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span>Usuário</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Pode ser o nome de usuario, CNPJ (XX.XXX.XXX/0001-XX), CPF(000.000.00-00) e etc..'
                    ></i>
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='name'
                    disabled={concessionarie && concessionarie.status_mon_aut !== "NÃO MONITORADA" ? false : true}
                    placeholder=''
                    value={formik.values.username}
                    onChange={formik.handleChange("username")}
                  />
                  {/* {!formik.values.companyName && hasError && (
                    <div className='fv-plugins-message-container'>
                      <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                        Informe um nome para a unidade consumidora
                      </div>
                    </div>
                  )} */}
                </div>

                <div className='fv-row'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span>Senha</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Pode ser a senha, data de nascimento(00-00-0000), e etc'
                    ></i>
                  </label>
                  <div style={{display: 'flex', gap: '0.2rem'}}>
                    <input
                      type={showPassword ? "text" : "password"}
                      className='form-control form-control-lg form-control-solid'
                      name='name'
                      disabled={concessionarie && concessionarie.status_mon_aut !== "NÃO MONITORADA" ? false : true}
                      placeholder=''
                      value={formik.values.password}
                      onChange={formik.handleChange("password")}
                    />
                    <a
                      onClick={togglePasswordVisibility}
                      style={{height: 'auto'}}
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                        {/* <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' /> */}
                        <i className={`bi bi-eye-fill`} style={{fontSize: '1.2rem'}}></i>
                    </a>
                  </div>
                  {/* {!formik.values.companyName && hasError && (
                    <div className='fv-plugins-message-container'>
                      <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                        Informe um nome para a unidade consumidora
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            </div>)}


            <h3 className='align-items-start flex-column mb-5 mt-10' style={{display: "flex", marginBottom: '2rem'}}>
                <span className='card-label fw-bold fs-4 mb-1'>
                  Créditos 
                  <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Para ativar o monitoramento de faturas da unidade consumidora, é necessário informar as credenciais da plataforma da concessionária'
                  ></i>
                </span> 
                <span className='text-muted fw-semibold fs-6'>Créditos acumulados da unidade consumidora</span>
            </h3>

            <h3 className='align-items-start flex-column mb-10' style={{display: "flex", marginBottom: '2rem'}}>
                <div className="border border-gray-300 border-dashed rounded" style={{width: '100%', padding: '1rem'}}>
                  <span className='text-muted fw-semibold fs-2'>~{invoiceFilter && invoiceFilter.creditos ? invoiceFilter.creditos : "0"} KWh</span>
                </div>
            </h3>


        </div>

        <div style={{flex: '1'}}>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <a
                className="nav-link active text-active-primary border-active-primary border-hover-primary me-6"
                data-bs-toggle="tab"
                href="#details"
              >
                Usina Vinculada
              </a>
            </li>
            <li className='nav-item'>
              <a
                className="nav-link text-active-primary border-active-primary border-hover-primary me-6"
                data-bs-toggle="tab"
                href="#relators"
              >
                Aut. das Faturas
              </a>
            </li>

            {/* <li className='nav-item'>
              <a
                className="nav-link text-active-primary border-active-primary border-hover-primary me-6"
                data-bs-toggle="tab"
                href="#iframe"
              >
                Iframe
              </a>
            </li> */}
          </ul>

          <div className="tab-content" id="myTabContent5">
            <div
              className="tab-pane fade active show"
              id="details"
              role="tabpanel"
            >
              <ListPlantsLinked 
                plants={plants} 
                className="card-xl-stretch bg-body mb-xl-8" 
                formik={formik}/>
            </div>

            <div 
              className="tab-pane fade show divTableReport"
              id="relators"
              role="tabpanel"
            >
              <StepV2 hasError={hasError} formik={formik}/>
            </div>

            {/* <div 
              className="tab-pane fade show divTableReport"
              id="iframe"
              role="tabpanel"
            >
              <MyIframeComponent />
            </div> */}
          </div>

          
        </div>

      </div>

        {/*end::Form Group */}

        {/*begin::Form Group */}
        {/*end::Form Group */}
    </div>
  )
}

export {StepModal}
function toggleToast(arg0: string, arg1: string) {
  throw new Error("Function not implemented.");
}


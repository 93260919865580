/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { KTSVG } from '../../../../../../../../_metronic/helpers';
import { formatarData } from '../../../../../../../utils/date';
import { useAuth } from '../../../../../../../modules/auth';
//import { DateComplete } from '../../../../../utils/date';

type Props = {
    reportServices: any[];
    setMyReportService: any;
    setShowModalStatus: any;
    setShowCreateAppModalDelete: any
    setShowModalDetails: any
    setShowModalCreateOS: any
}

export function TableBody({
    reportServices, 
    setMyReportService,
    setShowModalStatus,
    setShowCreateAppModalDelete, 
    setShowModalDetails,
    setShowModalCreateOS
}: Props) {

    const {currentUser} = useAuth()

    const handleButtonClickDelete = (event: any, reportService: any) => {
        event.stopPropagation();
        reportService.modalStatus = "CANCELADO"
        setMyReportService(reportService)
        setShowModalStatus(true)
    };

    const handleButtonClickUpdate = (event: any, reportService: any) => {
        event.stopPropagation();
        reportService.modalStatus = "ATENDIDO"
        setMyReportService(reportService)
        setShowModalStatus(true)
    };

  return (
    <tbody>
        {reportServices && reportServices.length !== 0 ?   
            reportServices.map((reportService: any) => {
                return (
                    <tr 
                        onClick={() =>{
                            setMyReportService(reportService)
                            setShowModalDetails(true)
                        }}
                    >
                        {/* <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                            </div>
                        </td> */}

                        <td>
                            <div className='d-flex align-items-center'>
                                <span
                                  className={`bullet bullet-vertical h-50px bg-${
                                    reportService.status !== null
                                    ? reportService.status === "PENDENTE"
                                    ? "info"
                                    : reportService.status === "CANCELADO"
                                    ? "danger"
                                    : reportService.status === "ATENDIDO"
                                    ? "success"
                                    : "secondary"
                                  : "secondary"
                                } spanMarcador`}
                                >
                                </span>
                                {/* <div className='symbol symbol-45px me-5'>
                                    <KTSVG path="/media/icons/duotune/general/gen016.svg" className="svg-icon-muted svg-icon-3hx" />
                                </div> */}
                                <div className='d-flex justify-content-start flex-column'>
                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                        {reportService.typeService && reportService.typeService.description ? reportService.typeService.description : "Não encontrado. "}
                                    </a>
                                    {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        Descrição da solicitação
                                    </span> */}
                                </div>
                            </div>
                        </td>

                        {currentUser && currentUser.role && currentUser.role.slug !== 'CLIENTE' && 
                        <td>
                            <div className='d-flex align-items-center'>
                            {/* <div className='symbol symbol-45px me-5'>
                                <KTSVG path="/media/icons/duotune/general/gen016.svg" className="svg-icon-muted svg-icon-3hx" />
                            </div> */}
                            <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                    {reportService.created_at? formatarData(reportService.created_at) : "Não encontrado. "}
                                </a>
                                {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Descrição da solicitação
                                </span> */}
                            </div>
                            </div>
                        </td>}

                        <td>
                            <span 
                              className={
                                `badge badge-light-${
                                    reportService.status !== null
                                    ? reportService.status === "PENDENTE"
                                    ? "info"
                                    : reportService.status === "CANCELADO"
                                    ? "danger"
                                    : reportService.status === "ATENDIDO"
                                    ? "success"
                                    : "secondary"
                                  : "secondary"
                                } fs-7 fw-semibold`
                              }
                            >
                                {
                                 reportService.status ? reportService.status : "Não identificado"
                                }
                            </span>
                        </td>

                        {currentUser && currentUser.role && currentUser.role.slug !== 'CLIENTE' && 
                        <td>
                            <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                    {reportService.client && reportService.client.nameResponsible ? reportService.client.nameResponsible : "Não encontrado. "}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {reportService.client && reportService.client.email ? reportService.client.email : "Não encontrado. "}
                                </span>
                            </div>
                            </div>
                        </td>}

                        <td>
                            {
                                reportService.status === "ATENDIDO"
                                ? (
                                    <div style={{display: 'flex', justifyContent: 'end'}}>
                                        <KTSVG 
                                            path="media/icons/duotune/arrows/arr016.svg" 
                                            className="svg-icon-success svg-icon-2hx" 
                                        />
                                    </div>
                                )
                                :
                                reportService.status === "CANCELADO"
                                ? (
                                    <div style={{display: 'flex', justifyContent: 'end'}}>
                                        <KTSVG 
                                            path="media/icons/duotune/arrows/arr015.svg" 
                                            className="svg-icon-danger svg-icon-2hx" 
                                        />
                                    </div>
                                )
                                :
                                (
                                    <div>
                                        {currentUser && currentUser.role && currentUser.role.slug !== 'CLIENTE' &&
                                        
                                        <div className='d-flex justify-content-end flex-shrink-0 gap-2'>
                                            <a
                                                onClick={(event: any) =>{
                                                    handleButtonClickUpdate(event, reportService)
                                                }}
                                                href='#'
                                                className='btn btn-icon btn-bg-success btn-active-color-primary btn-sm me-1'
                                            >
                                                <KTSVG path='/media/icons/duotune/arrows/arr085.svg' className='svg-icon-3 svg-icon-light' />
                                            </a>
                                            <a
                                                onClick={(event: any) => {
                                                    //handleButtonClickDelete(event, typeService)
                                                    handleButtonClickDelete(event, reportService)
                                                }}
                                                href='#'
                                                className='btn btn-icon btn-bg-danger btn-active-color-primary btn-sm'
                                            >
                                                <KTSVG
                                                path='/media/icons/duotune/arrows/arr097.svg'
                                                className='svg-icon-3 svg-icon-light'
                                                />
                                            </a>

                                            <a
                                                onClick={(event: any) => {
                                                    event.stopPropagation();
                                                    setMyReportService(reportService)
                                                    setShowModalCreateOS(true)
                                                    //handleButtonClickDelete(event, typeService)
                                                }}
                                                href='#'
                                                className='btn btn-bg-primary btn-active-color-primary btn-sm'
                                            >
                                                <span className='text-light fw-semibold d-block fs-7'>
                                                    Gerar OS
                                                </span>
                                            </a>
                                        </div>}
                                    </div>
                                )
                            } 
                        </td>
                    </tr>
                )
            })
        :
        <tr>
            <td colSpan={6} style={{textAlign: 'center'}}>
                <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                    <span className='text-muted fw-semibold text-muted d-block fs-4'>
                        Solicitações de serviços não encontradas!
                    </span>
                </div>
            </td>
        </tr>
        }
    </tbody>
  );
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
//import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import 'mapbox-gl/dist/mapbox-gl.css'
//import { StandaloneSearchBox } from '@react-google-maps/api';
import { Plants } from '../../../@types/plants';
import { getplantsAll } from '../../../services/plantsService';
import Map, {FullscreenControl, GeolocateControl, Marker, NavigationControl} from "react-map-gl"
import { getAlarmStatus } from '../../../services/alarm';
import {    toAbsoluteUrl } from '../../../../_metronic/helpers';
import { ModalPlant } from '../../components/modalPlant';
import './styles.css';
import useSize from '../../../hooks/useSize';

type Props = {
    plant: any
    formik: any
}


export function MapsV2Plant({plant, formik}: Props) {
    //const TOKEN = process.env.TOKEN_MAPBOX
    //const STYLE_MAP = process.env.MAPSTYLE

    //const [TOKEN] = useState(process.env.TOKEN_MAPBOX);
    //const [STYLE_MAP] = useState(process.env.MAPSTYLE);

    const [plants, setPlants] = useState<Plants[]>([plant]);
    const mapRef = useRef<any>(null);
    const [largura, setLargura] = useState(window.innerWidth);
    const {width, height} = useSize()
    const [viewport, setViewport] = useState({
        latitude: plant.plantLat && plant.plantLat !== null && plant.plantLat !== undefined
        ? (Number.isNaN(Number(corrigirCoordenada(plant.plantLat.toString()))) ? 0 : Number(corrigirCoordenada(plant.plantLat.toString())))
        : 0,
        longitude: plant.plantLon && plant.plantLon !== null && plant.plantLon !== undefined
        ? (Number.isNaN(Number(corrigirCoordenada(plant.plantLon.toString()))) ? 0 : Number(corrigirCoordenada(plant.plantLon.toString())))
        : 0,
        zoom: width < 1200 ? 2 : 10,
    });


    function corrigirCoordenada(coordenadaString: string) {
        if (coordenadaString.includes(',')) {
            return coordenadaString.replace(',', '.');
        } else {
            return coordenadaString;
        }
    }

    const atualizarTamanhoDaTela = () => {
        setLargura(window.innerWidth);
    };

    // useEffect(() => {
    //     if(page === 3){
    //         if(mapRef.current){
    //             mapRef.current.resize();
    //         }
    //     }
    // }, [page]);

     useEffect(() => {
        if(formik.values.plantLat && formik.values.plantLon){
            plant.plantLat = formik.values.plantLat
            plant.plantLon = formik.values.plantLon

            setViewport({
                latitude: formik.values.plantLat
                ? (Number.isNaN(Number(corrigirCoordenada(formik.values.plantLat.toString()))) ? -6.771433 : Number(corrigirCoordenada(formik.values.plantLat.toString())))
                : -6.771433,
                longitude: formik.values.plantLon
                ? (Number.isNaN(Number(corrigirCoordenada(formik.values.plantLon.toString()))) ? -43.024926 : Number(corrigirCoordenada(formik.values.plantLon.toString())))
                : -43.024926,
                zoom: width < 1200 ? 2 : 10,
            })
        }
    }, [formik.values.plantLat, formik.values.plantLon]);

    useEffect(() => {
        window.addEventListener('resize', atualizarTamanhoDaTela);
        return () => {
          window.removeEventListener('resize', atualizarTamanhoDaTela);
        };

     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);

    if (!plants) {
        return <h1>Carrengando...</h1>;
    }

  return (
    <div
        className='row g-xl-8 py-5 px-5 divGlobalMap' 
        style={{
            borderRadius: '10px',
            //marginTop: '1rem',
        }}
    >
        {/* <div 
            style={{
                display: 'flex',
                flexDirection: largura > 1200 ? 'row': 'column' , 
                justifyContent: largura > 1200 ? 'space-between' : '',
                gap: '1rem'
            }}
        >
            <h3 
                style={{display: 'flex'}} 
                className='card-title align-items-start flex-column'
            >
                <span className={`card-label fw-bold fs-${width < 1200 ? '5' : '3'} mb-1`}>Mapa das Usinas</span>
                <span className={`text-muted fw-semibold fs-7`}>Aproximadamente {plants.length} usinas localizadas.</span>
            </h3>          
        </div> */}

        <div 
            className='mt-5 mb-5 mb-xl-8 border border-gray-300 border-dashed rounded p-1' 
            style={{
                height: width < 1200 ?
                height <= 750 ? '27vh' 
              : height > 750 && height <= 850 ? '35vh'
              : height > 850 && height <= 930 ? '40vh'
              : `42vh`
              : '45rem', 
                width: "100%"
            }}
        >
            {<Map
                {...viewport}
                ref={mapRef}
                mapLib={import('mapbox-gl')}
                onMove={evt => setViewport(evt.viewState)}
                //mapboxAccessToken={"pk.eyJ1IjoiZGF2aWFsdmVzIiwiYSI6ImNsbHI3NzFnYzBmeW4zZHMyemZpbjdvdGoifQ.TZix69tIsNb8ITolAhPrAw"}
                mapboxAccessToken={"pk.eyJ1IjoibGVra293aWxsaWFtdG9ycmVzIiwiYSI6ImNsdThnazlyZDBmcXEya29qMmZndGtmMmkifQ.kwHp_K24hVrkVHWqTE9yEQ"}
                //mapboxAccessToken={process.env.TOKEN_MAPBOX}
                style={{width: "100%"}}
                //mapStyle={"mapbox://styles/davialves/cllr7q8t700og01ph36tjap3c"}
                mapStyle={"mapbox://styles/lekkowilliamtorres/clu8gy4e8000e01qt6p0p28hi"}
                //mapStyle={process.env.MAPSTYLE}
            >    
                {
                    plants.map((plant: Plants) =>{
                        
                        // const myPosition = {
                        //     lat: plant.plantLat && plant.plantLat !== null && plant.plantLat !== undefined ? Number(corrigirCoordenada(plant.plantLat.toString())) : -6.771433,
                        //     lng: plant.plantLon && plant.plantLon !== null && plant.plantLon !== undefined ? Number(corrigirCoordenada(plant.plantLon.toString())) : -43.024926
                        // }

                        const myPosition = {
                            lat: plant.plantLat && plant.plantLat !== null && plant.plantLat !== undefined
                                ? (Number.isNaN(Number(corrigirCoordenada(plant.plantLat.toString()))) ? 0 : Number(corrigirCoordenada(plant.plantLat.toString())))
                                : null,
                            lng: plant.plantLon && plant.plantLon !== null && plant.plantLon !== undefined
                                ? (Number.isNaN(Number(corrigirCoordenada(plant.plantLon.toString()))) ? 0 : Number(corrigirCoordenada(plant.plantLon.toString())))
                                : null
                        };

                        if(myPosition.lat && myPosition.lng){
                            return (
                                <Marker
                                    key={plant.plantId} 
                                    latitude={myPosition.lat} 
                                    longitude={myPosition.lng} 
                                    draggable
                                >
                                    <i 
                                        title={`${plant.name}`}
                                        onClick={() => {
                                            //setMyPlant(plant.id)
                                            //setShowCreateAppModal(true)
                                        }} 
                                    >
                                        <div className='symbol symbol-70px me-5'>
                                            <img
                                                src={toAbsoluteUrl(`/media/imgs/plant-${
                                                    plant.status !== null
                                                    ? plant.status === 0
                                                    ? "sucess"
                                                    : plant.status === 1
                                                    ? "danger"
                                                    : plant.status === 2
                                                    ? "warning"
                                                    : plant.status === 7 || plant.status === 3
                                                    ? "info"
                                                    : "indentify"
                                                    : "indentify"
                                                }.png`)}
                                                className=''
                                                alt=''
                                            />
                                        </div>
                                        
                                    </i>
    
                                </Marker>
                            )
                        }

                    })
                }
                <NavigationControl />
                <GeolocateControl />
                <FullscreenControl />    
            </Map>}
            
            {/* <ModalPlant show={showCreateAppModal} handleClose={() => closedModal(false)} plant={myPlant}/> */}
        </div>
    </div>
  );
}
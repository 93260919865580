//import { Plants } from "../@types/plants";
import api from "./api";

export const getplantsAll = async (url?: string) => {
    if(url){
        const response = await api.get(`plants/listAll/${url}`);
        return response.data;
    }else{
        const response = await api.get(`plants/listAll`);
        return response.data;
    }
};

export const getplantsAllPagination = async (url?: string) => {
    if(url){
        const response = await api.get(`plants/listAllPagination/${url}`);
        return response.data;
    }else{
        const response = await api.get(`plants/listAllPagination`);
        return response.data;
    }
};

export const getplantsOne = async (id: string) => {
    const response = await api.get(`plants/listOneV2/${id}`);
    return response.data;
};

export const getClimaPlantOne = async (id: string) => {
    const response = await api.get(`plants/datesClimaPlant/${id}`);
    return response.data;
};

export const getPlantsImported = async (idCredential: string) => {
    const response = await api.get(`plants/listPlantsForImported/${idCredential}`);
    return response.data;
};

export const postLogin = async (body: any) => {
    const response = await api.post(`integrationAPI/`, body);
    return response.data.result;
};

export const putPlantOne = async (id: string, body: any) => {
    const response = await api.put(`plants/${id}`, body);
    return response.data.result;
};

export const putPrognosisOne = async (id: string, body: any) => {
    const response = await api.put(`prognosis/${id}`, body);
    return response.data.result;
};

export const deletePlantOne = async (id: string) => {
    const response = await api.delete(`plants/${id}`);
    return response.data.result;
};


export const postPrognosisOne = async (body: any) => {
    const response = await api.post(`prognosis/`, body);
    return response.data.result;
};
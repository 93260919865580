/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { KTSVG } from '../../../../../../../_metronic/helpers';
import { useToast } from '../../../../../components/Toats';
import { StepperComponent } from '../../../../../../../_metronic/assets/ts/components';
import { LoadingSpan } from '../../../../../components/Loadings/loadingSpan';
import { deleteTemplateAll } from '../../../../../../services/templates';


type Props = {
    id: string;
    data: any;
    show: boolean;
    handleClose: () => void
    setMyTemplate: any
    setInitialHtml:any
    myTemplate: any
}


const modalsRoot = document.getElementById('root-modals') || document.body

const ModalDeleteTemplate = ({show, handleClose, id, data, myTemplate, setMyTemplate, setInitialHtml}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)

  
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    handleClose()
  }

  const deleteTemplateSubmit = async () => {
    try {
      setLoading(true)
    
      await deleteTemplateAll(id)
      toggleToast("Sucesso ao deletar o template. ", "success")

      if(myTemplate && myTemplate.id && myTemplate.id === id){
        setMyTemplate({
          description: null,
          id: null,
          textHTML: "<div>Crie o seu template ou escolha um template disponivel. </div>",
          type: "private"
        })
        setInitialHtml("<div>Crie o seu template ou escolha um template disponivel. </div>")
      }

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao deletar o template. ", "error")
    }finally{
      setLoading(false)
      closed();

    }
  }

  const submit = () => {
    deleteTemplateSubmit()
  }


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-500px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Deletar Template {" - "}{data && data.description ? data.description : "Não identificado"}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-0'>
            <div style={{marginBottom: '3rem'}}>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='text-muted mt-1 fw-semibold fs-5'>Deseja deletar esse template permanentemente?</span>
                </h3>
            </div>
            {/*begin::Form */}
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <button
                    type='button'
                    className='btn btn-lg btn-secondary'
                    disabled={loading}
                    onClick={() => {
                        closed()
                    }}
                    //data-kt-stepper-action='submit'
                >
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 ms-2 me-0'
                    />
                    <span style={{marginLeft: '0.5rem'}}>Cancelar</span>
                    
                </button>

                <button
                    type='button'
                    className='btn btn-lg btn-danger'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={submit}
                >
                    Deletar
                    {
                        loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                        :
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                        />
                    }
                </button>
            </div>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalDeleteTemplate}

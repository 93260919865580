import api from "./api";

export const postTemplateProposalOne = async (body: any) => {
    const response = await api.post(`templateProposal/`, body);
    return response.data;
};

export const getTemplateProposalAll = async () => {
    const response = await api.get(`templateProposal/listAll`,);
    return response.data;
};

export const putTemplateProposalAll = async (id: string, body: any) => {
    const response = await api.put(`templateProposal/${id}`, body);
    return response.data;
};

export const deleteTemplateProposalAll = async (id: string) => {
    const response = await api.delete(`templateProposal/${id}`);
    return response.data;
};
import api from "./api";

export const postTemplateOne = async (body: any) => {
    const response = await api.post(`template/`, body);
    return response.data;
};

export const getTemplateAll = async () => {
    const response = await api.get(`template/listAll`,);
    return response.data;
};

export const putTemplateAll = async (id: string, body: any) => {
    const response = await api.put(`template/${id}`, body);
    return response.data;
};

export const deleteTemplateAll = async (id: string) => {
    const response = await api.delete(`template/${id}`);
    return response.data;
};
import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { formatDate } from '../../../utils/date';
import { Loading } from '../Loadings/loading';
import { BarGraph } from './Componentes/BarGraphModal';
import { LineGraph } from './Componentes/LineGraphModal';
import './styles.css'
import { BarGraphInterval } from './Componentes/BarGraphIntervalModal';

type Props = {
  charts: any,
  loading: boolean,
  typeGraphc?: string
  plantsFilter?: any[];
  dataFilter?: any;
  setDataFilter?: any;
  data?: any;
  prognostico?: any
  myProg?: any;
}

export function GraphicsModalPlant({charts, loading, typeGraphc, plantsFilter, dataFilter, setDataFilter, data, prognostico, myProg}: Props) {
  const [dailyCharts, setDailyCharts] = useState({});
  const [mounthCharts, setMounthCharts] = useState({});
  const [yearCharts, setYearCharts] = useState({});
  const [totalCharts, setTotalCharts] = useState({});
  const [intervalCharts, setIntervalCharts] = useState({});
  const [largura, setLargura] = useState(window.innerWidth);

  const toFillCharts = () => {
    if(charts){
      /*const daily_charts_result = dailyChartsFunction(charts.daily_charts)
      const mount_charts_result = mounthChartsFunction(charts.mounth_charts)
      const year_charts_result = yearChartsFunction(charts.year_charts)
      const total_charts_result = totalChartsFunction(charts.total_charts)*/
  
      setDailyCharts(charts && charts.daily_charts ? charts.daily_charts : null)
      setMounthCharts(charts && charts.month_charts ? charts.month_charts : null)
      setYearCharts(charts && charts.year_charts ? charts.year_charts : null)
      setTotalCharts(charts && charts.total_charts ? charts.total_charts : null)
      setIntervalCharts(charts && charts.interval_charts ? charts.interval_charts : null)
    }
  }
  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };
  
  useEffect(() => {
    toFillCharts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charts]);

  useEffect(() => {
    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, []);

  if (loading) {
    return (
      <div 
        className='row g-xl-8 card p-0 divLoadingCharts' style={{marginTop: '1.5rem', height: '20rem', marginLeft: '0rem'}}>
          <Loading myClass='spinner-border loadingChart' />
      </div>)
  }else{
    if(!charts){
      return (
        <div 
          className='row g-xl-8 card p-0 divNotCharts' 
          style={{
            width: largura < 1200 ? '100%' : '60%',
          }}>
            <h3 className='h3DivNotChart'>
              <span className='text-muted mt-1 fw-semibold fs-5' style={{fontWeight: '800'}}>Não há dados de graficos.</span>
            </h3>
        </div>)
    }
  }

  return (
    <div
      className={`row g-xl-8 ${largura < 1200 ? '' : 'card'} p-0 divGlobal`} 
      style={{
        width: largura < 1200 || (typeGraphc && typeGraphc === 'grapich') ? '100%' : '60%',
        marginTop: '1.5rem',
        marginLeft: '0rem',
      }}
    >
      <div className='card-header border-0' style={{marginTop: '1rem'}}>
        
        <h3 className='card-title align-items-start flex-column' style={{display: 'flex', flexDirection: 'column'}}>
          <span className='card-label fw-bold fs-3 mb-1'>Gráfico da Usina</span>
          <span className='text-muted fw-semibold fs-7'>Geração de energia da usina {}</span>
        </h3>

        {
          typeGraphc && typeGraphc === 'grapich' ?
          (
            <div className='d-flex align-items-center my-2'>
              <div className='w-130px me-5'>
                <select
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-white form-select-sm'
                  value={dataFilter ? dataFilter : "1"}
                  onChange={(event: any) => {
                    setDataFilter ? setDataFilter(event.target.value) : console.log('Sem função de filtro')
                  }}
                >
                  <option value="1"></option>
                  {
                    plantsFilter && plantsFilter.length > 0 && plantsFilter.map((plant: any) => {
                      return (
                        <option value={plant.id}>{`${plant.plantId} - ${plant.name}`}</option>
                      );
                    })
                  }
                </select>
              </div>

              <div>
                {/* <h3 className='card-title align-items-start flex-column' style={{marginBottom: '0.1rem'}}>
                  <span className='text-muted fw-bold fs-7'>Status: </span>
                </h3> */}
                <div>
                  <span 
                    className={
                      `badge badge-light-${
                        data && data.status !== null
                          ? data.status === 0
                          ? "success"
                          : data.status === 1
                          ? "danger"
                          : data.status === 2
                          ? "warning"
                          : data.status === 7 || data.status === 3
                          ? "info"
                          : "secondary"
                        : "secondary"
                      } fs-8 fw-semibold`
                    }
                  >
                      {
                        data.status !== null
                          ? data.status === 0
                          ? "Online"
                          : data.status === 1
                          ? "Offline"
                          : data.status === 2
                          ? "Alerta"
                          : data.status === 7 || data.status === 3
                          ? "Lembrar"
                          : "Desconhecido"
                      : "Desconhecido"
                      }
                  </span>
                </div>
              </div>

            </div>
          )
          : (<></>)
        }

        {<div className='card-toolbar nav nav-tabs nav-line-tabs' data-kt-buttons='true' 
          style={{
            flexWrap: 'nowrap', marginTop: '1rem', 
            overflowX: 'scroll', overflowY: 'hidden', whiteSpace: 'nowrap', 
            scrollBehavior: 'smooth',
            width: largura < 1200 ? '25rem' : ''
            //scrollbarWidth: 'none',
            //msOverflowStyle: 'none'
          }}>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1 nav-item'
            data-bs-toggle="tab"
            href="#graphicModal1"
            style={{flexShrink: 0}}
          >
            Diário
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 nav-item'
            data-bs-toggle="tab"
            href="#graphicModal2"
            style={{flexShrink: 0}}
          >
            Mensal
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 nav-item'
            data-bs-toggle="tab"
            href="#graphicModal3"
            style={{flexShrink: 0}}
          >
            Anual
          </a>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 nav-item'
            data-bs-toggle="tab"
            href="#graphicModal4"
            style={{flexShrink: 0}}
          >
            Total
          </a>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 nav-item'
            data-bs-toggle="tab"
            href="#graphicModal5"
            style={{flexShrink: 0}}
          >
            Período
          </a>
        </div>}
      </div>

      <div className="tab-content" id="myTabContent" style={{marginBottom: '0rem'}}>
        <div
          className="tab-pane fade active show"
          id="graphicModal1"
          role="tabpanel"
        >
          <div className='row g-5 g-xl-2'>
            <LineGraph className='card-xl-stretch mb-xl-8' charts={dailyCharts} chartsMounth={mounthCharts} myProg={myProg}/>
          </div>
        </div>
        <div className="tab-pane fade" id="graphicModal2" role="tabpanel">
          <BarGraph className='card-xl-stretch mb-xl-8' charts={mounthCharts} prognostico={prognostico ? prognostico : null} myProg={myProg}/>
        </div>
        <div className="tab-pane fade" id="graphicModal3" role="tabpanel">
          <BarGraph className='card-xl-stretch mb-xl-8' charts={yearCharts} prognostico={prognostico ? prognostico : null} myProg={myProg}/>
        </div>
        <div className="tab-pane fade" id="graphicModal4" role="tabpanel">
          <BarGraph className='card-xl-stretch mb-xl-8' charts={totalCharts} prognostico={prognostico ? prognostico : null} myProg={myProg}/>
        </div>
        <div className="tab-pane fade" id="graphicModal5" role="tabpanel">
          <BarGraphInterval className='card-xl-stretch mb-xl-8' charts={intervalCharts} prognostico={prognostico ? prognostico : null} myProg={myProg}/>
        </div>
      </div>

      {typeGraphc && typeGraphc === 'grapich' ? 
      <div className='card-body' style={{marginTop: '0rem', paddingTop: '0rem'}}>
        <div style={{marginBottom: '1rem'}}>
          <h3 className='card-title align-items-start flex-column'>
            <span className='text-muted fw-semibold fs-7'>Ciclo de <span className='fw-bold'>{formatDate(new Date())}</span></span>
          </h3>
        </div>
        
        <div style={{display: 'flex', gap: '2rem', justifyContent: 'space-between', marginTop: '0rem', paddingTop: '0rem'}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <KTSVG path={'/media/icons/duotune/general/gen060.svg'} className={`svg-icon-muted svg-icon-4x ms-n1`} />
            <h3 
              className='card-title align-items-start flex-column' 
              style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
              }}>
              <span className='text-muted fw-semibold fs-7' style={{width: '100%', textAlign: 'center'}}>Gerado</span>
              <span className='card-label fw-bold fs-6 mb-1' style={{width: '100%', textAlign: 'center'}}>{data && data.energyMonth ? data.energyMonth : 0} KWh</span>
            </h3>
          </div>

          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <KTSVG path={'/media/icons/duotune/finance/fin003.svg'} className={`svg-icon-muted svg-icon-4x ms-n1`} />
            <h3 
              className='card-title align-items-start flex-column' 
              style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
              }}>
              <span className='text-muted fw-semibold fs-7' style={{width: '100%', textAlign: 'center'}}>Faturado</span>
              <span className='card-label fw-bold fs-6 mb-1' style={{width: '100%', textAlign: 'center'}}>{data && data.value_fat_mes ? data.value_fat_mes : 0} R$</span>
            </h3>
          </div>

          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <KTSVG path={'/media/icons/duotune/finance/fin008.svg'} className={`svg-icon-muted svg-icon-4x ms-n1`} />
            <h3 
              className='card-title align-items-start flex-column' 
              style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
              }}>
              <span className='text-muted fw-semibold fs-7' style={{width: '100%', textAlign: 'center'}}>Economizado</span>
              <span className='card-label fw-bold fs-6 mb-1' style={{width: '100%', textAlign: 'center'}}>{data && data.economy_mes ? data.economy_mes : 0} R$</span>
            </h3>
          </div>
        </div>

      </div>
      :
      <></>  
      }
    </div>
  );
}
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { useToast } from '../../../components/Toats'
import { LoadingSpan } from '../../../components/Loadings/loadingSpan'
import { getReportsAllAnt, getReportsOne } from '../../../../services/reports'
import { Loading } from '../../../components/Loadings/loading'
import { useAuth } from '../../../../modules/auth'
import { DateAno, DateCompleteBarra, extrairMesDia, getShortMonthName, nameMes } from '../../../../utils/date'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { getFileBase64 } from '../../../../services/files'
import ReactDOMServer from 'react-dom/server';
import { generatePDF } from '../../../../utils/savePDF'
import useSize from '../../../../hooks/useSize'

type Props = {
    id: string | undefined;
    show: boolean;
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalResumoReport = ({show, handleClose, id}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  const [report, setReport] = useState<any | null>(null)
  const user = useAuth();
  const [loadingReports, setLoadingReports] = useState(true);
  const [loadingSubmitPDF, setLoadingSubmitPDF] = useState(false)
  const {width} = useSize()
  
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    setReport(null);
    handleClose()
  }

  const getReportsAnt = async (plant: any, dataAnt: any, status: any) => {
    setLoadingReports(true)
    try {
      const myReports = await getReportsAllAnt(status, plant, dataAnt)
      if(myReports && myReports.length > 0){
        let reportsArray = [] as any;
        myReports.map((reportAnt: any) => {
          reportsArray.push({
            mes: getShortMonthName(reportAnt.data),
            generation: reportAnt.generation,
            performance: reportAnt.performance,
            consumption: reportAnt.consumption,
            saved: reportAnt.saved,
          });
        })
        if(reportsArray.length > 0){
          // formik.setValues({...formik.values, reportsAnt: reportsArray})
          return reportsArray
        }else{
          return []
        }
        
      }
    } catch (error) {
      console.log(error)
    }finally{
      setLoadingReports(false)
    }
  }

  // const graphicMouth = (charts_mouth: any) => {
  //   const chart = charts_mouth;

  //   let valores_total = 0;
    
  //   if(chart && chart.valores && chart.valores.length > 0){
  //     chart.valores.map((value: any) => {
  //       if(value > valores_total){
  //         valores_total = value;
  //       }
  //     })
  //   }

  //   return (
  //     <table
  //       className="
  //         charts-css column show-heading show-labels 
  //         show-primary-axis show-4-secondary-axes show-data-axes 
  //         data-spacing-5 hide-data"
  //         style={{maxHeight: '170px', maxWidth: '100%', overflowY: 'auto', overflowX: 'auto'}}
  //     >
  //         {/* <caption><span>Geração (KWh) - {DateCompleteBarra(report.initPeriod)} à {DateCompleteBarra(report.endPeriod)}</span></caption> */}

  //         <thead>
  //           <tr>
  //             <th scope="col"> Year </th>
  //             <th scope="col"> Value </th>
  //           </tr>
  //         </thead>

  //         <tbody>
  //           {chart && chart.dias && chart.dias.map((dia: any, index: any) => {
  //             if(chart.valores && chart.valores[index] !== 0){
  //               return (
  //                 <tr>
  //                   <th style={{fontSize: '0.7rem'}}> <span style={{paddingTop: '0.5rem', color: '#181c32'}}>{extrairMesDia(dia)}</span> </th>  
  //                   <td style={{ '--size': `calc(${chart.valores[index]} / ${valores_total})`,'--color': report.color_company ? report.color_company : '#ffc700', color: '#fffefe', fontSize: '0.7rem'} as any}>
  //                     {chart && chart.valores ? chart.valores[index] : 0}
  //                   </td>
  //                 </tr>
  //               )
  //             }
  //           })}
  //         </tbody>

  //       </table>
  //   )
  // }


//   const graphicMouth = (charts_mouth: any) => {
//   const chart = charts_mouth;

//   let valores_total = 0;

//   if (chart && chart.valores && chart.valores.length > 0) {
//     chart.valores.map((value: any) => {
//       if (value > valores_total) {
//         valores_total = value;
//       }
//     });
//   }

//   return (
//     <div
//       style={{
//         maxWidth: '900px',
//         overflowX: 'auto',
//         overflowY: 'hidden',
//         margin: '0 auto', // Centraliza no modal
//       }}
//     >
//       <table
//         className="
//           charts-css column show-heading show-labels 
//           show-primary-axis show-4-secondary-axes show-data-axes 
//           data-spacing-5 hide-data"
//         style={{
//           minWidth: '1200px', // Largura mínima para evitar compressão
//           maxHeight: '250px',
//           overflowX: 'hidden',
//         }}
//       >
//         <thead>
//           <tr>
//             <th scope="col">Year</th>
//             <th scope="col">Value</th>
//           </tr>
//         </thead>

//         <tbody>
//           {chart &&
//             chart.dias &&
//             chart.dias.map((dia: any, index: any) => {
//               if (chart.valores && chart.valores[index] !== 0) {
//                 return (
//                   <tr key={index}>
//                     <th style={{ fontSize: '0.7rem' }}>
//                       <span style={{ paddingTop: '0.5rem', color: '#181c32' }}>
//                         {extrairMesDia(dia)}
//                       </span>
//                     </th>
//                     <td
//                       style={
//                         {
//                           '--size': `calc(${chart.valores[index]} / ${valores_total})`,
//                           '--color': report.color_company
//                             ? report.color_company
//                             : '#ffc700',
//                           color: '#fffefe',
//                           fontSize: '0.7rem',
//                           textAlign: 'center', // Centraliza valores nas barras
//                         } as any
//                       }
//                     >
//                       {chart && chart.valores ? chart.valores[index] : 0}
//                     </td>
//                   </tr>
//                 );
//               }
//             })}
//         </tbody>
//       </table>
//     </div>
//   );
// };


// const graphicMouth = (charts_mouth: any) => {
//   const chart = charts_mouth;

//   let valores_total = 0;

//   // Calcula o maior valor no conjunto de valores
//   if (chart && chart.valores && chart.valores.length > 0) {
//     valores_total = Math.max(...chart.valores);
//   }

//   const maxHeight = 250; // Altura máxima do gráfico

//   return (
//     <div
//       style={{
//         maxWidth: '900px',
//         overflowX: 'auto',
//         overflowY: 'hidden',
//         margin: '0 auto', // Centraliza no modal
//       }}
//     >
//       <table
//         className="
//           charts-css column show-heading show-labels 
//           show-primary-axis show-4-secondary-axes show-data-axes 
//           data-spacing-5 hide-data"
//         style={{
//           minWidth: '1200px', // Largura mínima para evitar compressão
//           maxHeight: `${maxHeight}px`, // Limita a altura do gráfico
//           overflowX: 'hidden',
//         }}
//       >
//         <thead>
//           <tr>
//             <th scope="col">Year</th>
//             <th scope="col">Value</th>
//           </tr>
//         </thead>

//         <tbody>
//           {chart &&
//             chart.dias &&
//             chart.dias.map((dia: any, index: any) => {
//               if (chart.valores && chart.valores[index] !== 0) {
//                 // Normaliza a altura da barra proporcionalmente ao maior valor
//                 const barHeight =
//                   valores_total > 0
//                     ? (chart.valores[index] / valores_total) * maxHeight
//                     : 0;

//                 return (
//                   <tr key={index}>
//                     <th style={{ fontSize: '0.7rem', textAlign: 'center' }}>
//                       <span style={{ paddingTop: '0.5rem', color: '#181c32' }}>
//                         {extrairMesDia(dia)}
//                       </span>
//                     </th>
//                     <td
//                       style={{
//                         height: `${barHeight}px`, // Define a altura da barra
//                         backgroundColor: report.color_company
//                           ? report.color_company
//                           : '#ffc700', // Define a cor da barra
//                         color: '#fffefe', // Cor do texto
//                         fontSize: '0.7rem',
//                         textAlign: 'center', // Centraliza valores nas barras
//                         verticalAlign: 'bottom', // Garante que as barras cresçam de baixo para cima
//                       }}
//                     >
//                       {chart.valores[index]}
//                     </td>
//                   </tr>
//                 );
//               }
//             })}
//         </tbody>
//       </table>
//     </div>
//   );
// };




const graphicMouth = (charts_mouth: any) => {
  const chart = charts_mouth;

  let valores_total = 0;

  if (chart && chart.valores && chart.valores.length > 0) {
    valores_total = Math.max(...chart.valores); // Obtém o valor máximo diretamente
  }

  const maxBarHeight = 200; // Define a altura máxima para as barras (em pixels)

  return (
    <div
      style={{
        maxWidth: '900px',
        overflowX: 'auto',
        overflowY: 'hidden',
        margin: '0 auto', // Centraliza no modal
      }}
    >
      <table
        className="
          charts-css column show-heading show-labels 
          show-primary-axis show-4-secondary-axes show-data-axes 
          data-spacing-5 hide-data"
        style={{
          minWidth: '1200px', // Largura mínima para evitar compressão
          maxHeight: `${maxBarHeight + 50}px`, // Inclui espaço para cabeçalhos
          overflowX: 'hidden',
        }}
      >
        <thead>
          <tr>
            <th scope="col">Year</th>
            <th scope="col">Value</th>
          </tr>
        </thead>

        <tbody style={{height: '250px'}}>
          {chart &&
            chart.dias &&
            chart.dias.map((dia: any, index: any) => {
              if (chart.valores && chart.valores[index] !== 0) {
                // Calcula a altura proporcional da barra
                const normalizedHeight =
                  valores_total > 0
                    ? (chart.valores[index] / valores_total) * maxBarHeight
                    : 0;

                // Define uma altura mínima para garantir a visibilidade
                const barHeight = Math.max(normalizedHeight, 10);

                return (
                  <tr key={index}>
                    <th style={{ fontSize: '0.7rem' }}>
                      <span style={{ paddingTop: '0.5rem', color: '#181c32' }}>
                        {extrairMesDia(dia)}
                      </span>
                    </th>
                    <td
                      style={{
                        height: `${barHeight}px`,
                        backgroundColor: report.color_company
                          ? report.color_company
                          : '#ffc700',
                        color: '#fffefe',
                        fontSize: '0.7rem',
                        textAlign: 'center', //
                        verticalAlign: 'bottom',
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                      }}
                    >
                      {chart.valores[index]}
                    </td>
                  </tr>
                );
              }
            })}
        </tbody>
      </table>
    </div>
  );
};


// const graphicMouth = (charts_mouth: any) => {
//   const chart = charts_mouth;

//   let valores_total = 0;

//   if (chart && chart.valores && chart.valores.length > 0) {
//     valores_total = Math.max(...chart.valores); // Obtém o valor máximo diretamente
//   }

//   const maxBarHeight = 150; // Define a altura máxima para as barras (em pixels)

//   return (
//     <div
//       style={{
//         maxWidth: '900px',
//         overflowX: 'auto',
//         overflowY: 'hidden',
//         margin: '0 auto', // Centraliza no modal
//       }}
//     >
//       <table
//         className="
//           charts-css column show-heading show-labels 
//           show-primary-axis show-4-secondary-axes show-data-axes 
//           data-spacing-5 hide-data"
//         style={{
//           minWidth: '1200px', // Largura mínima para evitar compressão
//           height: `${maxBarHeight + 50}px`, // Altura total ajustada
//         }}
//       >
//         <thead>
//           <tr>
//             <th scope="col">Year</th>
//             <th scope="col">Value</th>
//           </tr>
//         </thead>

//         <tbody
//           style={{
//             display: 'flex',
//             alignItems: 'flex-end', // Posiciona as barras na base
//             justifyContent: 'space-between', // Espaçamento uniforme entre barras
//             height: `${maxBarHeight}px`, // Altura ajustada
//             margin: 0,
//             padding: 0,
//           }}
//         >
//           {chart &&
//             chart.dias &&
//             chart.dias.map((dia: any, index: any) => {
//               if (chart.valores && chart.valores[index] !== 0) {
//                 // Calcula a altura proporcional da barra
//                 const normalizedHeight =
//                   valores_total > 0
//                     ? (chart.valores[index] / valores_total) * maxBarHeight
//                     : 0;

//                 // Define uma altura mínima para garantir a visibilidade
//                 const barHeight = Math.max(normalizedHeight, 10);

//                 return (
//                   <tr
//                     key={index}
//                     style={{
//                       height: `${barHeight}px`, // Altura proporcional
//                       backgroundColor: report.color_company
//                         ? report.color_company
//                         : '#ffc700', // Cor configurável
//                       textAlign: 'center', // Centraliza valores nas barras
//                       display: 'flex',
//                       flexDirection: 'column', // Garante empilhamento vertical
//                       justifyContent: 'flex-end', // Posiciona o texto na base
//                       alignItems: 'center',
//                       margin: '0 5px', // Espaçamento lateral entre barras
//                     }}
//                   >
//                     <th style={{ fontSize: '0.7rem' }}>
//                       {extrairMesDia(dia)}
//                     </th>
//                     <td
//                       style={{
//                         color: '#fffefe',
//                         fontSize: '0.7rem',
//                       }}
//                     >
//                       {chart.valores[index]}
//                     </td>
//                   </tr>
//                 );
//               }
//             })}
//         </tbody>
//       </table>
//     </div>
//   );
// };


// const graphicMouth = (charts_mouth: any) => {
//   const chart = charts_mouth;

//   let valores_total = 0;

//   if (chart && chart.valores && chart.valores.length > 0) {
//     valores_total = Math.max(...chart.valores); // Obtém o valor máximo diretamente
//   }

//   const maxBarHeight = 150; // Define a altura máxima para as barras (em pixels)

//   return (
//     <div
//       style={{
//         maxWidth: '900px',
//         overflowX: 'auto',
//         overflowY: 'hidden',
//         margin: '0 auto', // Centraliza no modal
//       }}
//     >
//       <table
//         className="
//           charts-css column show-heading show-labels 
//           show-primary-axis show-4-secondary-axes show-data-axes 
//           data-spacing-5 hide-data"
//         style={{
//           minWidth: '1200px', // Largura mínima para evitar compressão
//           height: `${maxBarHeight + 50}px`, // Altura total ajustada
//         }}
//       >
//         <thead>
//           <tr>
//             <th scope="col">Year</th>
//             <th scope="col">Value</th>
//           </tr>
//         </thead>

//         <tbody>
//           {chart &&
//             chart.dias &&
//             chart.dias.map((dia: any, index: any) => {
//               if (chart.valores && chart.valores[index] !== 0) {
//                 // Calcula a altura proporcional da barra
//                 const normalizedHeight =
//                   valores_total > 0
//                     ? (chart.valores[index] / valores_total) * maxBarHeight
//                     : 0;

//                 // Define uma altura mínima para garantir a visibilidade
//                 const barHeight = Math.max(normalizedHeight, 10);

//                 return (
//                   <tr key={index}>
//                     <th style={{ fontSize: '0.7rem', textAlign: 'center' }}>
//                       {extrairMesDia(dia)}
//                     </th>
//                     <td
//                       style={{
//                         height: `${barHeight}px`, // Altura proporcional
//                         textAlign: 'center', // Centraliza o texto
//                         fontSize: '0.7rem', // Tamanho da fonte
//                       }}
//                     >
//                       {chart.valores[index]}
//                     </td>
//                   </tr>
//                 );
//               }
//             })}
//         </tbody>
//       </table>
//     </div>
//   );
// };











// const graphicMouth = (charts_mouth: any) => {
//   const chart = charts_mouth;

//   const maxBarHeight = 170; // Altura máxima das barras em pixels
//   let valores_total = 0;

//   if (chart && chart.valores && chart.valores.length > 0) {
//     valores_total = Math.max(...chart.valores); // Encontra o valor máximo
//   }

//   return (
//     <div
//       style={{
//         maxWidth: '900px',
//         overflowX: 'auto',
//         overflowY: 'hidden',
//         margin: '0 auto', // Centraliza no modal
//       }}
//     >
//       <table
//         className="
//           charts-css column show-heading show-labels 
//           show-primary-axis show-4-secondary-axes show-data-axes 
//           data-spacing-5 hide-data"
//         style={{
//           minWidth: '1200px', // Largura mínima para evitar compressão
//           maxHeight: `${maxBarHeight}px`,
//           overflow: 'hidden',
//         }}
//       >
//         <thead>
//           <tr>
//             <th scope="col">Year</th>
//             <th scope="col">Value</th>
//           </tr>
//         </thead>

//         <tbody>
//           {chart &&
//             chart.dias &&
//             chart.dias.map((dia: any, index: any) => {
//               if (chart.valores && chart.valores[index] !== 0) {
//                 const normalizedHeight =
//                   valores_total > 0
//                     ? (chart.valores[index] / valores_total) * maxBarHeight
//                     : 0;

//                 return (
//                   <tr key={index}>
//                     <th style={{ fontSize: '0.7rem' }}>
//                       <span style={{ paddingTop: '0.5rem', color: '#181c32' }}>
//                         {extrairMesDia(dia)}
//                       </span>
//                     </th>
//                     <td
//                       style={
//                         {
//                           height: `${normalizedHeight}px`, // Define altura proporcional
//                           '--size': normalizedHeight / maxBarHeight, // Para CSS Charts
//                           '--color': report.color_company
//                             ? report.color_company
//                             : '#ffc700',
//                           color: '#fffefe',
//                           fontSize: '0.7rem',
//                           textAlign: 'center',
//                         } as any
//                       }
//                     >
//                       {chart && chart.valores ? chart.valores[index] : 0}
//                     </td>
//                   </tr>
//                 );
//               }
//             })}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// const graphicMouth = (charts_mouth: any) => {
//   const chart = charts_mouth;

//   const maxBarHeight = 170; // Altura máxima das barras em pixels
//   let valores_total = 0;

//   if (chart && chart.valores && chart.valores.length > 0) {
//     valores_total = Math.max(...chart.valores); // Encontra o valor máximo
//   }

//   return (
//     <div
//       style={{
//         maxWidth: '900px',
//         overflowX: 'auto',
//         overflowY: 'hidden',
//         margin: '0 auto', // Centraliza no modal
//       }}
//     >
//       <table
//         className="
//           charts-css column show-heading show-labels 
//           show-primary-axis show-4-secondary-axes show-data-axes 
//           data-spacing-5 hide-data"
//         style={{
//           minWidth: '1200px', // Largura mínima para evitar compressão
//           maxHeight: `${maxBarHeight}px`,
//           overflow: 'hidden',
//         }}
//       >
//         <thead>
//           <tr>
//             <th scope="col">Year</th>
//             <th scope="col">Value</th>
//           </tr>
//         </thead>

//         <tbody>
//           {chart &&
//             chart.dias &&
//             chart.dias.map((dia: any, index: any) => {
//               if (chart.valores && chart.valores[index] !== 0) {
//                 // Calcular altura proporcional
//                 const normalizedHeight =
//                   valores_total > 0
//                     ? (chart.valores[index] / valores_total) * maxBarHeight
//                     : 0;

//                 // Altura mínima para barras visíveis
//                 const barHeight = Math.max(normalizedHeight, 10); // Pelo menos 10px

//                 return (
//                   <tr key={index}>
//                     <th style={{ fontSize: '0.7rem' }}>
//                       <span style={{ paddingTop: '0.5rem', color: '#181c32' }}>
//                         {extrairMesDia(dia)}
//                       </span>
//                     </th>
//                     <td
//                       style={
//                         {
//                           height: `${barHeight}px`, // Define altura proporcional
//                           '--size': barHeight / maxBarHeight, // Para CSS Charts
//                           '--color': report.color_company
//                             ? report.color_company
//                             : '#ffc700',
//                           color: '#fffefe',
//                           fontSize: '0.7rem',
//                           textAlign: 'center',
//                         } as any
//                       }
//                     >
//                       {chart && chart.valores ? chart.valores[index] : 0}
//                     </td>
//                   </tr>
//                 );
//               }
//             })}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// const graphicMouth = (charts_mouth: any) => {
//   const chart = charts_mouth;

//   const maxBarHeight = 170; // Altura máxima das barras em pixels
//   let valores_total = 0;

//   if (chart && chart.valores && chart.valores.length > 0) {
//     valores_total = Math.max(...chart.valores); // Encontra o valor máximo
//   }

//   return (
//     <div
//       style={{
//         maxWidth: '900px',
//         overflowX: 'auto',
//         overflowY: 'hidden',
//         margin: '0 auto', // Centraliza no modal
//       }}
//     >
//       <table
//         className="
//           charts-css column show-heading show-labels 
//           show-primary-axis show-4-secondary-axes show-data-axes 
//           data-spacing-5 hide-data"
//         style={{
//           minWidth: '1200px', // Largura mínima para evitar compressão
//           height: `${maxBarHeight}px`, // Define a altura total do gráfico
//           overflow: 'hidden',
//           position: 'relative',
//         }}
//       >
//         <thead>
//           <tr>
//             <th scope="col">Year</th>
//             <th scope="col">Value</th>
//           </tr>
//         </thead>

//         <tbody>
//           {chart &&
//             chart.dias &&
//             chart.dias.map((dia: any, index: any) => {
//               if (chart.valores && chart.valores[index] !== 0) {
//                 // Calcular altura proporcional
//                 const normalizedHeight =
//                   valores_total > 0
//                     ? (chart.valores[index] / valores_total) * maxBarHeight
//                     : 0;

//                 // Altura mínima para barras visíveis
//                 const barHeight = Math.max(normalizedHeight, 10); // Pelo menos 10px

//                 return (
//                   <tr key={index}>
//                     <th style={{ fontSize: '0.7rem' }}>
//                       <span style={{ paddingTop: '0.5rem', color: '#181c32' }}>
//                         {extrairMesDia(dia)}
//                       </span>
//                     </th>
//                     <td
//                       style={
//                         {
//                           height: `${barHeight}px`, // Altura proporcional
//                           verticalAlign: 'bottom', // Ancora as barras na base
//                           '--size': barHeight / maxBarHeight, // Para gráficos CSS
//                           '--color': report.color_company
//                             ? report.color_company
//                             : '#ffc700',
//                           color: '#fffefe',
//                           fontSize: '0.7rem',
//                           textAlign: 'center',
//                           display: 'flex',
//                           alignItems: 'flex-end', // Posiciona o texto na base da barra
//                           justifyContent: 'center',
//                         } as any
//                       }
//                     >
//                       {chart && chart.valores ? chart.valores[index] : 0}
//                     </td>
//                   </tr>
//                 );
//               }
//             })}
//         </tbody>
//       </table>
//     </div>
//   );
// };


const getImage = async (key: string) => {
    try {
      const url = await getFileBase64(key)
      return url.base64

    } catch (error) {
      console.log(error)
      return ""
    }
  }
  
const getMyReport = async () => {
    try {
        setLoading(true)
        const myReport = await getReportsOne(id ? id : "")
        const myReports = await getReportsAnt(
          myReport && myReport.plant ? myReport.plant.id : "",
          myReport && myReport.data ? myReport.data : "",
          "GERADO"
        )

        const logo_url = myReport.plant 
        && myReport.plant.unitBranch 
        && myReport.plant.unitBranch.company 
        && myReport.plant.unitBranch.company.image_dark 
        ? await getImage (myReport.plant.unitBranch.company.image_dark) 
        : "https://i.postimg.cc/kXzT1qjT/sua-logo-aqui.png";

        const myObject = {
          data: myReport.data ? new Date(myReport.data) : new Date(),
          generation: myReport.generation ? myReport.generation : 0,
          performance: myReport.performance ? myReport.performance : 0,
          consumption: myReport.consumption ? myReport.consumption : 0,
          saved: myReport.saved ? myReport.saved : 0,
          financial_feedback_month: myReport.financial_feedback_month ? myReport.financial_feedback_month : 0,
          financial_feedback_year: myReport.financial_feedback_year ? myReport.financial_feedback_year : 0,
          invoiced: myReport.invoiced ? myReport.invoiced : 0,
          total_economy: myReport.total_economy? myReport.total_economy: 0,

          consumerRegister: myReport.consumerRegister? myReport.consumerRegister: 0,
          consumerInstant: myReport.consumerInstant? myReport.consumerInstant: 0,
          unitConsumerTotal: myReport.unitConsumerTotal? myReport.unitConsumerTotal: 0,
          energyInjected: myReport.energyInjected? myReport.energyInjected: 0,
          //logo: logo_url,
          logo: `data:image/jpeg;base64,${logo_url}`,

          color_company: myReport.plant && myReport.plant.unitBranch 
          && myReport.plant.unitBranch.company 
          && myReport.plant.unitBranch.company.color_primary 
          ? myReport.plant.unitBranch.company.color_primary : null,

          color_secondary: myReport.plant && myReport.plant.unitBranch 
          && myReport.plant.unitBranch.company 
          && myReport.plant.unitBranch.company.color_primary_variet 
          ? myReport.plant.unitBranch.company.color_primary_variet : null,

          prog_mensal: myReport.prog_mensal? myReport.prog_mensal: "0",
          prog_anual: myReport.prog_anual? myReport.prog_anual: "0",

          performance_anual: myReport.performance_anual? myReport.performance_anual: "0",
          generation_anual: myReport.generation_anual? myReport.generation_anual: "0",
          prog_12_meses: myReport.prog_12_meses? myReport.prog_12_meses: "0",

          //initPeriod: myReport.initPeriod ? DateCompleteBarra(myReport.initPeriod) : DateCompleteBarra(new Date()),
          //endPeriod: myReport.endPeriod ? DateCompleteBarra(myReport.endPeriod) : DateCompleteBarra(new Date()), 
          initPeriod: myReport.initPeriod ? myReport.initPeriod : new Date().toString(),
          endPeriod: myReport.endPeriod ? myReport.endPeriod : new Date().toString(),
          corporateName: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.company && myReport.plant.unitBranch.company.corporateName ? myReport.plant.unitBranch.company.corporateName : "https://i.postimg.cc/kXzT1qjT/sua-logo-aqui.png",
          cnpj: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.company && myReport.plant.unitBranch.company.cnpj ? myReport.plant.unitBranch.company.cnpj : "00.00.00.000/00",
          companyName: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.companyName ? myReport.plant.unitBranch.companyName : "Solar Vision Filial",
          emailCompany: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.emailCompany ? myReport.plant.unitBranch.emailCompany : "solarvisionfilial@lekko.com",
          namePlant: myReport.plant && myReport.plant.name ? myReport.plant.name : "Solar Vision Usina",
          capacity: myReport.plant && myReport.plant.capacity ? myReport.plant.capacity : "0",
          adressPlant: myReport.plant && myReport.plant.address ? myReport.plant.address : "Não identificado. ",
          mark: myReport.plant && myReport.plant.credential && myReport.plant.credential.mark && myReport.plant.credential.mark.name ? myReport.plant.credential.mark.name : "Não identificado. ",
          chart_daily: myReport.chart_daily ? myReport.chart_daily : {},
          chart_mouth: myReport.chart_mouth ? myReport.chart_mouth : {},
          chart_year: myReport.chart_year ? myReport.chart_year : {},
          chart_prognosis: myReport.chart_prognosis ? myReport.chart_prognosis : {},
          chart_year_generation:  myReport.chart_year_generation ? myReport.chart_year_generation : {},
          plano: myReport.plant && myReport.plant.plano ? myReport.plant.plano : "",
          chart_comsuption: myReport.chart_comsuption ? myReport.chart_comsuption : {},
          invoices: myReport.invoices ? myReport.invoices.map((myInvoice: any) => {
            let economizado_fatura = 0;

            if(myInvoice.unitConsumer && myInvoice.unitConsumer.type_unit_consumer === "GERADORA"){
              
              const energy_inject = myInvoice.energia_injetada ? myInvoice.energia_injetada.replace(',', '.') : "0"
              const generation = myReport.generation ? myReport.generation.replace(',', '.')  : "0"
              const consumo_instantaneo = parseFloat(generation) - parseFloat(energy_inject)
              const energia_compensada = myInvoice.energia_compensada? myInvoice.energia_compensada.replace(',', '.') : "0"
              const tarifa = myInvoice.tarifa ? myInvoice.tarifa.replace(',', '.') : "0"
              const consumo_total = consumo_instantaneo + parseFloat(energia_compensada);
              economizado_fatura = parseFloat(tarifa) * consumo_total;

            }else{
              const energia_compensada = myInvoice.energia_compensada? myInvoice.energia_compensada.replace(',', '.') : "0"
              const tarifa = myInvoice.tarifa ? myInvoice.tarifa.replace(',', '.') : "0"
              economizado_fatura = parseFloat(tarifa) * energia_compensada;
            }
            return {
                contrato: myInvoice.unitConsumer && myInvoice.unitConsumer.name ? myInvoice.unitConsumer.name : "",
                dateInit: myInvoice.leitura_anterior ? myInvoice.leitura_anterior : new Date(),
                dateAnd: myInvoice.leitura_atual ? myInvoice.leitura_atual : new Date(),
                energia_registrada: myInvoice.energia_registrada ? myInvoice.energia_registrada : "0",
                energia_compensada: myInvoice.energia_compensada ? myInvoice.energia_compensada : "0",
                energia_faturada: myInvoice.energia_faturada ? myInvoice.energia_faturada : "0",
                creditos_ant: myInvoice.creditos_ant ? myInvoice.creditos_ant : "0",
                creditos: myInvoice.creditos? myInvoice.creditos: "0",
                valor_faturado: myInvoice.valor_faturado ? myInvoice.valor_faturado : "0",
                economizado: economizado_fatura.toFixed(2).toString(),
            }
          }) : [],

          graphic_days: {},
          graphic_months: {},

          //chart_mouth: myReport.chart_mouth ? myReport.chart_mouth : {},

          idTemplate: myReport.templates && myReport.templates.length > 0 ? myReport.templates[0].id : null,
          idsTemplates: myReport.templates && myReport.templates.length > 0 ? myReport.templates.map((template: any) => {return template.id}) : [],
          templates: myReport.templates && myReport.templates.length > 0 ? myReport.templates: null,
          reportsAnt: myReports ? myReports : []
        }
        setReport(myObject)
        
    } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar o relatório. ", "error")
    }finally{
        setLoading(false)
    }
  }

  const graphicYear = (chart1: any, chart2: any) => {
    if (!chart1 || !chart1.meses || !chart1.valores || chart1.meses.length === 0 || chart1.valores.length === 0) {
        chart1 = { meses: [], valores: [] };
    }
  
    if (!chart2 || !chart2.meses || !chart2.valores || chart2.meses.length === 0 || chart2.valores.length === 0) {
        chart2 = { meses: [], valores: [] };
    }
  
    let valores_total = 100;
  
    const todosMeses = [...new Set([...chart1.meses, ...chart2.meses])];
  
    todosMeses.forEach(mes => {
        const valorA = chart1.meses.includes(mes) ? chart1.valores[chart1.meses.indexOf(mes)] : 0;
        const valorB = chart2.meses.includes(mes) ? chart2.valores[chart2.meses.indexOf(mes)] : 0;
        const maiorValor = Math.max(valorA, valorB);
        if (maiorValor > valores_total) {
            valores_total = maiorValor;
        }
    });
  
    return (
        <table 
            className="
              charts-css bar show-heading 
              show-labels show-primary-axis show-4-secondary-axes 
              show-data-axes data-spacing-5 hide-data"
        >
            <caption><span>Geração / Consumo - meses anteriores - {DateAno(report.data)}</span></caption>
  
            <thead>
                <tr>
                    <th scope="col"> Year </th>
                    <th scope="col"> Value </th>
                </tr>
            </thead>
  
            <tbody>
                {todosMeses.length > 0 ? (
                    todosMeses.map((mes: any, index: any) => {
                        const valorA = chart1.meses.includes(mes) ? chart1.valores[chart1.meses.indexOf(mes)] : 0;
                        const valorB = chart2.meses.includes(mes) ? chart2.valores[chart2.meses.indexOf(mes)] : 0;
                        return (
                            <tr key={index}>
                                <th style={{fontSize: '0.6rem'}}>
                                    <span style={{paddingTop: '0.5rem', color: '#181c32 !important'}}>{mes}</span>
                                </th>  
                                <td 
                                    style={{ 
                                        '--size': `calc(${valorA} / ${valores_total})`,
                                        '--color': user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0 ? user.currentUser.companies[0].color_primary : '#ffc700',
                                        color: '#fffefe !important',
                                        fontSize: '0.6rem'
                                    } as any}
                                >
                                    {valorA}
                                </td>
                                <td 
                                    style={{ 
                                        '--size': `calc(${valorB} / ${valores_total})`,
                                        '--color': user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0 ? user.currentUser.companies[0].color_primary_variet : '#00aaff',
                                        color: '#fffefe !important',
                                        fontSize: '0.6rem'
                                    } as any}
                                >
                                    {valorB}
                                </td>
                            </tr>
                        )
                    })
                ) : (
                    <tr>
                        <td colSpan={3} style={{ textAlign: 'center' }}>Nenhum dado disponível</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
  };

  const graphicYearGeneration = (chart1: any, chart2: any) => {
    if (!chart1 || !chart1.meses || !chart1.valores || chart1.meses.length === 0 || chart1.valores.length === 0) {
        chart1 = { meses: [], valores: [] };
    }
  
    if (!chart2 || !chart2.meses || !chart2.valores || chart2.meses.length === 0 || chart2.valores.length === 0) {
        chart2 = { meses: [], valores: [] };
    }
  
    let valores_total = 100;
  
    const todosMeses = [...new Set([...chart1.meses, ...chart2.meses])]
      .sort((a: string, b: string) => new Date(a).getTime() - new Date(b).getTime());
  
    todosMeses.forEach(mes => {
        const valorA = chart1.meses.includes(mes) ? chart1.valores[chart1.meses.indexOf(mes)] : 0;
        const valorB = chart2.meses.includes(mes) ? chart2.valores[chart2.meses.indexOf(mes)] : 0;
        const maiorValor = Math.max(valorA, valorB);
        if (maiorValor > valores_total) {
            valores_total = maiorValor;
        }
    });
  
    return (
        <table 
            className="
                charts-css column show-heading 
                show-labels show-primary-axis show-4-secondary-axes 
                show-data-axes data-spacing-5 hide-data"
        >
            <caption><span>Geração / Prognóstico - Ultimos 12 meses</span></caption>
  
            <thead>
                <tr>
                    <th scope="col">Mês</th>
                    <th scope="col">Valor A</th>
                    <th scope="col">Valor B</th>
                </tr>
            </thead>
  
            <tbody>
                {todosMeses.length > 0 ? (
                    todosMeses.map((mes, index) => {
                        const valorA = chart1.meses.includes(mes) ? chart1.valores[chart1.meses.indexOf(mes)] : 0;
                        const valorB = chart2.meses.includes(mes) ? chart2.valores[chart2.meses.indexOf(mes)] : 0;
                        const proporcaoA = (typeof valorA === 'number' && typeof valores_total === 'number') ? valorA / valores_total : 0;
                        const proporcaoB = (typeof valorB === 'number' && typeof valores_total === 'number') ? valorB / valores_total : 0;
                        
                        return (
                            <tr key={index}>
                                <th style={{fontSize: '0.6rem'}}>
                                    <span style={{paddingTop: '0.5rem', color: '#181c32 !important'}}>{mes}</span>
                                </th>  
                                <td 
                                    style={{ 
                                        '--size': `${proporcaoA}`,
                                        '--color': user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0 ? user.currentUser.companies[0].color_primary : '#ffc700',
                                        color: '#fffefe !important',
                                        fontSize: '0.6rem'
                                    } as any}
                                >
                                    {valorA}
                                </td>
                                <td 
                                    style={{ 
                                        '--size': `${proporcaoB}`,
                                        '--color': user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0 ? user.currentUser.companies[0].color_primary_variet : '#00aaff',
                                        color: '#fffefe !important',
                                        fontSize: '0.6rem'
                                    } as any}
                                >
                                    {valorB}
                                </td>
                            </tr>
                        )
                    })
                ) : (
                    <tr>
                        <td colSpan={3} style={{ textAlign: 'center' }}>Nenhum dado disponível</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
  };

  const graphicYearComsuption = (chart2: any) => {
    if (!chart2 || !chart2.meses || !chart2.valores || chart2.meses.length === 0 || chart2.valores.length === 0) {
        chart2 = { meses: [], valores: [] };
    }
  
    let valores_total = 100;
  
    const todosMeses = [...new Set(chart2.meses)];
  
    todosMeses.forEach(mes => {
        const valorB = chart2.meses.includes(mes) ? chart2.valores[chart2.meses.indexOf(mes)] : 0;
        if (valorB > valores_total) {
            valores_total = valorB;
        }
    });
  
    return (
      <table 
          className="
            charts-css column show-heading 
            show-labels show-primary-axis show-4-secondary-axes 
            show-data-axes data-spacing-5 hide-data"
      >
          <caption><span>Consumo - meses anteriores - {DateAno(report.data)}</span></caption>

          <thead>
              <tr>
                  <th scope="col"> Month </th>
                  <th scope="col"> Value </th>
              </tr>
          </thead>

          <tbody>
              {todosMeses.length > 0 ? (
                  todosMeses.map((mes: any, index: any) => {
                      const valorB = chart2.meses.includes(mes) ? chart2.valores[chart2.meses.indexOf(mes)] : 0;
                      return (
                          <tr key={index}>
                              <th style={{fontSize: '0.6rem'}}>
                                  <span style={{paddingTop: '0.5rem', color: '#181c32 !important'}}>{mes}</span>
                              </th>  
                              <td 
                                  style={{ 
                                      '--size': `calc(${valorB} / ${valores_total})`,
                                      '--color': user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0 ? user.currentUser.companies[0].color_primary_variet : '#00aaff',
                                      color: '#fffefe !important',
                                      fontSize: '0.6rem'
                                  } as any}
                              >
                                  {valorB}
                              </td>
                          </tr>
                      )
                  })
              ) : (
                  <tr>
                      <td colSpan={2} style={{ textAlign: 'center' }}>Nenhum dado disponível</td>
                  </tr>
              )}
          </tbody>
      </table>
    );
  };


  const replaceValues = (dados: any, html: string) => {
    let novoHtml = html;
    
    Object.keys(dados).forEach(chave => {
      if(chave === 'data'){
        const mounthYear = [
          'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
          'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
        ];

        const data = new Date(dados[chave]);
        const year = data.getFullYear();
        const month = data.getMonth() + 1;
        const nameMonth = mounthYear[month - 1];

        const marcadorYear = new RegExp(`{{year}}`, 'g');
        const marcadorMonth = new RegExp(`{{month}}`, 'g');

        novoHtml = novoHtml.replace(marcadorYear, year.toString());
        novoHtml = novoHtml.replace(marcadorMonth, nameMonth);
      }else{
        if(chave === 'reportsAnt'){

          const reportsHtml = dados[chave].map((report: any) => {
            const tds = Object.keys(report).map(key => {
              return `
                <td>
                  <div class='d-flex align-items-center'>
                    <div class='d-flex justify-content-start flex-column'>
                      <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
                        ${report[key]}
                      </a>
                    </div>
                  </div>
                </td>
              `  
              //return `<td>${report[key]}</td>`;
            }).join('');
            return `<tr>${tds}</tr>`;
        }).join('');
        
        // Adiciona as trs ao HTML

        novoHtml = novoHtml.replace(
          `<tbody id="table_reports"><tr><td colspan="4"><span>Valores de relatorios anteriores</span></td></tr></tbody>`, 
          `<tbody id="table_reports">${reportsHtml}</tbody>`
        );


        }else{
          if(chave === 'chart_mouth'){
            const charts_mouth = dados[chave];
            
            novoHtml = novoHtml.replace(
              `<div id="chart_daily" style="height: 15.5rem; border-color: #e1e3ea !important" class="border border-dashed rounded">Gráficos dos dias</div>`, 
              `<div id="chart_daily" style="height: 10rem; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                ReactDOMServer.renderToString(graphicMouth(charts_mouth))
                //teste()
                  // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
                // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
              }
              </div>`
            );

            novoHtml = novoHtml.replace(
              `<div class="border border-dashed rounded" style="border-bottom-color:#e1e3ea;border-left-color:!important;border-right-color:!important;border-top-color:#e1e3ea;height:15.5rem;" id="chart_daily">Gráficos dos dias</div>`, 
              `<div id="chart_daily" style="height: 10rem; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                ReactDOMServer.renderToString(graphicMouth(charts_mouth))
                //teste()
                  // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
                // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
              }
              </div>`
            );

          }else{
            if(chave === 'chart_year'){
            
            const charts_year = dados[chave];
            const charts_comsuption = dados["chart_comsuption"]

            novoHtml = novoHtml.replace(
              `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">Gráficos mensais</div>`, 
              `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                ReactDOMServer.renderToString(graphicYear(charts_year, charts_comsuption))
              }
              </div>`
            );

            novoHtml = novoHtml.replace(
              `<div class="border border-dashed rounded" style="border-bottom-color:#e1e3ea;border-left-color:!important;border-right-color:!important;border-top-color:#e1e3ea;flex:7;" id="chart_mouth">Gráficos mensais</div>`, 
              `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                ReactDOMServer.renderToString(graphicYear(charts_year, charts_comsuption))
              }
              </div>`
            );

            }else{
              if(chave === 'graphic_days'){
                const charts_mouth = dados['chart_mouth'];
                
                const marcador = new RegExp(`{{${chave}}}`, 'g');
                novoHtml = novoHtml.replace(marcador, `<div id="chart_daily" style="height: 10rem; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                    ReactDOMServer.renderToString(graphicMouth(charts_mouth))
                    //teste()
                      // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
                    // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
                  }
                  </div>`);

              }else{
                if(chave === 'graphic_months'){
                  const charts_year = dados['chart_year'];
                  const charts_comsuption = dados["chart_comsuption"]

                  const marcador = new RegExp(`{{${chave}}}`, 'g');
                  novoHtml = novoHtml.replace(
                    marcador,
                    `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                      ReactDOMServer.renderToString(graphicYear(charts_year, charts_comsuption))
                    }
                    </div>`
                  );

                }else{
                  if(chave === 'initPeriod'){
                    const myDate = DateCompleteBarra(dados[chave]);
                    
                    const marcador = new RegExp(`{{${chave}}}`, 'g');
                    novoHtml = novoHtml.replace(marcador, myDate);

                  }else{
                    if(chave === 'endPeriod'){
                      const myDate = DateCompleteBarra(dados[chave]);
                      
                      const marcador = new RegExp(`{{${chave}}}`, 'g');
                      novoHtml = novoHtml.replace(marcador, myDate);
  
                    }else{
                      if(chave === 'invoices'){
                        const invoicesHtml = dados[chave].map((invoice: any) => {
                          const tds = Object.keys(invoice).map(key => {
                            if(key === 'dateInit' || key === 'dateAnd'){
                                return `
                                  <td>
                                    <div class='d-flex align-items-center'>
                                      <div class='d-flex justify-content-start flex-column'>
                                        <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
                                          ${DateCompleteBarra(invoice[key])}
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                `  
                            }

                            if(key === 'energia_registrada' 
                              || key === 'energia_compensada' 
                              || key === 'energia_faturada' 
                              || key === 'creditos_ant'
                              || key === 'creditos'
                            ){
                              return `
                              <td>
                                <div class='d-flex align-items-center'>
                                  <div class='d-flex justify-content-start flex-column'>
                                    <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
                                      ${invoice[key]} KWh
                                    </a>
                                  </div>
                                </div>
                              </td>
                            `  
                            }

                            if(key === 'valor_faturado' || key === 'economizado' ){
                              return `
                              <td>
                                <div class='d-flex align-items-center'>
                                  <div class='d-flex justify-content-start flex-column'>
                                    <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
                                      R$ ${invoice[key]}
                                    </a>
                                  </div>
                                </div>
                              </td>
                            `  
                            }
                            return `
                              <td>
                                <div class='d-flex align-items-center'>
                                  <div class='d-flex justify-content-start flex-column'>
                                    <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
                                      ${invoice[key]}
                                    </a>
                                  </div>
                                </div>
                              </td>
                            `  
                            //return `<td>${report[key]}</td>`;
                          }).join('');
                          return `<tr>${tds}</tr>`;
                      }).join('');
                      
                      // Adiciona as trs ao HTML
              
                      novoHtml = novoHtml.replace(
                        `{{informationsConcessionaries}}`, 
                        `<div class='table-responsive'>
                            <table class='table align-middle gs-0'>
                                <thead>
                                    <tr class='fw-bold text-muted' style="background: #f9f9f9;">
                                        <th class='min-w-30px rounded-start'><span class="fs-9">Contrato</span></th>
                                        <th class='min-w-40px rounded-start'><span class="fs-9">Inicio</span></th>
                                        <th class='min-w-40px rounded-start'><span class="fs-9">Fim</span></th>
                                        <th class='min-w-40px'><span class="fs-9">Consumo Registrado</span></th>
                                        <th class='min-w-40px'><span class="fs-9">Energia Compensada</span></th>
                                        <th class='min-w-40px'><span class="fs-9">Energia Faturada</span></th>
                                        <th class='min-w-40px'><span class="fs-9">Créditos Acumulados (Anterior)</span></th>
                                        <th class='min-w-40px'><span class="fs-9">Créditos Acumulados (Atual)</span></th>
                                        <th class='min-w-40px'><span class="fs-9">Faturado</span></th>
                                        <th class='min-w-40px'><span class="fs-9">Economizado</span></th>
                                    </tr>
                                </thead>
                                
                                <tbody id="table_reports">${invoicesHtml}</tbody>
                            </table>
                        </div>
                        `
                      );
                      }else{
                        if(chave === 'chart_year_generation'){
            
                          const charts_year_generation = dados[chave];
                          const charts_prognosis = dados["chart_prognosis"]
              
                          novoHtml = novoHtml.replace(
                            `{{graphic_mensal_combination}}`, 
                            `<div id="chart_mouth" style="height: 20rem; flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                              ReactDOMServer.renderToString(graphicYearGeneration(charts_year_generation, charts_prognosis))
                            }
                            </div>`
                          );

                          novoHtml = novoHtml.replace(
                            `{{graphic_mensal_combination}}`, 
                            `<div id="chart_mouth" style="height: 20rem; flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                              ReactDOMServer.renderToString(graphicYearGeneration(charts_year_generation, charts_prognosis))
                            }
                            </div>`
                          );

                        }else{
                          if(chave === 'chart_comsuption'){
                            //const charts_year = dados[chave];
                            const charts_comsuption = dados[chave]
          
                            const marcador = new RegExp(`{{${chave}}}`, 'g');
                            novoHtml = novoHtml.replace(
                              marcador,
                              `<div id="chart_mouth" style="height: 10rem; flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                                ReactDOMServer.renderToString(graphicYearComsuption(charts_comsuption))
                              }
                              </div>`
                            );
                          }else{
                            if(chave === 'generation'){
                              
                              if(dados[chave] && !isNaN(Number(dados[chave]))){
                                const generation = Number(dados[chave])
                                
                                const economia_co2 = (generation * 0.53)
                                const arvores_salvas = economia_co2 / 22

                                const marcador_economia_co2 = new RegExp(`{{economia_co2}}`, 'g');
                                novoHtml = novoHtml.replace(marcador_economia_co2, (economia_co2 / 1000).toFixed(2).toString());

                                const marcador_arvores_salvas = new RegExp(`{{arvores_salvas}}`, 'g');
                                novoHtml = novoHtml.replace(marcador_arvores_salvas, arvores_salvas.toFixed(2).toString());

                              }else{
                                const marcador_economia_co2 = new RegExp(`{{economia_co2}}`, 'g');
                                novoHtml = novoHtml.replace(marcador_economia_co2, "0");

                                const marcador_arvores_salvas = new RegExp(`{{arvores_salvas}}`, 'g');
                                novoHtml = novoHtml.replace(marcador_arvores_salvas, "0");
                              } 

                              const marcador = new RegExp(`{{${chave}}}`, 'g');
                              novoHtml = novoHtml.replace(marcador, dados[chave]);

                            }else{
                              const marcador = new RegExp(`{{${chave}}}`, 'g');
                              novoHtml = novoHtml.replace(marcador, dados[chave]);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

    if(novoHtml.includes("report_template")){
      return novoHtml;
    }else{
      return "<div id='report_template'>" + novoHtml + "</div>"
    }
  };

  const submit = (typeReport: string) => {
    setLoadingSubmitPDF(true)
    const template_generation = report && report.templates ? report.templates.find((template: any) => template.type_template === typeReport) : null
    if(template_generation){
        generatePDF(report ? replaceValues(report, template_generation.textHTML) : "<div>template não encontrado<div/>", "report_template")
    }
    setLoadingSubmitPDF(false) 
  }


  useEffect(()=> {
    async function search_reports(){
      if(id){
        await getMyReport()
      }
    }
    search_reports()

  }, [show])

  useEffect(() => {
    console.log('my report', report)
  }, [report])

  if (loading) {
    return (
      <div className='bg-body' style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border bg-body' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Resumo do Relatório</span>
        </h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8' style={{}}>
        <div className='d-flex justify-content-center'>
            <h3 className='card-title align-items-center' style={{display: 'flex',flexDirection: 'column'}}>
                <span className='card-label fw-bold fs-3 mb-1'>{report && report.data ? nameMes(report.data) : "Não identificado. "}</span>
                <span className='text-muted mt-1 fw-semibold fs-6'>{report && report.initPeriod ? DateCompleteBarra(report.initPeriod) : "00/00/000"} a {report && report.endPeriod ? DateCompleteBarra(report.endPeriod) : "00/00/000"}</span>
            </h3>
        </div>

        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem', marginTop: '2rem', flexWrap: "wrap"}}>
            <h3 className='card-title align-items-start' style={{display: 'flex', gap: '1rem'}}>
                <span className='card-label fw-bold fs-4 mb-1'>Usina:</span>
                <span className='text-muted mt-1 fw-semibold fs-6'>{report && report.namePlant ? report.namePlant : "Não identificado."}</span>
            </h3>

            <h3 className='card-title align-items-start' style={{display: 'flex', gap: '1rem'}}>
                <span className='card-label fw-bold fs-4 mb-1'>Geração:</span>
                <span className='text-muted mt-1 fw-semibold fs-6'>{report && report.generation ? report.generation : "0"} KWh</span>
            </h3>
            
            <h3 className='card-title align-items-start' style={{display: 'flex', gap: '1rem'}}>
                <span className='card-label fw-bold fs-4 mb-1'>Prognóstico:</span>
                <span className='text-muted mt-1 fw-semibold fs-6'>{report && report.prog_mensal ? report.prog_mensal : "0"} KWh</span>
            </h3>
        </div>

        <div>
            {graphicMouth(report && report.chart_mouth ? report.chart_mouth : {})}
        </div>
      </div>

      <div className='d-flex flex-stack modal-footer py-lg-8 px-lg-8' style={{justifyContent: 'end', marginBottom :'1rem', gap: '1rem'}}>
          <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
            <h3 className='card-title align-items-start' style={{display: 'flex', gap: '1rem'}}>
              <span className='text-muted mt-1 fw-semibold fs-6'>Gerar relatório de: </span>
            </h3>
            <button
              type='button'
              className={`btn btn-${width > 1200 ? "lg" : "sm"} btn-secondary text-light`}
              //disabled={loading || report ? !report.template ? true : false : true}
              disabled={
                loadingSubmitPDF
                || (report && report.templates && !report.templates.some((template: any) => template.type_template === 'GERACAO'))
                || (report && report.plano === "GRATUITA")
                ? true
                : false
              }
              //data-kt-stepper-action='submit'
              onClick={() => {submit('GERACAO')}}
            >
              Geração
              {
                loadingSubmitPDF ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                :
                  report && report.plano !== "GRATUITA" 
                  ?
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  :
                    <i className={`bi bi-lock-fill ms-2`} style={{fontSize: '1.5rem'}}></i>
              }
            </button> 

            <button
              type='button'
              className={`btn btn-${width > 1200 ? "lg" : "sm"} btn-primary`}
              disabled={
                loadingSubmitPDF
                || (report && report.templates && !report.templates.some((template: any) => template.type_template === 'DETALHADO'))
                || (report && report.plano === "GRATUITA")
                || (report && report.plano === "STARTER")
                ? true
                : false
              }
              onClick={() => {submit('DETALHADO')}}
            >
              Detalhado
              {
                loadingSubmitPDF ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                :
                  report && report.plano !== "GRATUITA" && report.plano !== "STARTER"
                  ?
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  :
                    <i className={`bi bi-lock-fill ms-2`} style={{fontSize: '1.5rem'}}></i>
              }
            </button>   
          </div>
        </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalResumoReport}



/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import useSize from "../../../../hooks/useSize";
import { useToast } from "../../../components/Toats";
import { getInvoicePDF, getInvoicesAll } from "../../../../services/invoices";
import { getplantsAll } from "../../../../services/plantsService";
import { Loading } from "../../../components/Loadings/loading";
import { KTSVG } from "../../../../../_metronic/helpers";
import { DateMes, formatDate } from "../../../../utils/date";
import { getConcessionariesAll } from "../../../../services/concessionariesService";
import { getUnitConsumerAll, getUnitConsumerAllFilter } from "../../../../services/unitConsumerService";
import { ModalAddUpdateInvoice } from "../ModalAddEditInvoices";


type Props = {
    className: string;
}

const TableInvoicesV2: React.FC<Props> = ({className}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingDownload, setLoadingDownload] = useState<boolean>(true);
    const [invoices, setInvoices] = useState<any[]>([]);
    const [loadingPlantsFilter, setLoadingPlantsFilter] = useState(false);
    const [plantString, setPlantString] = useState<string | null>(null);
    const [dataFilter, setDataFilter] = useState<any[]>([]);
    //const [years, setYears] = useState<any[]>([]);
    const [filterConcessionaries, setFilterConcessionaries] = useState<string>("");
    const [filterUnitConsumer, setFilterUnitConsumer] = useState<string>("");
    const [concessionaries, setConcessionaries] = useState<any>([]);
    const [selectedConcessionaries, setSelectedConcessionaries] = useState<string>("");
    const [loadingConcessionaries, setLoadingConcessionaries] = useState<any>(false);
    const [loadingUnit, setLoadingUnit] = useState<any>(true);
    const [unitConsumers, setUnitConsumers] = useState<any>([]);
    const [unitConsumer, setUnitConsumer] = useState<any>(null);
    const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
    const [myInvoice, setMyInvoice] = useState<any | null>(null);
    const {height} = useSize()

    const { toggleToast } = useToast();

    // function getUniqueSortedYears(data: any) {
    //     const years = [...new Set(data.map(obj => new Date(obj.data).getFullYear()))];
    //     setYears(years.sort((a: any, b: any) => b - a));
    // }

    const getInvoices = async () => {
        try {
            setLoading(true)
            const invoices = await getInvoicesAll(
                //filterUnitConsumer !== "" ? filterUnitConsumer : undefined, 
                undefined,
                undefined, 
                filterConcessionaries !== "" ? filterConcessionaries : undefined, 
                plantString ? plantString : undefined,
                "INFORMADO"
            )
                
            setInvoices(invoices)
    
        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar as faturas. ", "error")
        }finally{
          setLoading(false)
        }
    }

    const getPlantsFilter = async () => {
        try {
            setLoadingPlantsFilter(true)
            const myPlants = await getplantsAll()
            setDataFilter(myPlants)
            
            if(myPlants && myPlants.length > 0){
                setPlantString(myPlants[0].id)
            }
        } catch (error) {
          console.log(error)
        }finally{
          setLoadingPlantsFilter(false)
        }
    }

    const getConcessionaries = async () => {
        try {
          setLoadingConcessionaries(true)
          const myConcessionaries = await getConcessionariesAll()
          setConcessionaries(myConcessionaries)
      
        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar as faturas!", "error")
        }finally{
          setLoadingConcessionaries(false)
        }
    }

    const getUnitConsumer = async () => {
        try {
          setLoadingUnit(true)
          const myUnitConsumers = await getUnitConsumerAllFilter(null, plantString)
          if(myUnitConsumers){
            setUnitConsumers(myUnitConsumers)
            setFilterUnitConsumer(myUnitConsumers[0].id)
            setUnitConsumer(myUnitConsumers[0])
          }

        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar as unidades consumidoras!", "error")
        }finally{
          setLoadingUnit(false)
        }
    }

    const getPDF = async (link_file: any) => {
        try {
          setLoadingDownload(true);
          let myPDF = await getInvoicePDF(link_file ? link_file : "");
          
          const link = document.createElement('a');
          link.href = myPDF.url;
          link.setAttribute('download', 'fatura.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      
        } catch (error) {
          console.log(error);
          toggleToast("Fatura não encontrada.", "error");
      
        } finally {
          setLoadingDownload(false);
        }
      };

    const refresh = () => {
        getInvoices()
    }

    const handleButtonClickDownload = (event: any, link_file: any) => {
        event.stopPropagation();
        getPDF(link_file)
    };

    useEffect(() => {
        refresh();
        getUnitConsumer()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plantString, filterConcessionaries, filterUnitConsumer]);

    useEffect(() => {
        getPlantsFilter();
        getConcessionaries();
        getUnitConsumer()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loadingPlantsFilter || loading || loadingConcessionaries || loadingUnit) {
        return (
          <div className='spinnerLoadingDashboard'>
            <Loading myClass='spinner-border spinnerDashboard'/>
          </div>
        );
    }

    return (
        // <div className={`card ${className}`} style={{height: width > 1200 ? '72vh' : '68vh', paddingBottom: '1rem'}}></div>
        <div style={{
            paddingBottom: '1rem', 
            overflow: 'auto',
            height: height <= 750 ? '65vh' 
            : height <= 850 ? '65vh'
            : height <= 930 ? '70vh'
            : '70vh',
        }}>
            <div style={{display: 'flex', alignItems: 'center', gap: '0.7rem', marginBottom: '1.5rem', marginTop: '0.5rem'}}>
                <KTSVG path="/media/icons/duotune/general/gen001.svg" className="svg-icon-muted svg-icon-3hx" />
                <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
                    <span className='card-label fs-7'>{unitConsumer ? unitConsumer.name : "Não identificado"}</span>
                    <span className='text-muted mt-1 fw-semibold fs-8'>Nº {unitConsumer && unitConsumer.contrato ? unitConsumer.contrato : "000000"}</span>
                </h3>
                <select
                    name='status'
                    data-control='select2'
                    data-hide-search='true'
                    className='form-select form-select-white form-select-sm'
                    style={{width: '8rem'}}
                    value={filterUnitConsumer ? filterConcessionaries : "1"}
                    onChange={(event: any) => {
                        setFilterUnitConsumer(event.target.value)
                        //setPlan ? setDataFilter(event.target.value) : console.log('Sem função de filtro')
                    }}
                    >
                    {
                        unitConsumers && unitConsumers.length > 0 && unitConsumers.map((unitConsumer: any) => {
                        return (
                            <option value={unitConsumer.id}>{`${unitConsumer.name}`}</option>
                        );
                        })
                    }
                </select>
            </div>
            <div  
                style={{
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '1rem', 
                    flexDirection: 'row', 
                    marginTop: '0.5rem', 
                    justifyContent: 'space-between'
                }}>
                {/* <h3 className='card-title align-items-start flex-column pb-3 py-3'>
                    <span className='text-body mt-1 fw-semibold fs-5'>Usina</span>
                </h3> */}
                
                <div>
                    <div className='me-0 dropdown' style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-light buttonFilterMark'
                        type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                        style={{display: 'flex', gap: '1rem'}}
                    >
                        <span>{filterConcessionaries !== "" ? filterConcessionaries : "Concessionaria"} </span>
                        <i className='bi bi-three-dots fs-3'></i>
                    </button>
                    <div className='dropdown-menu dropdown-menu-end' aria-labelledby="dropdownMenuButton1" style={{padding: '1rem'}}>
                        <div className='card-toolbar nav nav-tabs' data-kt-buttons='true' style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start'}}>
                        <a
                            className={`btn btn-sm btn-color-muted btn-active btn-active-dark px-5 me-1 nav-item`}
                            //style={{background: 'green'}}
                            data-bs-toggle="tab"
                            style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                            href="#"
                            onClick={()=> {
                                setFilterConcessionaries("")
                                setSelectedConcessionaries("")
                            }}
                            >
                            Concessionária
                            {/* <span 
                            className='badge badge-dark spanFilter'
                            >
                            {concessionarie.status_mon_aut ? concessionarie.status_mon_aut : 'Não identificado.'}
                            </span> */}
                        </a>
                        {concessionaries && concessionaries.map((concessionarie: any) => {
                        return (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                            className={`btn btn-sm btn-color-muted btn-active btn-active-
                                ${concessionarie.status_mon_aut && concessionarie.status_mon_aut === 'MONITORADA' ? 'success' : 'danger'}
                                px-5 me-1 nav-item`}
                            style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                            data-bs-toggle="tab"
                            href="#"
                            onClick={()=> {
                                setFilterConcessionaries(concessionarie.name ? concessionarie.name : "")
                                setSelectedConcessionaries(concessionarie.name ? concessionarie.name : "")
                            }}
                            >
                            <span style={{textAlign: 'start'}}>
                            {concessionarie.name ? concessionarie.name : "Não identificado"} 
                            </span>
                            <span 
                            className={`badge badge-${concessionarie.status_mon_aut && concessionarie.status_mon_aut === 'MONITORADA' ? 'success' : 'danger'} fs-9`}
                            style={{height: '1.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                            >
                            {concessionarie.status_mon_aut ? concessionarie.status_mon_aut : 'Não identificado.'}
                            </span>
                        </a>
                            // <option key={concessionarie.id} value={concessionarie.name}>{concessionarie.name}</option>
                        )
                        })}
                        </div>
                    </div>
                    </div>
                </div>
                
                <div>
                    <select
                        name='status'
                        data-control='select2'
                        data-hide-search='true'
                        className='form-select form-select-white form-select-sm'
                        value={plantString ? plantString : "1"}
                        onChange={(event: any) => {
                            setPlantString(event.target.value)
                            //setPlan ? setDataFilter(event.target.value) : console.log('Sem função de filtro')
                        }}
                        >
                        {
                            dataFilter && dataFilter.length > 0 && dataFilter.map((plant: any) => {
                            return (
                                <option value={plant.id}>{`${plant.name}`}</option>
                            );
                            })
                        }
                    </select>
                </div>

            </div>

            <div className={`card p-2 mt-5`}>
                {invoices && invoices.length > 0 ?
                    invoices.map((invoice: any) => {
                        return (
                            <div className="bg-hover-primary" 
                                style={{
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'space-between', 
                                    borderRadius: '10px',
                                    padding: '0.5rem'
                                }}
                                onClick={() => {
                                    setMyInvoice(invoice)
                                    setShowCreateAppModal(true)
                                }}
                            >
                        
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div className='symbol symbol-40px symbol-circle me-5'>
                                        <KTSVG path="/media/icons/duotune/ecommerce/ecm010.svg" className="svg-icon-muted svg-icon-2hx" />
                                    </div>
                                    <div>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fs-5'>{formatDate(invoice.mes)}</span>
                                            {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
                                        </h3>
                                    </div>
                                </div>
                                <div>
                                    <span 
                                        className={
                                            `badge badge-light-${
                                                invoice.status !== null
                                                ? invoice.status === "INFORMADO"
                                                ? "success"
                                                : invoice.status === "NÃO INFORMADO"
                                                ? "danger"
                                                : "secondary"
                                                : "secondary"
                                            } fs-8 fw-semibold`
                                        }
                                    >
                                        {
                                        invoice.status ? invoice.status : "Não identificado"
                                        }
                                    </span>
                                </div>
                                <div>
                                    {/* <div className='symbol symbol-10px symbol-circle me-5'>
                                        <KTSVG path="media/icons/duotune/arrows/arr001.svg" className="svg-icon-muted svg-icon-1" />
                                    </div> */}
                                    <a
                                        onClick={(event: any) =>{
                                            if(invoice.link_file){
                                                handleButtonClickDownload(event, invoice.link_file)
                                            }else{
                                                console.log('epa')
                                                toggleToast("Não há arquivo de fatura para download. ", "alert");
                                            }
                                        }}
                                        aria-disabled={loading || (!invoice.link_file ? true : false)}
                                        href='#'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    >
                                        <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-3' />
                                    </a>
                                </div>
                            </div>
                        )
                        
                    })
                :
                <div style={{
                    display: 'flex', 
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: height <= 750 ? '50vh' 
                    : height <= 850 ? '50vh'
                    : height <= 930 ? '50vh'
                    : '50vh',
                }}>
                    <h3 className='card-title align-items-start flex-column pb-3 py-3'>
                        {/* <span className='text-body mt-1 fw-semibold fs-5'>Usina</span> */}
                        <span className='text-muted mt-1 fw-semibold fs-5'>Não há faturas geradas!</span>
                    </h3>
                </div>
                }
            </div>
            <ModalAddUpdateInvoice
                show={showCreateAppModal}
                id={myInvoice ? myInvoice.id : undefined} 
                data={myInvoice ? myInvoice : undefined}
                handleClose={() => {
                    setMyInvoice(null)
                    setShowCreateAppModal(false)
                    refresh()
            }} 
        />
        </div>
    )

}

export {TableInvoicesV2}
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Plants } from '../../../@types/plants'
import { getAlarmStatus } from '../../../services/alarm'
import { getMark } from '../../../services/markService'
import { getplantsAll, getplantsAllPagination } from '../../../services/plantsService'
import { searchSubstitut } from '../../../utils/search'
import { Loading } from '../../components/Loadings/loading'
import { useToast } from '../../components/Toats'
import { TablePlantsSearch } from './components/table'
import './styles.css'
import { SearchInput } from '../../components/SearchInput'
import useSize from '../../../hooks/useSize'

type Props = {
  className: string;
  setLoadingPlants: any;
}

const TablesPlants: React.FC<Props> = ({className, setLoadingPlants}) => {
    const { toggleToast } = useToast();
    const [plants, setPlants] = useState<Plants[]>([]);
    const [alarmStatus, setAlarmStatus] = useState<any>({
      attention: 0,
      alarmNum: 0,
      normalNum: 0,
      offlineNum: 0,
      total: 0,
    });
    const [filterAlarms, setFilterAlarms] = useState(-1);
    const [filterMarks, setFilterMarks] = useState("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loadingMyPlants, setLoadingMyPlants] = useState(true);
    const [marks, setMarks] = useState<any>([]);
    const [largura, setLargura] = useState(window.innerWidth);

    const [mySearch, setMySearch] = useState<any[]>([]);
    const [control, setControl] = useState(false);
    const {height, width} = useSize()

    const [pagination, setPagination] = useState<any>({
      page: 1,
      limit: 50,
      total: 0,
      qtdPages: 1
    });

    const [qtd, setQtd] = useState<any>({
      start: 0,
      end: 50,
    });


    // const getPlants = async () => {
    //   try {
    //     //setLoading(true)
    //     setLoadingPlants(true)
    //     setLoadingMyPlants(true)
    //     if(filterAlarms === -1){
    //       const url = `?mark=${filterMarks}`
    //       const myPlants = await getplantsAllPagination(url)
    //       setPlants(myPlants.data)

    //       const search_plants = mySearch.length > 0 ? 
    //         searchSubstitut(myPlants.data, mySearch)
    //       : []
    //       setMySearch(search_plants)
  
    //     }else if (filterAlarms === 0){
    //       const url = `?status=${0}&mark=${filterMarks}`
    //       const myPlants = await getplantsAllPagination(url)
    //       setPlants(myPlants.data)

    //       const search_plants = mySearch.length > 0 ? 
    //         searchSubstitut(myPlants.data, mySearch)
    //       : []
    //       setMySearch(search_plants)
  
    //     }else if(filterAlarms === 1){
    //       const url = `?status=${1}&mark=${filterMarks}`
    //       const myPlants = await getplantsAllPagination(url)
    //       setPlants(myPlants.data)

    //       const search_plants = mySearch.length > 0 ? 
    //         searchSubstitut(myPlants.data, mySearch)
    //       : []
    //       setMySearch(search_plants)
  
    //     }else if(filterAlarms === 2){
    //       const url = `?status=${2}&mark=${filterMarks}`
    //       const myPlants = await getplantsAllPagination(url)
    //       setPlants(myPlants.data)

    //       const search_plants = mySearch.length > 0 ? 
    //         searchSubstitut(myPlants.data, mySearch)
    //       : []
    //       setMySearch(search_plants)
  
    //     }else if(filterAlarms === 4){
    //       const url = `?status=${4}&mark=${filterMarks}`
    //       const myPlants = await getplantsAllPagination(url)
    //       setPlants(myPlants.data)

    //       const search_plants = mySearch.length > 0 ? 
    //         searchSubstitut(myPlants.data, mySearch)
    //       : []
    //       setMySearch(search_plants)
    //     }else if(filterAlarms === 3){
    //       const url = `?status=${3}&mark=${filterMarks}`
    //       const myPlants = await getplantsAllPagination(url)
    //       setPlants(myPlants.data)

    //       const search_plants = mySearch.length > 0 ? 
    //         searchSubstitut(myPlants.data, mySearch)
    //       : []
    //       setMySearch(search_plants)
    //     }
    //     //await getSomeAlarmStatus()
    //   } catch (error) {
    //     console.log(error)
    //     toggleToast("Erro ao buscar as usinas. ", "error")
    //   }finally{
    //     setLoadingPlants(false)
    //     //setLoading(false)
    //     setLoadingMyPlants(false)
    //   }
    // }

    const getPlants = async () => {
      try {
        setLoadingPlants(true)
        setLoadingMyPlants(true)

        const url = `?status=${filterAlarms !== -1 && filterAlarms !== -2 ? filterAlarms : ""}&mark=${filterMarks}&limit=${pagination.limit}&page=${pagination.page}&alert=${filterAlarms === -2 ? true : false}`
        const myPlants = await getplantsAllPagination(url)
        setPlants(myPlants.data)
        setPagination({...pagination, qtdPages: myPlants.lastPage, total: myPlants.total})

        const search_plants = mySearch && mySearch.length > 0 ? 
          searchSubstitut(myPlants.data, mySearch)
        : []
        setMySearch(search_plants)

      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar as usinas. ", "error")
      }finally{
        setLoadingPlants(false)
        //setLoading(false)
        setLoadingMyPlants(false)
      }
    }

    const getSomeAlarmStatus = async () => {
      try {
        const alarmStatus = await getAlarmStatus()
        setAlarmStatus(alarmStatus)
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar os status das usinas. ", "error")
      }
    }

    const getMarksAll = async () => {
      try {
        //setLoadingMyMarks(true)
        const marks = await getMark(true)
        setMarks(marks)
        
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar os portais. ", "error")
      }finally{
        //setLoadingMyMarks(false)
      }
    }

    // function handleChange(evt: any) {
    //   if (evt.target.value !== "") {
    //     if(plants){
    //       let search: any = [];
    //       for (let i = 0; i < plants.length; i++) {
    //         if(plants[i].name !== null){
    //           if (plants[i].name.indexOf(evt.target.value) >= 0) {
    //             search.push(plants[i]);
    //           }
    //         }
    //       }
    //       setMySearch(search);
    //       if (control === false) {
    //         setControl((prev) => !prev);
    //       }
    //     }
    //   } else {
    //     setMySearch([]);
    //     if (control === true) {
    //       setControl((prev) => !prev);
    //     }
    //   }
    // }

    const atualizarTamanhoDaTela = () => {
      setLargura(window.innerWidth);
    };

    const refresh = () => {
      getPlants()
    }

    useEffect(() => {
      getPlants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterAlarms, filterMarks, pagination.page]);

    useEffect(() => {
      getPlants();
      getSomeAlarmStatus();
      getMarksAll()
      
      window.addEventListener('resize', atualizarTamanhoDaTela);
      return () => {
        window.removeEventListener('resize', atualizarTamanhoDaTela);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const start = (pagination.page - 1) * pagination.limit + 1;
      const end = Math.min(pagination.page * pagination.limit, pagination.total);
  
      setQtd({start: start, end: end === 0 ? pagination.limit : end})
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.page]);


    if (!alarmStatus || !marks) {
        return (
          <div className='divSpinnerTablePlants'>
            <Loading myClass='spinner-border spinnerTablePlants'/>
          </div>
        );
    }

    return (
      <div className={`card ${className} pb-5`}>
        {/* begin::Header */}
        <div className={`card-header border-0 pt-5 ${largura < 1200 ? "pb-0" : "pb-5"} flex-column`}>
          <div style={{display: 'flex', alignItems: 'center', gap: '2rem'}}>
            <h3 className='card-title align-items-start' style={{flex: '1', flexDirection: largura < 1200 ? 'column' : 'column'}}>
                <span className={`card-label fw-bold fs-${largura < 1200 ? '6' : '3'} mb-1`}>Usinas {largura < 1200 ? `(${plants && plants.length ? plants.length : 0})` : ''}</span>
                {largura > 1200 && 
                  <span className={`text-muted mt-1 fw-semibold fs-${largura < 1200 ? '8' : '7'}`}>
                    {largura > 1200 ?  'Total: ' : ''}  {plants && plants.length ? plants.length : 0} usinas
                  </span>
                }
              </h3>
            {
              largura < 1200 && (
                <SearchInput 
                  className={`form-control form-control-solid bg-body ps-14 ${largura < 1200 ? "fs-7" : ""}`}
                  placeholder='Buscar pelo Nome'
                  myDate={plants}
                  setMySearch={setMySearch}
                  control={control}
                  style={{width: largura < 1200 ? "100%" : "300px", flex: '1', height: '2.5rem'}}
                  setControl={setControl}
                  searchParameter="name"
                />
              )
            }
          </div>
          
          <div className='border-0 flex-row divGlobalTitles' 
            style={{
              width: '100%',
              display: largura > 1200 ? 'flex' : '', 
              gap: '1rem',
              paddingTop: largura > 1200 ? '1rem' : '',
              paddingBottom: largura > 1200 ? '1rem' : '',
            }}
            >
            <div className='border-0 divFiltersPlants'>
              {/* <input
                type='text'
                style={{width: largura < 1200 ? "100%" : "200px"}}
                data-kt-user-table-filter='search'
                className='form-control form-control-solid ps-14'
                placeholder='Buscar pelo Nome'
                onChange={handleChange}
              /> */}

              {largura > 1200 && (
              
              <SearchInput 
                className='form-control form-control-solid bg-body ps-14'
                placeholder='Buscar pelo Nome'
                myDate={plants}
                setMySearch={setMySearch}
                control={control}
                style={{width: largura < 1200 ? "100%" : "300px"}}
                setControl={setControl}
                searchParameter="name"
              />
              )}

              {largura > 1200 &&
                <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
                  <a
                    className='btn btn-sm btn-color-muted btn-active btn-active-dark active px-5 me-1 nav-item'
                    data-bs-toggle="tab"
                    href="#"
                    onClick={()=> {
                      setFilterAlarms(-1)
                    }}
                  >
                    Total 
                    <span 
                      className='badge badge-dark spanFilter'

                    >
                      {alarmStatus.total}
                    </span>
                  </a>
                  <a
                    className='btn btn-sm btn-color-muted btn-active btn-active-success px-4 me-1 nav-item'
                    data-bs-toggle="tab"
                    href="#"
                    onClick={()=> {
                      setFilterAlarms(0)
                    }}
                  >
                    Normais
                    <span 
                      className='badge badge-success spanFilter'
                    >
                      {alarmStatus.normalNum}
                    </span>
                  </a>
                  <a
                    className='btn btn-sm btn-color-muted btn-active btn-active-warning px-4 nav-item'
                    data-bs-toggle="tab"
                    href="#"
                    onClick={()=> {
                      setFilterAlarms(2)
                    }}
                  >
                    Em alerta
                    <span 
                      className='badge badge-warning spanFilter'
                    >
                      {alarmStatus.attention}
                    </span>
                  </a>
                  <a
                    className='btn btn-sm btn-color-muted btn-active btn-active-danger px-4 nav-item'
                    data-bs-toggle="tab"
                    href="#"
                    onClick={()=> {
                      setFilterAlarms(1)
                    }}
                  >
                    Offline
                    <span 
                      className='badge badge-danger spanFilter'
                    >
                      {alarmStatus.offlineNum}
                    </span>
                  </a>
                  <a
                    className='btn btn-sm btn-color-muted btn-active btn-active-info px-4 nav-item'
                    data-bs-toggle="tab"
                    href="#"
                    onClick={()=> {
                      setFilterAlarms(3)
                    }}
                  >
                    Informativo
                    <span 
                      className='badge badge-info spanFilter'
                    >
                      {alarmStatus.info}
                    </span>
                  </a>
                  <a
                  className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 nav-item'
                  data-bs-toggle="tab"
                  href="#"
                  onClick={()=> {
                    setFilterAlarms(-2)
                  }}
                >
                  Com Alarme
                  <span 
                    className='badge badge-primary spanFilter'
                  >
                    {alarmStatus.alertCount}
                  </span>
                </a>
                </div>
              }
            </div>

            <div className='card-toolbar'>
              <div className='fv-row' style={{width: '100%'}}>
                <div>
                  <select
                    className={`form-select form-select-solid fs-${largura < 1200 ? '7' : '5'}`}
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    style={{height: largura < 1200 ? '2.8rem' : '3rem'}}
                    defaultValue={''}
                    onChange={(e) => {
                      setFilterMarks(e.target.value)
                    }}
                  >
                    <option value={''} className="optionButtonFilterMark">Buscar pelo Portal</option>
                    {marks && marks.map((myMark: any) => {
                      return (
                        <option key={myMark.id} value={myMark.id}>{myMark.name}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>

            {largura < 1200 &&
            
              <div style={{overflow: 'auto', width: '100%'}}>
                <div className='card-toolbar nav nav-tabs' style={{display: 'flex', flexWrap: 'nowrap', width: '70vh'}} data-kt-buttons='true'>
                <a
                  className='btn btn-sm btn-color-muted btn-active btn-active-dark active px-5 me-1 nav-item'
                  data-bs-toggle="tab"
                  href="#"
                  onClick={()=> {
                    setFilterAlarms(-1)
                  }}
                >
                  Total 
                  <span 
                    className='badge badge-dark spanFilter'

                  >
                    {alarmStatus.total}
                  </span>
                </a>
                <a
                  className='btn btn-sm btn-color-muted btn-active btn-active-success px-4 me-1 nav-item'
                  data-bs-toggle="tab"
                  href="#"
                  onClick={()=> {
                    setFilterAlarms(0)
                  }}
                >
                  Normais
                  <span 
                    className='badge badge-success spanFilter'
                  >
                    {alarmStatus.normalNum}
                  </span>
                </a>
                <a
                  className='btn btn-sm btn-color-muted btn-active btn-active-warning px-4 nav-item'
                  data-bs-toggle="tab"
                  href="#"
                  onClick={()=> {
                    setFilterAlarms(2)
                  }}
                >
                  Em alerta
                  <span 
                    className='badge badge-warning spanFilter'
                  >
                    {alarmStatus.attention}
                  </span>
                </a>
                <a
                  className='btn btn-sm btn-color-muted btn-active btn-active-danger px-4 nav-item'
                  data-bs-toggle="tab"
                  href="#"
                  onClick={()=> {
                    setFilterAlarms(1)
                  }}
                >
                  Offline
                  <span 
                    className='badge badge-danger spanFilter'
                  >
                    {alarmStatus.offlineNum}
                  </span>
                </a>
                <a
                  className='btn btn-sm btn-color-muted btn-active btn-active-info px-4 nav-item'
                  data-bs-toggle="tab"
                  href="#"
                  onClick={()=> {
                    setFilterAlarms(3)
                  }}
                >
                  Informativo
                  <span 
                    className='badge badge-info spanFilter'
                  >
                    {alarmStatus.info}
                  </span>
                </a>
                <a
                  className='btn btn-sm btn-color-muted btn-active btn-active-prmary px-4 nav-item'
                  data-bs-toggle="tab"
                  href="#"
                  onClick={()=> {
                    setFilterAlarms(-2)
                  }}
                >
                  Com Alarme
                  <span 
                    className='badge badge-primary spanFilter'
                  >
                    {alarmStatus.alertCount}
                  </span>
                </a>
                </div>
              </div>
            
            }
          </div> 
        </div>

        <div className='card-body py-3 pb-10'
          style={{
            //background: 'green',
            overflow: 'auto', 
            height: width < 1200 ?
              height <= 750 ? '27vh' 
            : height > 750 && height <= 850 ? '35vh'
            : height > 850 && height <= 930 ? '40vh'
            : `42vh`
            : '',
          }}
        >
          <div className='table-responsive myTableResponsive'>
            {loadingMyPlants ? 
              <div className='divSpinnerTablePlants'>
                <Loading myClass='spinner-border spinnerTablePlants'/>
              </div>
            :
                <div className='divTable'>
                  <TablePlantsSearch 
                    plants={control === false ? plants : mySearch}
                    refresh={refresh}
                  />
                </div>
                
            }
          </div>
          <div className='d-flex flex-stack flex-wrap pt-10'>
            <div className='fs-6 fw-bold text-gray-700'>Mostrando {qtd.start} a {qtd.end} de {pagination.total} encontradas</div>

            <ul className='pagination'>
              <li className='page-item previous'>
                <a 
                  href='#' 
                  className='page-link'
                  onClick={() => {
                    setPagination({...pagination, page: pagination.page - 1 > 0 ? pagination.page - 1 : pagination.page})
                  }}
                >
                  <i className='previous'></i>
                </a>
              </li>

              {Array.from({ length: pagination ? pagination.qtdPages : 0 }, (_, index) => (
              <li 
                key={index} 
                className={`page-item ${(index + 1) === pagination.page ? "active" : ""}`}
                onClick={() => {
                  setPagination({...pagination, page: index + 1})
                }}
              >
                <a href='#' className='page-link'>
                  {index + 1}
                </a>
              </li>
              ))}

              
              <li className='page-item next'>
                <a 
                  href='#' 
                  className='page-link'
                  onClick={() => {
                    setPagination({...pagination, page: pagination.page + 1 <= pagination.qtdPages ? pagination.page + 1 : pagination.page})
                  }}
                >
                  <i className='next'></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
}

export {TablesPlants}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { ptBR } from "date-fns/locale";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 
import './style.css'
import { KTSVG } from '../../../../../../_metronic/helpers';
import { DateCompleteV2, getShortMonthNameString } from '../../../../../utils/date';
import useSize from '../../../../../hooks/useSize';

type Props = {
  className: string;
  charts: any;
  chartsMounth: any;
  myProg?: any;
}

const LineGraph: React.FC<Props> = ({className, charts, chartsMounth, myProg}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [listDates, setListDates] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [listDatesString, setListDatesString] = useState<any>([]);
  const [chartsValues, setChartsValues] = useState({
    metaDates: [],
    valores: []
  });
  const {width} = useSize()

  const [generation, setGeneration] = useState<string>("0"); 
  const [desempenho, setDesempenho] = useState<string>("0"); 

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, chartsValues.valores, chartsValues.metaDates))
    if (chart) {
      chart.render()
    }
    return chart
  }

  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
}

  const handleChartDeploy = () => {
    let myGeneration = 0

    if(Object.keys(charts).length  > 0){
      if(selectedValue){
        setChartsValues({
          metaDates: charts[selectedValue]["horarios"],
          valores: charts[selectedValue]["valores"]
        })
        console.log(chartsMounth)

        if(chartsMounth){
          const mesAno = selectedValue.slice(0, 7);

          if (chartsMounth[mesAno]) {
            const indice = chartsMounth[mesAno].dias.indexOf(selectedValue);
            if (indice !== -1) {
              const valorCorrespondente = chartsMounth[mesAno].valores[indice];
              setGeneration(valorCorrespondente.toString())
              myGeneration = valorCorrespondente
            }
          }
        }

        if(myProg && selectedValue){
          const value = getShortMonthNameString(selectedValue)
          const value_prop = `prog_${value}`;
          const value_final = myProg[value_prop];

          const [year, month] = selectedValue.split('-');
          const daysInMonth = getDaysInMonth(parseInt(year, 10), parseInt(month, 10));

          if(daysInMonth && value_final){
            const value_prog_day = value_final / daysInMonth

            const porcentagem_prognostico = (myGeneration / value_prog_day) * 100
            setDesempenho(porcentagem_prognostico ? porcentagem_prognostico.toFixed(2).toString() : "0")
          }
        }


      }   
    }
  }

  const handleChange = (myDate: string) => {
    setSelectedValue(DateCompleteV2(myDate))
  };
  
  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

        
  useEffect(() => {
    if(charts){
      const string_date : string = Object.keys(charts)[0]
      setSelectedValue(string_date)
      const list = Object.entries(charts);
      setListDates(list)

      const teste = list.map((chart: any) => {
        return chart[0]
      })

      setListDatesString(teste)
      console.log(teste)
    }
  }, [charts])
         
  useEffect(() => {
    handleChartDeploy()
  }, [selectedValue])

  useEffect(() => {
    const chart = refreshMode()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, chartsValues])

  if (!charts) {
    return <h1>Carrengando...</h1>;
  }

  return (
    <div className={`${width < 1200 ? '' : 'card'} ${className}`}>
      <div className='card-header border-0 pt-1' style={{alignItems: 'center'}}>
        {/* <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{selectedValue}</span>
        </h3>
        <div className='card-toolbar' data-kt-buttons='true'>
          <div className='d-flex align-items-center my-2'>
            <div className='w-130px me-5'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-white form-select-sm'
                value={selectedValue}
                onChange={handleSelectChange}
              >
                {
                  listDates.map((chart: any) => {
                    return (
                      <option value={chart[0]}>{chart[0]}</option>
                    );
                  })
                }
              </select>
            </div>
          </div>
        </div> */}

        <div style={{display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: width < 1200 ? '1rem' : '0rem', marginTop: width < 1200 ? '1rem' : '0rem'}}>
          <div>
            <KTSVG
              path='/media/icons/duotune/graphs/gra005.svg'
              className='svg-icon-3x ms-2 me-0'
            />
          </div>
          <div>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-7 mb-0'><span className='text-muted'>Gerado -</span> {generation} KWh</span>
            </h3>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-7 mb-0'><span className='text-muted'>Desempenho -</span>  {desempenho}%</span>
            </h3>
          </div>
        </div>

        <div>
          <DatePicker
            showIcon
            className="form-control form-control-sm"
            selected={new Date(new Date(selectedValue ? selectedValue : new Date()).setDate(new Date(selectedValue ? selectedValue : new Date()).getDate() + 1))}
            onChange={handleChange}
            showPreviousMonths
            //startDate={startDate}
            //endDate={endDate}
            locale={ptBR}
            //minDate={startDate}
            dateFormat="dd/MM/yyyy"
            includeDates={listDatesString.map(dateStr => {
              const [year, month, day] = dateStr.split('-');
              return new Date(year, month - 1, day);
            })}
            //minDate={new Date(new Date(dateAntigaMetaDates ? dateAntigaMetaDates : '').setDate(new Date(dateAntigaMetaDates ? dateAntigaMetaDates : '').getDate() + 1))}
            //minDate={new Date(new Date(dateAntigaMetaDates ? dateAntigaMetaDates : '').setDate(new Date(dateAntigaMetaDates ? dateAntigaMetaDates : '').getDate() + 1))}
            //maxDate={new Date(new Date(dateAtualMetaDates ? dateAtualMetaDates : '').setDate(new Date(dateAtualMetaDates ? dateAtualMetaDates : '').getDate() + 0))}
            // dayClassName={date => 
            //   (date.getTime() === (startDate?.getTime() || 0) || date.getTime() === (endDate?.getTime() || 0)) 
            //     ? "customSelectedDay" 
            //     : undefined
            // }
          />
        </div>
      </div>
      {/* begin::Body */}
      <div className='card-body' style={{overflowX: "auto"}}>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '150px', width: '100%'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {LineGraph}

function getChartOptions(height: number, valores: any, metaDates: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const lightColor = getCSSVariableValue('--bs-primary-light')

  return {
    series: [
      {
        name: 'Energia Gerada',
        data: valores,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: metaDates,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      offsetY: 10,
      offsetX: 10,

      labels: {
        rotate: 15,
        style: { 
          colors: labelColor,
          fontSize: '12px',
          cssClass: 'lineGraph'
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: [
      {
        min: 0,
        seriesName: 'Energia Gerada',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: baseColor
        },
        labels: {
          style: {
            colors: baseColor,
          }
        },
        title: {
          text: "Geração (KW)",
          style: {
            color: baseColor,
          }
        },
        tooltip: {
          enabled: true
        }
      },
    ],
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return 'KW ' + val
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
